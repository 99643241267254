<template>
  <div class="card full-height">
    <div class="flex-container">
      <div class="header">
        <h4>{{ `${titlesName || (filters && filters.loss_name_string) || "--"}` }}</h4>
        <div class="icons">
          <i
            class="fa fa-table"
            v-bind:class="chartType === chartTypes.TABLE ? 'selected' : null"
            @click="
              () => {
                chartType = chartTypes.TABLE;
              }
            "
          />
          <i
            class="fa fa-bar-chart"
            v-bind:class="chartType === chartTypes.CHART ? 'selected' : null"
            @click="
              () => {
                chartType = chartTypes.CHART;
              }
            "
          />
        </div>
      </div>
      <div
        class="chart"
        v-if="chartType == chartTypes.CHART && !loading"
      >
        <BarChart
          class="chart-container"
          :chart-data="chartData"
          uom="%"
          :click-function="clickEvent"
        />
        <div class="footer">
          <bar-chart-paginator
            :refresh="interactiveFilters"
            :rawData="rawData"
            :sortMethod="sortByTime"
            default-sort="asc"
            :buildChart="buildChart"
            defaultQuantity="50"
          />
        </div>
      </div>
      <div
        class="table"
        v-else-if="chartType == chartTypes.TABLE && !loading"
      >
        <GenericTable
          :columns="[
            { title: '', key: 'name' },
            {
              title: $t('Total Downtime'),
              key: 'total_downtime',
              format: seconds,
              sortable: false
            },
            {
              title: $t('Status Downtime'),
              key: 'status_runtime',
              format: seconds,
              sortable: false
            }
          ]"
          :data="tableData"
        />
      </div>

      <v-progress-linear
        indeterminate
        v-else
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import BarChartPaginator from "@/components/BarChartPaginator";
import GenericTable from "@/components/GenericTable";
import BarChart from "@/components/charts/BarChart";
import { chartOptions } from "@/components/charts/chartOptions";
import { tiffanyBlue } from "@/scss/_variables.scss";
import { seconds } from "@/utils/filters";
import { getDateFormatter } from "@/utils/getDateFormatter";

import TLAUtilizationTable from "./TLAUtilizationTable";
import useTLAFilters from "./useTLAFilters";

const chartTypes = {
  CHART: "chart",
  TABLE: "table"
};

export default {
  name: "TLAUnplannedDowntime",
  components: { BarChartPaginator, TLAUtilizationTable, BarChart, GenericTable },
  setup() {
    const { interactiveFilters, filterApplied, hasFilters } = useTLAFilters();
    return {
      interactiveFilters,
      filterApplied,
      hasFilters
    };
  },
  data() {
    return {
      level: null,
      chartData: {},
      rawData: [],
      tableData: [],
      machineGroupLevelName: "--",
      chartType: chartTypes.CHART,
      chartTypes: chartTypes,
      chartIds: [],
      filterName: "part_numbers",
      filterDisplayName: "",
      titlesName: "",
      major_losses: [],
      minor_losses: [],
      loading: 0,
      cancelToken: null
    };
  },
  props: ["filters", "priorityLevel", "priority"],
  computed: {
    ...mapGetters({
      statuses: "app/Statuses"
    })
  },
  mounted() {
    this.rawData = [];
    this.tableData = [];
    if (this.filters && this.filters.status_codes && this.filters.status_codes.length > 0) {
      this.loadDowntime()
        .then((result) => {
          this.rawData = this.createRawData(result);
          this.tableData = this.createTableData(result);
          this.setupLosses();
          this.titlesName = this.createTitlesName();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  methods: {
    seconds,
    getDateFormatter,
    setupLosses() {
      const major_losses = [];
      const minor_losses = [];
      for (const s of this.statuses) {
        if (!s.running) {
          major_losses.push(s);
          for (const set of s.statuscodeSet) {
            minor_losses.push(set);
          }
        }
      }
      this.major_losses = major_losses;
      this.minor_losses = minor_losses;
    },
    createTitlesName() {
      const codesMap = {};
      const loss_name_string = new Set();
      for (const s of this.major_losses) {
        for (const sc of s.statuscodeSet) {
          codesMap[sc.code] = { name: s.name, description: sc.description, code: sc.code };
        }
      }

      if (
        this.interactiveFilters &&
        (!this.interactiveFilters["minor_losses"] ||
          this.interactiveFilters["minor_losses"].length === 0) &&
        this.interactiveFilters["major_losses"] &&
        this.interactiveFilters["major_losses"].length > 0
      ) {
        for (const ml of this.interactiveFilters["major_losses"]) {
          loss_name_string.add(codesMap[ml].name);
        }
      } else if (
        this.interactiveFilters &&
        this.interactiveFilters["minor_losses"] &&
        this.interactiveFilters["minor_losses"].length > 0
      ) {
        for (const ml of this.interactiveFilters["minor_losses"]) {
          loss_name_string.add(`${codesMap[ml].code}:${codesMap[ml].description}`);
        }
      }

      return Array.from(loss_name_string).join(",");
    },
    clickEvent(evt, clickedElements) {
      if (!clickedElements[0]) {
        return;
      }

      const element = clickedElements[0]._index;
      const id = this.chartIds[element];
      if (this.priority < this.priorityApplied) {
        this.removeAllFilters([
          "tlaReportMachineGroupLevelP2",
          "tlaReportMachineGroupLevelP2",
          "machine_id"
        ]);
      }

      this.toggleFilter(this.filterName, id, this.priority);
    },
    createRawData(result) {
      if (!result) {
        return [];
      }

      const { buckets } = result;
      const rawData = [];
      for (const b of buckets) {
        const data = {
          name: b.key,
          time: b.key,
          key_as_string: b.key_as_string,
          total_downtime: b.duration.value - b.runtime.duration.value,
          status_runtime:
            b.status_runtime && b.status_runtime.duration ? b.status_runtime.duration.value : 0
        };
        rawData.push(data);
      }

      return rawData;
    },
    createTableData(result) {
      const tableData = [];
      let { dateFormat, scaleString } = this.getDateFormatter(this.filters.scaleKey);
      let co = new chartOptions();
      co.scale = scaleString;
      co.dateFormat = dateFormat;

      for (const b of result.buckets) {
        tableData.push({
          name: co.labelFromString(b.key),
          total_downtime: b.duration.value - b.runtime.duration.value,
          status_runtime: b.status_runtime ? b.status_runtime.duration.value : 0
        });
      }
      return tableData;
    },
    sortByTime: function (rawData, sortOrder) {
      rawData.sort((a, b) => {
        const aVal = a.time;
        const bVal = b.time;
        if (sortOrder == "desc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      });
      return rawData;
    },
    buildChart(slicedData) {
      if (this.filters && this.filters.scaleKey) {
        let { dateFormat, scaleString } = this.getDateFormatter(this.filters.scaleKey);
        let co = new chartOptions();
        co.scale = scaleString;
        co.dateFormat = dateFormat;

        this.chartData = {};
        this.chartIds = [];

        this.chartData.datasets = [{}];
        this.chartData.datasets[0].data = [];
        this.chartData.datasets[0].label = [];
        this.chartData.datasets[0].gradients = [];

        this.chartData.title = "item.machineOrGroupName";
        this.chartData.labels = [];
        this.chartData.titles = [];

        this.filterDisplayName = "";
        if (
          this.interactiveFilters["minor_losses"] &&
          this.interactiveFilters["minor_losses"].length > 0
        ) {
          /* empty */
        } else if (
          this.interactiveFilters["major_losses"] &&
          this.interactiveFilters["major_losses"].length > 0
        ) {
          /* empty */
        }

        let index = 0;
        for (const s of slicedData || []) {
          const val = s.status_runtime ? s.status_runtime / (s.total_downtime || 1) : 0;
          this.chartData.datasets[0].data.push(100 * val.toFixed(2));
          this.chartData.datasets[0].label = this.$t("Utilization");
          this.chartData.labels.push(co.labelFromString(s.key_as_string));
          this.chartData.labels.titles = s.name;
          this.chartData.datasets[0].gradients.push([tiffanyBlue, tiffanyBlue]);
          index++;
          this.chartIds.push(s.name);
        }
      }
    },
    loadDowntime() {
      return new Promise((resolve, reject) => {
        //guard
        if (!this.filters || !this.filters.from_date || !this.filters.to_date) {
          reject();
        } else {
          let params = {
            from_date: this.filters.from_date,
            to_date: this.filters.to_date,
            scale: this.filters.scale
          };

          if (this.hasFilters) {
            if (
              this.interactiveFilters["machine_id"] &&
              this.interactiveFilters["machine_id"].length > 0
            ) {
              params.machine_id = this.interactiveFilters["machine_id"].join(",");
            } else if (
              this.interactiveFilters["tlaReportMachineGroupLevelP2"] &&
              this.interactiveFilters["tlaReportMachineGroupLevelP2"].length > 0
            ) {
              params.machine_group_id =
                this.interactiveFilters["tlaReportMachineGroupLevelP2"].join(",");
            } else if (
              this.interactiveFilters["tlaReportMachineGroupLevelP1"] &&
              this.interactiveFilters["tlaReportMachineGroupLevelP1"].length > 0
            ) {
              params.machine_group_id =
                this.interactiveFilters["tlaReportMachineGroupLevelP1"].join(",");
            }

            if (
              this.interactiveFilters["part_numbers"] &&
              this.interactiveFilters["part_numbers"].length > 0
            ) {
              params.part_numbers = this.interactiveFilters["part_numbers"].join(",");
            }

            if (
              this.interactiveFilters["minor_losses"] &&
              this.interactiveFilters["minor_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["minor_losses"].join(",");
            } else if (
              this.interactiveFilters["major_losses"] &&
              this.interactiveFilters["major_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["major_losses"].join(",");
            }
          } else {
            params.machine_group_id = this.filters.machine_groups.length
              ? this.filters.machine_groups.join(",")
              : null;
            params.machine_id = this.filters.machines.length
              ? this.filters.machines.join(",")
              : null;
            params.part_numbers = this.filters.part_numbers.length
              ? this.filters.part_numbers.join(",")
              : null;
            params.status_codes = this.filters.status_codes.length
              ? this.filters.status_codes.join(",")
              : null;
          }

          if (this.cancelSource) {
            this.cancelSource.cancel();
          }
          const CancelToken = axios.CancelToken;
          this.cancelSource = CancelToken.source();

          this.loading++;

          this.$http
            .get("metrics/availability_trend/", {
              params: params,
              cancelToken: this.cancelSource.token
            })
            .then((result) => {
              this.loading--;
              this.cancelSource = null;
              resolve(result.data);
            })
            .catch((e) => {
              this.loading--;
              this.cancelSource = null;
              console.log(e);
            });
        }
      });
    }
  },
  watch: {
    filters: function () {
      this.rawData = [];
      this.tableData = [];
      if (this.filters.status_codes && this.filters.status_codes.length > 0) {
        this.loadDowntime()
          .then((result) => {
            this.rawData = this.createRawData(result);
            this.tableData = this.createTableData(result);
            this.setupLosses();
            this.titlesName = this.createTitlesName();
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.rawData = [];
        this.tableData = [];
      }
    },
    interactiveFilters: function () {
      this.rawData = [];
      this.tableData = [];

      if (
        (this.interactiveFilters["minor_losses"] &&
          this.interactiveFilters["minor_losses"].length > 0) ||
        (this.interactiveFilters["major_losses"] &&
          this.interactiveFilters["major_losses"].length > 0) ||
        (this.filters && this.filters.status_codes)
      ) {
        if (this.filterApplied !== this.filterName) {
          this.loadDowntime()
            .then((result) => {
              this.rawData = this.createRawData(result);
              this.tableData = this.createTableData(result);
              this.setupLosses();
              this.titlesName = this.createTitlesName();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        this.rawData = [];
        this.tableData = [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: unset;

  i {
    padding-left: 10px;
    cursor: pointer;

    &.selected {
      color: $blue;
    }
  }
}

.full-height {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .flex-container {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1;
  }

  .chart {
    position: relative;
    flex: 1;
    overflow: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .chart-container {
      flex: 1;
    }

    .footer {
      flex: unset;
    }

    div {
      overflow: hidden;
    }
  }

  .table {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

h4 {
  color: $blue;
}

.footer {
  display: flex;
  flex: unset;
  align-items: center;
  justify-content: flex-end;
}
</style>
