<template>
  <div class="ldms_tickets">
    <v-data-table
      height="calc(100vh - 15rem)"
      item-key="id"
      :items="issueTable.data"
      :headers="issueTable.headers"
      :sortBy.sync="issueTable.sortBy"
      :sortDesc.sync="issueTable.sortDesc"
      :page.sync="issueTable.page.current"
      :itemsPerPage.sync="issueTable.page.itemsPerPage"
      :serverItemsLength="issueTable.total"
      @click:row="goToTicket"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading LDMS Tickets... Please wait')"
      disable-pagination
      fixed-header
    >
      <template v-slot:[`item.title`]="{ item }">
        {{ item.node.title }}
      </template>
      <template v-slot:[`item.ticketedDate`]="{ item }">
        {{ moment(item.node.ticketedDate).format($datetime.default) }}
      </template>
      <template v-slot:item.relation="{ item }">
        <p>{{ item.node.machineName ? item.node.machineName : item.node.machineGroupName }}</p>
      </template>
      <template v-slot:item.countermeasures="{ item }">
        <div v-bind:class="{ 'list-plus-button': getCMS(item).length > 0 }">
          <counter-measure
            :issues="[item.node]"
            :machine_id="machinesLookup[item.node.machineId].id"
            :time="item.key_as_string"
            :tab="-1"
            @UpdatedCM="(...args) => updateIssue(item, ...args)"
          />
        </div>
      </template>
      <template v-slot:item.cause="{ item }">
        <div v-bind:class="{ 'list-plus-button': getCauses(item).length > 0 }">
          <cause
            :issues="[item.node]"
            :machine_id="machinesLookup[item.node.machineId].id"
            :time="item.key_as_string"
            :tab="-1"
            @UpdatedCause="(...args) => updateIssue(item, ...args)"
          />
        </div>
      </template>
      <template v-slot:item.solution="{ item }">
        <div v-bind:class="{ 'list-plus-button': getSolutions(item).length > 0 }">
          <solution
            v-if="hasCause([item.node])"
            :issues="[item.node]"
            :machine_id="machinesLookup[item.node.machineId].id"
            :time="item.key_as_string"
            :tab="-1"
            @UpdatedSolution="() => loadIssuesTickets"
          />
        </div>
      </template>
      <template
        v-slot:item.status="{ item }"
        class="align-center"
      >
        <i
          class="harveyBall mdi"
          :class="getStatusIcon(item)"
          aria-hidden="true"
        ></i>
      </template>
      <template v-slot:[`header.assignedToNames`]="{ item }">
        <v-tabs
          class="tabs"
          v-model="usersTab"
          align-with-title
          key="ldmstabs"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="realtime">
            <p>
              <span class="button_text">{{ $t("Assigned") }}</span>
            </p>
          </v-tab>
          <v-tab key="summary">
            <p>
              <span class="button_text">{{ $t("Responding") }}</span>
            </p>
          </v-tab>
        </v-tabs>
      </template>
      <template v-slot:[`item.assignedToNames`]="{ item }">
        <div class="assignedRow">
          <v-dialog
            content-class="assignableDialog"
            v-model="item.assignDialog"
            persistent
            max-width="600px"
            v-if="usersTab === 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                light
                fab
                small
                v-bind="attrs"
                v-on="on"
              >
                <i
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              </v-btn>
            </template>
            <v-card>
              <DialogCloseButton :onClick="() => closeAssign(item)" />
              <v-card-title class="headline">
                <h3>{{ $t("Assign Users") }}</h3>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-xs-12 col-sm-6">
                    <v-text-field
                      v-model="userSearch"
                      append-icon="mdi-magnify"
                      :label="$t('Search Users to Assign')"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                    <div class="assignable">
                      <p
                        v-for="user in filteredUsers"
                        @click="newUsers.push(user)"
                      >
                        {{ user.firstName }} {{ user.lastName }}
                      </p>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6">
                    <h2>{{ $t("New Users") }}</h2>
                    <div class="newUsers">
                      <p
                        v-for="(new_user, new_user_index) in newUsers"
                        @click="newUsers.splice(new_user_index, 1)"
                      >
                        {{ new_user.firstName }} {{ new_user.lastName }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="closeAssign(item)"
                >
                  {{ $t("Cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  primary
                  text
                  :disabled="newUsers.length === 0"
                  :loading="assigningTicket"
                  @click="issueAssign(item)"
                >
                  {{ $t("Assign") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <p v-if="usersTab === 0">
            {{
              item.node.assignedToNames.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "")
            }}
          </p>
          <p v-if="usersTab === 1">
            {{
              item.node.respondingNames.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "")
            }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.launch`]="{ item }">
        <v-btn
          class="hidden-xs-only launch-button"
          small
          color="primary"
          fab
          @click.stop.prevent="launchTicket(item)"
        >
          <i
            class="fa fa-external-link-square"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-btn
          class="hidden-sm-and-up launch-button"
          small
          color="primary"
          @click.stop.prevent="launchTicket(item)"
        >
          {{ $t("Launch") }}
        </v-btn>
      </template>
      <template v-slot:footer.prepend>
        <v-btn
          color="primary"
          class="ma-2"
          @click="downloadCsv"
        >
          <v-icon>mdi-file-download</v-icon> CSV
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import Cause from "@/components/dialogs/Cause";
import CounterMeasure from "@/components/dialogs/CounterMeasure";
import Solution from "@/components/dialogs/Solution";
import { TableData } from "@/datatypes/table";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { Dispatcher } from "@/utils/eventbus";
import { seconds } from "@/utils/filters";

export default {
  props: [
    "machine_group_id",
    "machine_group_pk",
    "machines",
    "machinesLookup",
    "closedDateExists",
    "searchText",
    "filters"
  ],
  data() {
    return {
      issueTable: new TableData([
        { text: this.$t("Title"), value: "title" },
        {
          text: this.$t("Machine or Group"),
          value: "relation",
          sortable: false,
          width: "calc(25% - 18px)"
        },
        { text: this.$t("Date"), value: "ticketedDate", sortable: true, width: "115px" },
        {
          text: this.$t("Issue"),
          value: "node.reason",
          align: "start",
          sortable: false,
          cellClass: "issueCell"
        },
        {
          text: this.$t("Short Term Countermeasures"),
          value: "countermeasures",
          align: "center",
          sortable: false,
          width: "50px"
        },
        {
          text: this.$t("Root Cause"),
          value: "cause",
          align: "center",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t("Long Term Solutions"),
          value: "solution",
          align: "center",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t("Status"),
          value: "status",
          align: "center",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t("Assigned"),
          value: "assignedToNames",
          cellClass: "assignedCell",
          sortable: false
        },
        { text: "", value: "launch", align: "center", sortable: false, width: "40px" }
      ]),
      usersTab: 1,
      loading: false,
      machineStatuses: [],
      helpTimeout: false,
      machinesTimeout: false,
      assignDialog: false,
      userSearch: "",
      users: [],
      newUsers: [],
      assigningTicket: false,
      reasons: [],
      machineIds: [],
      machineGroupIds: [],
      reasonIds: [],
      errors: []
    };
  },
  components: {
    Cause,
    Solution,
    CounterMeasure,
    DialogCloseButton
  },
  created() {
    this.issueTable.page.itemsPerPage = 20;
    this.loadMachines();
    this.loadIssueTypes();
    this.loadUsers();
    Dispatcher.$listen("NEW_HELP_TICKET", this.loadIssuesTickets);
  },
  beforeDestroy() {
    Dispatcher.$silence("NEW_HELP_TICKET");
  },
  computed: {
    ...mapGetters({
      user: "session/User",
      statuses: "app/Statuses"
    }),
    filteredUsers() {
      if (!this.userSearch) {
        return this.users;
      }
      return this.users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(this.userSearch.toLowerCase());
      });
    }
  },
  methods: {
    moment,
    seconds,
    downloadCsv() {
      this.issueTable.page = {
        itemsPerPage: this.issueTable.total,
        current: this.issueTable.page.current,
        load: false,
        next: null,
        prev: null
      };
      this.loadIssuesTickets(true);
    },
    formatDateCSV(date) {
      if (date) {
        return moment(date).format(this.$datetime.default);
      }
      return null;
    },
    formatFromArrayCSV(data) {
      if (data) {
        return data.replaceAll("[", "").replaceAll("]", "").replaceAll("'", "");
      }
      return null;
    },
    formatStatusCSV(issue) {
      let status = this.$t("In process");
      if (issue.node.cause) {
        status = this.$t("Root cause found");
      }
      if (issue.node.closedDate) {
        status = this.$t("Resolved");
      }
      return status;
    },
    formatDataForCSV(data) {
      const rows = [];

      rows.push([
        this.$t("Title"),
        this.$t("Machine or Group"),
        this.$t("Ticketed Date"),
        this.$t("Start Date"),
        this.$t("End Date"),
        this.$t("Closed Date"),
        this.$t("Issue"),
        this.$t("Issue Type"),
        this.$t("Short Term Countermeasures"),
        this.$t("Root Cause"),
        this.$t("Long Term Solutions"),
        this.$t("Status"),
        this.$t("Assigned"),
        this.$t("Responding")
      ]);

      data.forEach((item) => {
        rows.push([
          item.node.title,
          item.node.machineName ? item.node.machineName : item.node.machineGroupName,
          this.formatDateCSV(item.node.ticketedDate),
          this.formatDateCSV(item.node.issueStartDate),
          this.formatDateCSV(item.node.issueEndDate),
          this.formatDateCSV(item.node.closedDate),
          item.node.reason,
          item.node.issueTypeName,
          this.formatFromArrayCSV(item.node.countermeasures),
          this.formatFromArrayCSV(item.node.cause),
          this.formatFromArrayCSV(item.node.solution),
          this.formatStatusCSV(item),
          this.formatFromArrayCSV(item.node.assignedToNames),
          this.formatFromArrayCSV(item.node.respondingNames)
        ]);
      });
      createCSVFromRows(
        rows,
        `issues_${this.closedDateExists ? "closed" : "open"}_${new Date().toISOString()}`
      );
    },
    loadMachines() {
      this.machinesLoaded = false;
      this.filterOptions = {
        machines: [],
        statuses: [],
        codes: [],
        parts: [],
        jobs: []
      };
      let params = {
        machine_group_id: this.machine_group_pk,
        start_date: moment().set("minute", 0).subtract(12, "h").toISOString(),
        end_date: moment().set("minute", 0).toISOString(),
        fields: [
          "factory_order",
          "lot_size",
          "part_number",
          "machine_name",
          "machine_id",
          "status_code"
        ],
        metrics: ["unplanned"]
      };
      this.$http.get("metrics/job_status/", { params: params }).then((res) => {
        this.machineStatuses = res.data;
        this.machinesLoaded = true;
        if (this.machinesTimeout) {
          clearTimeout(this.machinesTimeout);
        }
      });
    },
    loadUsers() {
      let query = `query {
        users {
          id,
          firstName,
          lastName,
        }
      }`;
      this.$http.post("graphql/", { query }).then((res) => {
        this.users = res.data.data.users;
      });
    },
    issueDebouncer() {
      if (this.helpTimeout) {
        clearTimeout(this.helpTimeout);
      }
      let loadIssuesTickets = this.loadIssuesTickets;
      this.helpTimeout = setTimeout(loadIssuesTickets, 500);
    },
    loadIssuesTickets(csv = false) {
      console.log("loading");
      this.loading = true;
      const query = `query (
        $andOr: GrapheneElasticAndORIssueSearchConnectionAndOrFilter,
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        issues (
          queryString: $search,
          filter: $filters,
          ordering: $ordering,
          andOr: $andOr,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [automatic]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              id,
              title,
              reason,
              countermeasures,
              cause,
              solution
              openedDate,
              closedDate,
              ticketedDate,
              assignedToNames,
              respondingNames,
              issueStartDate,
              issueEndDate,
              machineId,
              machineName,
              machineGroupName,
              issueTypeId,
              issueTypeName,
            }
          }
        }
      }`;
      let ordering = { ticketedDate: "DESC" };
      if (this.issueTable.sortBy.length > 0) {
        ordering = {};
        this.issueTable.sortBy.forEach((sortBy, index) => {
          ordering[sortBy] = this.issueTable.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        filters: {
          closedDate: { exists: this.closedDateExists },
          machineId: { terms: this.machineIds },
          reasonId: { in: this.reasonIds }
        },
        andOr: {
          MachineOrMachineGroup: {
            machineIds: this.machineIds,
            machineGroupIds: this.machineGroupIds
          }
        },
        ordering: ordering,
        before: false,
        after: false,
        search: ""
      };
      if (this.issueTable.page.load) {
        Object.assign(variables, this.issueTable.page.load);
      } else {
        variables["first"] = this.issueTable.page.itemsPerPage;
      }
      if (this.searchText) {
        variables["search"] = this.searchText;
      }
      if (this.filters && "machines" in this.filters && this.filters.machines.length > 0) {
        variables.filters.machineId = { terms: this.filters.machines };
        variables.andOr.MachineOrMachineGroup.machineIds = this.filters.machines;
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          if (csv) {
            this.formatDataForCSV(res.data.data.issues.edges);
          } else {
            this.issueTable.data = res.data.data.issues.edges;
            this.issueTable.total = res.data.data.issues.facets.automatic.doc_count;
            this.issueTable.page.next = res.data.data.issues.pageInfo.endCursor;
            this.issueTable.page.prev = res.data.data.issues.pageInfo.startCursor;
            this.issueTable.page.load = false;
          }
          this.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    closeAssign(ticket) {
      ticket.assignDialog = false;
      this.newUsers = [];
    },
    issueAssign(ticket) {
      this.assigningTicket = true;
      let newUserIds = [];
      this.newUsers.forEach((user) => {
        newUserIds.push(user.id);
      });
      const query = `mutation ($id: ID!, $issueData: IssueInput!){
        updateIssue(globalId:$id, issueData:$issueData){
          ok,
          issue{
            assignedTo,
            assignedToNames,
          }
        }
      }`;
      const variables = {
        id: ticket.node.id,
        issueData: {
          assignNew: newUserIds
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          this.newUsers = [];
          this.assigningTicket = false;
          ticket.assignDialog = false;
          this.loadIssuesTickets();
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    getCMS(item) {
      if (item.node.countermeasures) {
        return item.node.countermeasures
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    getCauses(item) {
      if (item.node.cause) {
        return item.node.cause
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    getSolutions(item) {
      if (item.node.solution) {
        return item.node.solution
          .replaceAll("[", "")
          .replaceAll("]", "")
          .replaceAll("'", "")
          .split(",");
      }
      return [];
    },
    loadIssueTypes() {
      this.loading = true;
      const query = `query ($machineGroupId: ID!) {
        issueReasons {
          edges {
            node {
              id
              pk
              text
              issueType {
                id
                pk
              }
            }
          }
        }
        machineGroup(id: $machineGroupId) {
          id
          ... on MachineGroupType {
            id
            pk
            allMachines {
              id
              pk
            }
            allSubGroups {
              id
              pk
            }
          }
        }
      }`;
      const variables = { machineGroupId: this.machine_group_id };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.reasons = res.data.data.issueReasons.edges;
          const reasonIds = [6, 7, 8];
          this.reasons.forEach((reason) => {
            if (reason.node.pk && !(reason.node.pk in reasonIds)) {
              reasonIds.push(reason.node.pk);
            }
          });
          this.machineIds = res.data.data.machineGroup.allMachines.map((machine) => machine.pk);
          this.machineGroupIds = res.data.data.machineGroup.allSubGroups.map((group) => group.pk);
          this.machineGroupIds.push(parseInt(this.machine_group_pk));
          this.reasonIds = reasonIds;
          this.loadIssuesTickets();
        })
        .catch((res) => {
          this.errors = res.errors;
          this.loading = false;
        });
    },
    getStatusIcon(issue) {
      let icon = "mdi-circle-outline";
      if (issue.node.cause) {
        icon = "mdi-circle-slice-4";
      }
      if (issue.node.solution) {
        icon = "mdi-circle-slice-6";
      }
      if (issue.node.closedDate) {
        icon = "mdi-circle-slice-8";
      }
      return icon;
    },
    goToTicket(item) {
      this.$router.push({ name: "ticket", params: { ticket_id: item.node.id } });
    },
    launchTicket(item) {
      let routeData = this.$router.resolve({ name: "ticket", params: { ticket_id: item.node.id } });
      window.open(routeData.href, "_blank");
    },
    addIssue(issues, issue) {
      issues.unshift({ node: issue });
      this.issueTables = [...this.issueTables];
    },
    updateIssue(item, issue) {
      //just copy the updated array into the existing one to re-render ui
      item.node.cause = issue.cause;
      item.node.countermeasures = issue.countermeasures;
      item.node.solution = issue.solution;
    },
    hasCause(issues) {
      let cause = false;
      if (issues) {
        issues.forEach((issue) => {
          if (issue.cause) {
            cause = true;
          }
        });
      }
      return cause;
    }
  },
  watch: {
    searchText: function () {
      this.issueDebouncer();
    },
    "issueTable.sortBy": function () {
      this.issueDebouncer();
    },
    "issueTable.sortDesc": function () {
      this.issueDebouncer();
    },
    "issueTable.page.itemsPerPage": function () {
      this.issueDebouncer();
    },
    "issueTable.page.current": function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.issueTable.page.load = {
          after: this.issueTable.page.next,
          before: false,
          first: this.issueTable.page.itemsPerPage
        };
        this.issueDebouncer();
      } else if (newPage < oldPage) {
        this.issueTable.page.load = {
          after: false,
          before: this.issueTable.page.prev,
          last: this.issueTable.page.itemsPerPage
        };
        this.issueDebouncer();
      }
    },
    closedDateExists() {
      this.loadIssuesTickets();
    },
    "filters.machines.length": function () {
      this.loadIssuesTickets();
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
.ldms_tickets {
  z-index: 8;
  width: 100vw;
  height: calc(100% - 7rem);
  margin-top: 0.25rem;
  padding-bottom: 0.5rem;
  .header-row {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
  }
  .v-input {
    max-width: 500px;
    margin: auto auto 0.5rem;
  }
  .v-data-table {
    .machine-link {
      color: unset;
      text-decoration: none;
      display: flex;
      align-items: center;
      p {
        white-space: nowrap;
        margin: 0 0.5rem 0 0;
      }
    }
    .issueCell {
      min-width: 150px;
    }
    .assignedCell {
      min-width: 250px;
    }
    .harveyBall {
      font-size: 2rem;
    }
    .v-badge__badge {
      font-size: 17px;
      height: 25px;
      min-width: 25px;
      border-radius: 25px;
      color: $darkGrey;
      font-weight: 500;
    }
    .v-btn {
      margin: 0.5rem;

      i {
        font-size: 1rem;
      }
    }
    .reasonText {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .assignedRow {
      display: flex;
      align-items: center;
      p {
        margin-left: 1rem;
        font-size: 0.8rem;
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      margin: 0.15rem 0;
      .tag {
        .tag-chip {
          padding: 0.25rem 0.5rem;
          white-space: nowrap;
          margin: 0.15rem 0.25rem;
          border-radius: 5px;
          color: white;
        }
      }
    }
    .status-wrapper {
      height: 100%;
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
        color: white;
      }
    }
  }
  .v-icon.v-icon {
    font-size: 24px !important;
  }
}
</style>
