<template>
  <div class="root">
    <v-btn
      class="close"
      :icon="true"
      fab
      small
      @click="onClick"
    >
      <v-icon>mdi-close-thick</v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: "DialogClose",
  props: {
    onClick: { type: Function, default: () => null }
  }
};
</script>
<style lang="scss" scoped>
.root {
  position: relative;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
</style>
