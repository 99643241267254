<template>
  <div class="machine-group-metrics">
    <div class="menu">
      <v-tabs
        grow
        class="tabs"
        v-model="tab"
        align-with-title
        key="tabs"
        :show-arrows="false"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <development-feature>
          <template #edge>
            <v-tab
              key="group-summary"
              :to="{ name: AppRoutes.groupSummary.name, params: 'route.params' }"
              exact-path
            >
              <p>
                <span class="button_text">{{ $t("Summary") }}</span>
                <span class="tiny_button_text">{{ $t("Summary") }}</span>
              </p>
            </v-tab>
          </template>
        </development-feature>

        <v-tab
          key="realtime"
          :to="{ name: 'group-realtime', params: 'route.params' }"
        >
          <p>
            <span class="button_text">{{ $t("Real Time Status") }}</span>
            <span class="tiny_button_text">{{ $t("Realtime") }}</span>
          </p>
        </v-tab>
        <v-tab
          key="summary"
          :to="{ name: 'group-summary', params: 'route.params' }"
        >
          <p>
            <span class="button_text">{{ $t("Machine status") }}</span>
            <span class="tiny_button_text">{{ $t("Machine status") }}</span>
          </p>
        </v-tab>
        <v-tab
          key="zone-control"
          :to="{ name: 'zone-control', params: 'route.params' }"
        >
          <p>
            <span class="button_text">{{ $t("Zone Control") }}</span>
            <span class="tiny_button_text">{{ $t("Zone Control") }}</span>
          </p>
        </v-tab>
        <v-tab
          key="p911"
          :to="{ name: 'tickets', params: 'route.params' }"
        >
          <p>
            <span class="button_text">{{ $t("Tickets") }}</span>
            <span class="tiny_button_text">{{ $t("Tickets") }}</span>
          </p>
        </v-tab>
        <development-feature>
          <template v-slot:edge>
            <v-tab
              key="quality"
              :to="{ name: 'quality', params: 'route.params' }"
            >
              <p>
                <span class="button_text">{{ $t("Quality") }}</span>
                <span class="tiny_button_text">{{ $t("Quality") }}</span>
              </p>
            </v-tab>
          </template>
        </development-feature>
      </v-tabs>
    </div>
    <router-view
      v-if="machinesLoaded"
      :machinesLookup="machinesLookup"
      :machines="machines"
    />
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";
.theme--light {
  .machine-group-metrics {
    .v-tab--active {
      color: $darkGrey;
    }
  }
}
.machine-group-metrics {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;

  .menu {
    height: 3rem;
    width: 100%;
    .tabs {
      margin: 0 1rem;
      i {
        margin-right: 1rem;
      }
    }
  }

  .tiny_button_text {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    .v-tabs--align-with-title
      > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
      > .v-slide-group__wrapper
      > .v-tabs-bar__content
      > .v-tabs-slider-wrapper
      + .v-tab {
      margin-left: 0 !important;
    }
    .menu {
      height: 60px;

      .tabs {
        margin: 0 !important;

        .v-slide-group__wrapper {
          z-index: 100;

          .v-slide-group__content {
            justify-content: space-around !important;

            a {
              flex: 1;
              margin-bottom: 6px;
            }
          }
        }
      }

      .mdi {
        font-size: 24px;
        max-height: 30px;
        margin: 0 !important;
      }

      .button_text {
        display: none;
        font-size: 14px;
      }

      .tiny_button_text {
        display: block;
        font-size: 9px;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { Fragment } from "vue-fragment";
import { mapActions } from "vuex";

import { Dispatcher } from "@/utils/eventbus";

import DevelopmentFeature from "../../../components/DevelopmentFeature.vue";
import PieChart from "../../../components/charts/PieChart";
import { AppRoutes } from "../../../shared/app-routes";

export default {
  computed: {
    AppRoutes() {
      return AppRoutes;
    }
  },
  props: ["machine_group_id", "machine_group_pk"],
  components: {
    DevelopmentFeature
  },
  data() {
    return {
      tab: 1,
      machineGroupSocket: false,
      machines: [],
      machinesLookup: {},
      machinesLoaded: false
    };
  },
  created() {
    this.loadMachines();
    this.connectMachineGroupSocket();
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
    if (this.machineGroupSocket) {
      this.machineGroupSocket.close();
      this.machineGroupSocket = null;
    }
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    connectMachineGroupSocket() {
      this.machineGroupSocket = this.$socket(`machine_group/${this.machine_group_pk}/`, (r) => {
        let data = JSON.parse(r.data);
        let type = data.type;
        let message = data.message;
        switch (type) {
          case "new_help_ticket":
            Dispatcher.$message("NEW_HELP_TICKET", message);
            break;
        }
      });
    },
    loadMachines() {
      this.machinesLoaded = false;
      let query = `query getMachineGroup($machineGroupId: Int!, $id: ID!){
        machineGroup(id: $id){
          id
          ... on MachineGroupType{
            name
            id
            prioritization
          }
        }
        machines(machineGroupId: $machineGroupId){
          name
          id
          pk
          oeeTarget
          performanceTarget
          qualityTarget
      		runtimeTarget,
      		contributesToTotal,
        }
      }`;
      let variables = { machineGroupId: this.machine_group_pk, id: this.machine_group_id };
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        this.setTitles({
          title: "<h2>" + res.data.data.machineGroup.name + "</h2>",
          mobile: "<h3>" + res.data.data.machineGroup.name + "</h3"
        });
        this.machines = res.data.data.machines;
        this.machinesLookup = {};
        this.machines.forEach((machine) => {
          this.machinesLookup[machine.pk] = machine;
        });
        this.loadMachineData();
        this.machinesLoaded = true;
      });
    },
    loadMachineData() {
      this.machineDataLoaded = false;
      let params = {
        from_date: moment().set("minute", 0).subtract(24, "h").toISOString(),
        to_date: moment().set("minute", 0).toISOString(),
        machine_group_id: this.machine_group_pk
      };
      this.$http.get("metrics/machine_metric_breakdown/", { params: params }).then((res) => {
        this.machineData = res.data;
        this.machineData.forEach((machine, i) => {
          if (machine.key in this.machinesLookup) {
            this.machinesLookup[machine.key]["trend"] = machine.trend;
          }
        });

        this.machineDataLoaded = true;
        if (this.machineDataTimeout) {
          clearTimeout(this.machineDataTimeout);
        }
        let loadMachineData = this.loadMachineData;
        this.machineDataTimeout = setTimeout(loadMachineData, 300000);
      });
    }
  },
  watch: {
    $route(to, from) {
      this.loadMachines();
    }
  }
};
</script>
