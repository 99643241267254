<template>
  <div class="tla-data-table">
    <div class="card data-table-card">
      <GenericTable
        :columns="[
          { title: '', key: 'name' },
          { title: $t('Total Duration'), key: 'duration', format: seconds, sortable: true },
          { title: $t('Total Downtime'), key: 'total_downtime', format: seconds, sortable: true },
          { title: $t('Downtime Percent'), key: 'downtime_percentage', sortable: false },
          { title: $t('Production Loss'), key: 'loss', format: percent, sortable: true }
        ]"
        :nested_columns="[
          { key: 'status_name' },
          { key: 'status_duration' },
          { key: 'status_downtime', format: seconds },
          { key: 'status_downtime_percentage', format: percent },
          { key: 'status_loss', format: percent }
        ]"
        :data="tableData"
      />
      <div class="footer">
        <v-btn
          color="primary"
          small
          class="ma-2"
          @click="downloadCsv"
          style="font-size: 12px"
        >
          <v-icon style="font-size: 12px">mdi-file-download</v-icon> CSV
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";

export default {
  name: "TLADataTable",
  components: { GenericTable },
  props: ["filters"],
  data() {
    return {
      rawData: [],
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses"
    })
  },
  mounted() {
    this.rawData = [];
    this.tableData = [];

    this.loadTLATableData()
      .then((result) => {
        this.rawData = result;
        this.tableData = this.createTableData(result);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    seconds,
    createCSVFromRows,
    downloadCsv() {
      const rows = [
        [
          "Machine Name",
          "Status Name",
          "Total Machine Duration (Mins)",
          "Total Machine Downtime (Mins)",
          "Status Downtime (Mins)",
          "Total Machine Loss",
          "Status Downtime Percentage",
          "Status Loss"
        ]
      ];
      for (const t of this.tableData) {
        for (const n of t._nested) {
          rows.push([
            t.name,
            n.status_name,
            (t.duration / 60).toFixed(0),
            (t.total_downtime / 60).toFixed(0),
            (n.status_downtime / 60).toFixed(0),
            `${(t.loss * 100).toFixed(2)}%`,
            `${(n.status_downtime_percentage * 100).toFixed(2)}%`,
            `${(n.status_loss * 100).toFixed(2)}%`
          ]);
        }
      }
      createCSVFromRows(rows, `tla_${new Date().toISOString()}`);
    },
    percent(val) {
      return `${(val * 100).toFixed(2)}%`;
    },
    createStatusMap() {
      const statusMap = {};
      for (const status of this.statuses) {
        for (const statusCode of status.statuscodeSet) {
          statusMap[statusCode.code] = { ...statusCode, running: status.running };
        }
      }
      return statusMap;
    },
    loadTLATableData() {
      return new Promise((resolve, reject) => {
        //guard
        if (!this.filters.from_date || !this.filters.to_date) {
          reject();
        }

        let params = {
          from_date: this.filters.from_date,
          to_date: this.filters.to_date
        };

        params.machine_group_id = this.filters.machine_groups.length
          ? this.filters.machine_groups.join(",")
          : null;
        params.machine_id = this.filters.machines.length ? this.filters.machines.join(",") : null;
        params.part_numbers = this.filters.part_numbers.length
          ? this.filters.part_numbers.join(",")
          : null;
        params.status_codes = this.filters.status_codes.length
          ? this.filters.status_codes.join(",")
          : null;

        this.$http
          .get("metrics/availability_per_machine_code/", {
            params: params
          })
          .then((result) => {
            resolve(result.data);
          })
          .catch((e) => {
            reject();
          });
      });
    },
    createTableData(result) {
      const statusMap = this.createStatusMap();

      const tableData = [];
      for (const r of result.buckets) {
        const td = {
          name: r.last_record.hits.hits[0]._source.machine_name,
          duration: r.duration.value,
          total_downtime: r.duration.value - r.runtime.duration.value,
          downtime_percentage: "--",
          loss: (r.duration.value - r.runtime.duration.value) / r.duration.value,
          _nested: []
        };

        for (const n of r.codes.buckets) {
          const running = statusMap[n.key] && statusMap[n.key].running;
          if (!running) {
            td._nested.push({
              status_name: statusMap[n.key]
                ? `${statusMap[n.key].code}:${statusMap[n.key].description}`
                : `unknown status (${n.key})`,
              status_duration: "",
              status_downtime: n.duration.value,
              status_downtime_percentage:
                n.duration.value / (r.duration.value - r.runtime.duration.value || 1),
              status_loss: n.duration.value / (r.duration.value || 1)
            });
          }
        }
        tableData.push(td);
      }

      return tableData;
    }
  },
  watch: {
    filters: function () {
      this.rawData = [];
      this.tableData = [];

      this.loadTLATableData()
        .then((result) => {
          this.rawData = result;
          this.tableData = this.createTableData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.tla-data-table {
  flex: 1;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  .data-table-card {
    height: 100%;
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.footer {
  text-align: left;
  flex: unset;
}
</style>
