<template>
  <div class="performance-summary-view">
    <GridLayout
      :col-num="12"
      :row-height="110"
      :is-resizable="false"
      :is-draggable="false"
      :vertical-compact="true"
      :use-css-transforms="false"
      :layout.sync="layout"
    >
      <GridItem
        v-for="(item, index) in layout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <Component
          v-show="showChart(item.component)"
          :ref="item.component"
          :is="item.component"
          v-bind="item.props"
        />
      </GridItem>
    </GridLayout>
  </div>
</template>
<script>
import { GridItem, GridLayout } from "vue-grid-layout";

import { coralReef, peachPuff, tiffanyBlue } from "@/scss/_variables.scss";
import { getTimeLabel } from "@/utils/p911AnalyticsUtils";

import P911AnalyticsIssueCards from "../P911AnalyticsIssueCards";
import P911GroupLevelOneChart from "./P911GroupLevelOneChart.vue";
import P911GroupLevelTwoChart from "./P911GroupLevelTwoChart.vue";
import P911OpenClosedPieChart from "./P911OpenClosedPieChart.vue";
import P911OpenClosedTrendChart from "./P911OpenClosedTrendChart.vue";
import P911TicketsByMachine from "./P911TicketsByMachine.vue";

export default {
  name: "P911PerformanceSummaryView",
  props: {
    filters: { type: Object, default: () => {} },
    levelP1: { type: Object, default: () => null },
    levelP2: { type: Object, default: () => null }
  },
  components: {
    P911AnalyticsIssueCards,
    P911OpenClosedPieChart,
    P911OpenClosedTrendChart,
    P911GroupLevelOneChart,
    P911GroupLevelTwoChart,
    P911TicketsByMachine,
    GridLayout,
    GridItem
  },
  data() {
    return {
      trendChartData: { datasets: [{ data: [] }], labels: [] },
      statusChartData: { datasets: [{ data: [] }], labels: [] }
    };
  },
  computed: {
    layout() {
      return [
        {
          x: 0,
          y: 0,
          w: 12,
          h: 0.8,
          i: 0,
          component: "P911AnalyticsIssueCards",
          props: {
            filters: this.filters,
            averageTimeLabel: "Avg Open Time"
          }
        },
        {
          x: 0,
          y: 0.8,
          w: 6,
          h: 3,
          i: 1,
          component: "P911OpenClosedTrendChart",
          props: {
            trendChartData: this.trendChartData
          }
        },
        {
          x: 6,
          y: 0.8,
          w: 6,
          h: 3,
          i: 2,
          component: "P911OpenClosedPieChart",
          props: {
            statusChartData: this.statusChartData
          }
        },
        {
          x: 0,
          y: 3.8,
          w: 6,
          h: 3,
          i: 3,
          component: "P911GroupLevelOneChart",
          props: {
            levelP1: this.levelP1,
            filters: this.filters
          }
        },
        {
          x: 6,
          y: 3.8,
          w: 6,
          h: 3,
          i: 4,
          component: "P911GroupLevelTwoChart",
          props: {
            levelP2: this.levelP2,
            filters: this.filters
          }
        },
        {
          x: 0,
          y: this.levelP1 && this.levelP2 ? 6.8 : 3.8,
          w: 12,
          h: 3,
          i: 5,
          component: "P911TicketsByMachine",
          props: {
            filters: this.filters
          }
        }
      ];
    }
  },
  methods: {
    showChart(component) {
      switch (component) {
        case "P911GroupLevelOneChart":
          if (!this.levelP1) {
            return false;
          }
          return true;
        case "P911GroupLevelTwoChart":
          if (!this.levelP2) {
            return false;
          }
          return true;
        default:
          return true;
      }
    },
    async getP911TrendData() {
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        machine_ids: this.filters.machines.map((machine) => machine.pk)
      };
      if (this.filters.interval) {
        params.interval = this.filters.interval;
      }
      try {
        const response = await this.$http.get("help/open_closed_trend/", { params });
        if (response && response.data) {
          this.trendChartData = {
            datasets: [
              {
                type: "line",
                data: response.data.map((item) => item.doc_count),
                label: this.$t("P911 Tickets"),
                borderColor: peachPuff,
                fill: false
              },
              {
                data: response.data.map((item) => item.closed.doc_count),
                label: this.$t("Closed"),
                backgroundColor: response.data.map(() => tiffanyBlue)
              },
              {
                data: response.data.map((item) => item.open.doc_count),
                label: this.$t("Open"),
                backgroundColor: response.data.map(() => coralReef)
              }
            ],
            labels: response.data.map((item) =>
              getTimeLabel(item.key_as_string, this.filters.dateFormat)
            )
          };
          const closed = response.data.reduce((acc, curr) => acc + curr.closed.doc_count, 0);
          const open = response.data.reduce((acc, curr) => acc + curr.open.doc_count, 0);
          this.statusChartData = {
            datasets: [{ data: [closed, open] }],
            labels: [this.$t("Closed"), this.$t("Open")]
          };
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
