<template>
  <div class="card top-machines">
    <div class="title-pagination-container">
      <div class="chart-title-icons">
        <span class="header-text">{{ $t("Top Help Tags") }}</span>
      </div>
    </div>
    <div class="analytics-card-content">
      <TreeMapChart
        class="tree-map"
        :data="helpTagsTreeMapData"
        :id="'help-tags-tree-map'"
        :handleClick="(event) => handleHelpTagClick(event)"
        :handleZoomOutClick="(event) => handleZoomOutClick(event)"
      />
    </div>
  </div>
</template>
<script>
import TreeMapChart from "@/components/charts/TreeMapChart";
import { hexToRGB } from "@/utils/colors";
import { getColor } from "@/utils/p911AnalyticsUtils";

export default {
  name: "P911AnalyticsHelpTags",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    filterChange: { type: Boolean, default: false },
    helpTags: { type: Array, default: () => [] }
  },
  components: {
    TreeMapChart
  },
  data() {
    return {
      pageSize: 10,
      helpTagsTreeMapData: []
    };
  },
  computed: {
    filterHasChanged() {
      return this.filterChange;
    }
  },
  methods: {
    handleZoomOutClick(event) {
      this.interactiveFilters.helpTag = null;
    },
    getLevelIndex(data, levelIndices) {
      if ("parent" in data.parent) {
        this.getLevelIndex(data.parent, levelIndices);
      }
      if (data.seriesDataItem._index !== null) {
        levelIndices.push(data.seriesDataItem._index);
      }
    },
    handleHelpTagClick(event) {
      const levelIndices = [];
      this.getLevelIndex(event.target.dataItem.treeMapDataItem, levelIndices);
      let selectedLevelId = "";
      let parentLevel = null;
      levelIndices.forEach((levelIndex, index) => {
        if (index === 0) {
          parentLevel = this.helpTagsTreeMapData[levelIndex];
        } else if (parentLevel && "children" in parentLevel) {
          parentLevel = parentLevel.children[levelIndex];
        }
        if (index === levelIndices.length - 1) {
          selectedLevelId = parentLevel.id;
        }
      });
      this.interactiveFilters.helpTag = { [`tagLevel${levelIndices.length}`]: selectedLevelId };
    },
    getParentTagColor(tag) {
      let color = "";
      Array.from({ length: tag.depth - 1 }, (_, i) => i + 1)
        .reverse()
        .every((key) => {
          const foundParent = this.helpTags.find(
            (helpTag) => helpTag.depth === key && helpTag.treeId === tag.treeId
          );
          if (foundParent && foundParent.color) {
            color = foundParent.color;
            return false;
          }
          return true;
        });
      return color;
    },
    getChildData(levelData, depth, parentTag = null, currentLevelTag) {
      if (currentLevelTag && depth === 1) {
        return {
          name: currentLevelTag.name ? currentLevelTag.name : this.$t("Unknown Tag"),
          value: levelData.count,
          color: currentLevelTag.color
            ? hexToRGB(currentLevelTag.color, 1)
            : getColor(levelData.tag_id),
          id: currentLevelTag.id ? currentLevelTag.id : "-1"
        };
      }
      if (
        currentLevelTag &&
        currentLevelTag.depth === depth &&
        currentLevelTag.treeId === parentTag.treeId &&
        currentLevelTag.lft > parentTag.lft &&
        currentLevelTag.rgt < parentTag.rgt
      ) {
        let levelColor = currentLevelTag.color;
        if (!levelColor && depth > 1) {
          levelColor = this.getParentTagColor(currentLevelTag);
        }
        return {
          name: currentLevelTag.name ? currentLevelTag.name : this.$t("Unknown Tag"),
          value: levelData.count,
          color: levelColor ? hexToRGB(levelColor, 1) : getColor(levelData.tag_id),
          id: currentLevelTag.id ? currentLevelTag.id : "-1"
        };
      }
      return [];
    },
    buildChildren(data, parentLevelData, level, parentTag) {
      if (`tag_level_${level}` in data) {
        const parentLevelChildData = data["tag_level_" + level].flatMap((item) => {
          const foundLevelTag = this.helpTags.find((helpTag) => helpTag.id === item.tag_id);
          const levelData = this.getChildData(item, level, parentTag, foundLevelTag);
          const nextLevel = `tag_level_${level + 1}`;
          if (nextLevel in data && data[nextLevel].length > 0) {
            this.buildChildren(data, levelData, level + 1, foundLevelTag);
          }
          return levelData;
        });
        if (parentLevelChildData.length > 0) {
          parentLevelData.children = parentLevelChildData;
        }
      }
      return parentLevelData;
    },
    async getTicketsByTag() {
      const machines = this.interactiveFilters.machines.map((machine) => machine.id);
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_ids:
          machines.length > 0 ? machines : this.filters.machines.map((machine) => machine.pk)
      };
      try {
        const response = await this.$http.get("help/tickets_by_tag/", { params });
        if (response && response.data && "tag_level_1" in response.data) {
          this.helpTagsTreeMapData = response.data.tag_level_1.map((levelOne) => {
            const foundLevelOneTag = this.helpTags.find(
              (helpTag) => helpTag.id === levelOne.tag_id
            );
            const levelOneData = this.getChildData(levelOne, 1, null, foundLevelOneTag);
            return this.buildChildren(response.data, levelOneData, 2, foundLevelOneTag);
          });
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  },
  watch: {
    "interactiveFilters.machines.length": function () {
      if (!this.filterHasChanged) {
        this.getTicketsByTag();
      }
    }
  }
};
</script>
