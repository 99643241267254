<template>
  <div class="machine-group-tickets">
    <div class="menu">
      <div class="large-width">
        <v-tabs
          class="p911-issues-tabs"
          v-model="tab"
          key="tabs1"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="realtime">
            <p>
              <span class="button_text">{{ $t("P911") }}</span>
              <span class="tiny_button_text">{{ $t("P911") }}</span>
            </p>
          </v-tab>
          <v-tab key="summary">
            <p>
              <span class="button_text">{{ $t("Issues") }}</span>
              <span class="tiny_button_text">{{ $t("Issues") }}</span>
            </p>
          </v-tab>
        </v-tabs>
        <v-text-field
          v-model="searchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          class="text-search"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <div class="machine-selector-tabs-container">
          <MachineGroupSelector
            class="machine-group-selector"
            :hideMachineGroupSelector="true"
            :setMachineGroups="setMachineGroups"
            :condensedView="true"
          />
          <v-tabs
            class="open-closed-tabs"
            v-model="openClosedTab"
            align-with-title
            key="tabs2"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="open">
              <p>
                <span class="button_text">{{ $t("Open") }}</span>
                <span class="tiny_button_text">{{ $t("Open") }}</span>
              </p>
            </v-tab>
            <v-tab key="closed">
              <p>
                <span class="button_text">{{ $t("Closed") }}</span>
                <span class="tiny_button_text">{{ $t("Closed") }}</span>
              </p>
            </v-tab>
          </v-tabs>
        </div>
      </div>
      <div class="small-width">
        <div class="tabs-container">
          <v-tabs
            class="p911-issues-tabs"
            v-model="tab"
            key="tabs1"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="realtime">
              <p>
                <span class="button_text">{{ $t("P911") }}</span>
                <span class="tiny_button_text">{{ $t("P911") }}</span>
              </p>
            </v-tab>
            <v-tab key="summary">
              <p>
                <span class="button_text">{{ $t("Issues") }}</span>
                <span class="tiny_button_text">{{ $t("Issues") }}</span>
              </p>
            </v-tab>
          </v-tabs>
          <v-tabs
            class="open-closed-tabs"
            v-model="openClosedTab"
            align-with-title
            key="tabs2"
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="open">
              <p>
                <span class="button_text">{{ $t("Open") }}</span>
                <span class="tiny_button_text">{{ $t("Open") }}</span>
              </p>
            </v-tab>
            <v-tab key="closed">
              <p>
                <span class="button_text">{{ $t("Closed") }}</span>
                <span class="tiny_button_text">{{ $t("Closed") }}</span>
              </p>
            </v-tab>
          </v-tabs>
        </div>
        <div class="search-machine-selector-container">
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            :label="$t('Search')"
            class="text-search"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <MachineGroupSelector
            class="machine-group-selector"
            :hideMachineGroupSelector="true"
            :setMachineGroups="setMachineGroups"
            :condensedView="true"
          />
        </div>
      </div>
    </div>
    <HelpTickets
      v-if="tab === 0"
      :machines="machines"
      :machine_group_id="machine_group_id"
      :machine_group_pk="machine_group_pk"
      :closedDateExists="showClosedIssues"
      :searchText="searchText"
      :filters="filters"
    />
    <LDMSTable
      v-if="tab === 1"
      :machines="machines"
      :machine_group_id="machine_group_id"
      :machine_group_pk="machine_group_pk"
      :machinesLookup="machinesLookup"
      :closedDateExists="showClosedIssues"
      :searchText="searchText"
      :filters="filters"
    />
  </div>
</template>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";
.machine-group-tickets {
  position: relative;
  height: 100%;
  width: 100%;
  .menu {
    z-index: 10;
    width: 100%;
    max-width: 100%;
    display: flex;
    height: auto;
    min-height: 3rem;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    .large-width {
      width: 100%;
    }
    .small-width {
      flex-direction: column;
      width: 100%;
    }
  }
  .v-tabs {
    width: auto;
  }
  .group_help_tickets {
    z-index: 8;
    width: 100vw;
    height: 100%;
  }
  .v-data-table__mobile-row {
    .v-data-table__mobile-row__header {
      color: $blue;
    }
  }
  .v-data-table__mobile-row:last-of-type {
    width: 100%;
    padding: 0;
    .v-data-table__mobile-row__header {
      display: none;
      padding: 0;
    }
    .v-data-table__mobile-row__cell {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0 1rem;
      .launch-button {
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .machine-group-metrics {
    .machine-group-tickets {
      .menu {
        .v-tabs-bar {
          margin-top: 10px;
          height: 40px;
        }
        .tiny_button_text {
          font-size: 13px;
        }
      }
    }
    .text-search {
      margin-bottom: 5px;
    }
  }
}
@media all and (min-width: 991px) {
  .machine-group-metrics {
    .machine-group-tickets {
      .small-width {
        display: none;
      }
      .large-width {
        display: flex;
        .p911-issues-tabs {
          display: flex;
          order: 1;
          padding: 0 1rem;
          flex-grow: 0;
          align-self: flex-end;
        }
        .text-search {
          display: flex;
          order: 2;
          padding: 0;
          flex-grow: 1;
          align-self: flex-end;
          margin-left: 1rem;
        }
        .machine-selector-tabs-container {
          display: flex;
          order: 3;
          justify-content: flex-end;
          .machine-group-selector {
            max-width: 400px;
            display: flex;
            align-self: flex-end;
            padding: 0 20px;
          }
          .open-closed-tabs {
            display: flex;
            justify-content: flex-end;
            padding: 0 1rem;
            flex-grow: 0;
            align-self: flex-end;
          }
        }
      }
    }
  }
}
@media all and (max-width: 990px) {
  .machine-group-metrics {
    .machine-group-tickets {
      height: calc(100% - 5rem);
      .large-width {
        display: none;
      }
      .small-width {
        display: flex;
        .v-tabs-bar {
          height: 30px;
          margin-top: -10px;
          margin-bottom: -10px;
        }
        .tabs-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .open-closed-tabs {
            display: flex;
            justify-content: flex-end;
          }
        }
        .search-machine-selector-container {
          display: flex;
          align-items: center;
          margin: 1rem 1rem 0 1rem;
          .text-search {
            display: flex;
            padding: 0;
            flex-grow: 1;
            align-self: flex-end;
            padding-right: 1rem;
          }
          .machine-group-selector {
            max-width: 400px;
            display: flex;
            padding-left: 20px;
            .total-badge {
              width: 100%;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";

import MachineGroupSelector from "@/views/analytics/production_report/MachineGroupSelector";

import HelpTickets from "./HelpTickets";
import LDMSTable from "./LDMSTable";

export default {
  props: ["machines", "machine_group_id", "machine_group_pk", "machinesLookup", "input_tab"],
  data() {
    return {
      tab: Number(this.input_tab) || 0,
      openClosedTab: 0,
      searchText: "",
      filters: {
        machines: this.machines.map((machine) => machine.pk)
      }
    };
  },
  components: {
    HelpTickets,
    LDMSTable,
    MachineGroupSelector
  },
  computed: {
    showClosedIssues() {
      return this.openClosedTab !== 0;
    }
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    async getMachineData(groups, machines) {
      let groupsArr = [-1];
      let machinesArr = [-1];
      if (groups.length > 0) {
        groupsArr = groups;
      }
      if (machines.length > 0) {
        machinesArr = machines;
      }
      const query = `query($groupIds: [Int], $machineIds: [Int]){
        machineGroups(ids: $groupIds){
          id
          allMachines {
            name,
            id,
            pk,
            performanceTarget,
            qualityTarget,
            oeeTarget,
            runtimeTarget
          }
        }
        machines(ids: $machineIds) {
          name,
          id,
          pk,
          performanceTarget,
          qualityTarget,
          oeeTarget,
          runtimeTarget
        }
      }`;
      const variables = {
        groupIds: groupsArr,
        machineIds: machinesArr
      };
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (response) {
          let machineList = [];
          const machineListByMachineGroup = response.data.data.machineGroups;
          for (const list of machineListByMachineGroup) {
            machineList = machineList.concat(list.allMachines);
          }
          //filter out the duplicates that exist in both the list we got from the
          //groups and the list we sent
          machineList = machineList.filter((machine) => machinesArr.indexOf(machine.pk) < 0);
          //then add the data from the individual machines
          machineList = machineList.concat(response.data.data.machines);

          return machineList;
        }
      } catch (error) {
        return [];
      }
    },
    setMachineGroups(machineGroups, machines) {
      //load machines
      //we need to load zones with a list of IDs
      this.getMachineData(machineGroups, machines).then((data) => {
        const updatedMachines = data.map((machine) => machine.pk);
        this.filters = { machines: updatedMachines };
      });
    }
  },
  watch: {
    machines: function () {
      this.filters.machines = this.machines.map((machine) => machine.pk);
    }
  }
};
</script>
