<template>
  <div class="card top-machines">
    <div class="title-pagination-container">
      <div class="chart-title-icons">
        <span class="header-text">{{ $t("Top Impacted Machines") }}</span>
        <div class="chart-icons">
          <v-icon
            class="bar-chart-icon"
            :color="showBarChart ? 'primary' : null"
            @click="handleShowBarChart"
          >
            mdi-chart-bar
          </v-icon>
          <v-icon
            class="tree-map-icon"
            :color="showTreeMap ? 'primary' : null"
            @click="handleShowTreeMap"
          >
            mdi-chart-tree
          </v-icon>
        </div>
      </div>
      <div class="analytics-card-header">
        <AnalyticsPagination :handleTabChange="handleTabChange" />
      </div>
    </div>
    <div class="analytics-card-content">
      <TreeMapChart
        v-if="showTreeMap"
        class="tree-map"
        :data="machinesTreeMapData"
        :id="'machines-tree-map'"
        :handleClick="(event) => handleTreeMapClick(event)"
      />
      <BarChart
        v-if="showBarChart"
        class="bar-chart"
        :chartData="machinesBarChartData"
        :axisType="AXISTYPE.SHORTEN"
        :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import TreeMapChart from "@/components/charts/TreeMapChart";
import { tealBlue } from "@/scss/_variables.scss";
import { hexToRGB } from "@/utils/colors";
import { getDatasets, handleBackgroundColorsNoOthersPresent } from "@/utils/p911AnalyticsUtils";

export default {
  name: "P911AnalyticsMachines",
  props: {
    filters: { type: Object, default: () => {} },
    interactiveFilters: { type: Object, default: () => {} },
    filterChange: { type: Boolean, default: false }
  },
  components: {
    BarChart,
    TreeMapChart,
    AnalyticsPagination
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      showTreeMap: true,
      showBarChart: false,
      machineIds: [],
      pageSize: 10,
      machinesTreeMapData: [],
      machinesBarChartData: {}
    };
  },
  computed: {
    ...mapGetters({
      machineFromPk: "dbCache/machineFromPk"
    }),
    filterHasChanged() {
      return this.filterChange;
    }
  },
  methods: {
    handleShowBarChart() {
      this.showTreeMap = false;
      this.showBarChart = true;
    },
    handleShowTreeMap() {
      this.showBarChart = false;
      this.showTreeMap = true;
    },
    updateBackgroundColors() {
      this.machinesBarChartData.datasets[0].backgroundColor = handleBackgroundColorsNoOthersPresent(
        this.machinesBarChartData.datasets[0].backgroundColor,
        this.interactiveFilters.machines
      );
      const treeMapColors = this.machinesTreeMapData.map((data) => data.color);
      const newTreeMapColors = handleBackgroundColorsNoOthersPresent(
        treeMapColors,
        this.interactiveFilters.machines
      );
      this.machinesTreeMapData = this.machinesTreeMapData.map((data, index) => ({
        ...data,
        color: newTreeMapColors[index]
      }));
    },
    async handleTabChange(pageSize) {
      this.pageSize = pageSize;
      await this.getTopMachinesData();
      if (this.interactiveFilters.machines.length > 0) {
        this.updateBackgroundColors();
      }
    },
    handleTreeMapClick(event) {
      const elementIndex = event.target._dataItem._index;
      this.setInteractiveFilter(elementIndex);
    },
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilter(elementIndex) {
      const machineId = this.machineIds[elementIndex];
      const foundMachineIndex = this.interactiveFilters.machines.findIndex(
        (machine) => machine.id === machineId
      );
      if (foundMachineIndex >= 0) {
        this.interactiveFilters.machines.splice(foundMachineIndex, 1);
      } else {
        this.interactiveFilters.machines.push({ id: machineId, index: elementIndex });
      }
      this.updateBackgroundColors();
    },
    async getTopMachinesData() {
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate,
        machine_ids: this.filters.machines.map((machine) => machine.pk)
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("help/tickets_by_machine/", { params });
        if (response && response.data) {
          this.machineIds = response.data.map((machine) => machine.key);
          const colors = response.data.map((machine) => hexToRGB(tealBlue, "0.9"));
          this.machinesTreeMapData = response.data.map((machine, index) => {
            const foundMachine = this.machineFromPk(parseInt(machine.key, 10));
            return {
              name: foundMachine ? foundMachine.name : `${$t("Unknown Machine")} ${machine.key}`,
              value: machine.doc_count,
              color: colors[index]
            };
          });
          this.machinesBarChartData = {
            labels: response.data.map((machine) => {
              const foundMachine = this.machineFromPk(parseInt(machine.key, 10));
              return foundMachine ? foundMachine.name : `${$t("Unknown Machine")} ${machine.key}`;
            }),
            datasets: getDatasets(response.data, colors)
          };
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    }
  }
};
</script>
<style lang="scss"></style>
