import axios from "axios";

export const irisIngestionApi = {
  createUpload: (data) => axios.post("/aa/uploads/", data).then((res) => res.data),
  getDocuments: (query) => axios.get("/aa/documents/", { params: query }).then((res) => res.data),
  completeUpload: (id) => axios.post(`/aa/documents/${id}/uploads/`).then((res) => res.data),
  updateDocuments: (data) => axios.post(`/aa/documents/`, data).then((res) => res.data),
  resumeUpdateDocument: (id) =>
    axios.post(`/aa/documents/${id}/updates/postponed/`).then((res) => res.data),
  generateFileTitle: (data) =>
    axios.post(`/aa/llm-queries/file_name/`, data).then((res) => res.data),
  getPresignedUrl: (id) => axios.get(`/aa/documents/${id}/view_link/`).then((res) => res.data),
  deleteDocument: (id) => axios.delete(`/aa/documents/${id}/`).then((res) => res.data)
};
