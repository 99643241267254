import axios from "axios";

export const settingsApi = {
  getTolerances: () =>
    axios
      .get("graphql/", {
        params: {
          query: `{
            settings{
              goodTolerance
              warningTolerance
              badTolerance,
              commentDisplayWindow
            }
          }`
        }
      })
      .then((res) => res.data),

  getTimezone: () =>
    axios
      .get("graphql/", {
        params: {
          query: `{
            settings{
              timezone
            }
          }`
        }
      })
      .then((res) => res.data),

  getDowntimeRevisionWindow: () =>
    axios
      .get("graphql/", {
        params: {
          query: `{
            settings{
              downtimeRevisionWindow
            }
          }`
        }
      })
      .then((res) => res.data)
};
