<template>
  <div>
    <help-center-search-input v-model="searchText" />

    <v-expansion-panels
      v-model="panel"
      class="mt-4"
      focusable
    >
      <v-expansion-panel
        v-if="results.machines.total"
        class="root mb-2"
        @change="loadMachines"
      >
        <v-expansion-panel-header class="px-6 py-5">
          <span class="text-subtitle-1"
            >{{ $t("Machines") }} ({{ results.machines.total }})</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-iterator
            class="comments-wrapper"
            :items="results.machines.results"
            :loading="results.machines.loading"
            :page.sync="results.machines.currentPage"
            :items-per-page.sync="results.machines.itemsPerPage"
            :server-items-length="results.machines.total"
            :no-data-text="$t('No Machines Found')"
            :hide-default-footer="true"
          >
            <template #default="props">
              <transition-group
                :class="'items'"
                name="slide-fade-list"
                mode="out-in"
                tag="div"
              >
                <!--  eslint-disable  vue/no-use-v-if-with-v-for -->
                <div
                  v-for="(item, index) in props.items"
                  v-if="index >= props.pagination.pageStart && index < props.pagination.pageStop"
                  :key="item.id"
                  class="mt-2"
                >
                  <!--  eslint-enable  vue/no-use-v-if-with-v-for -->
                  <router-link
                    class="item py-2 px-3 text-subtitle-2 color--text-secondary"
                    :to="{
                      name: 'machine',
                      params: { machine_id: item.global_id, machine_pk: item.id }
                    }"
                    @click="close"
                  >
                    {{ item.name }}
                  </router-link>
                </div>
              </transition-group>
            </template>
          </v-data-iterator>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="results.issues.total"
        class="root mb-2"
        @change="loadIssues"
      >
        <v-expansion-panel-header class="px-6 py-5">
          <span class="text-subtitle-1"
            >{{ $t("Issues") }} ({{ results.issues.total }})</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-iterator
            class="comments-wrapper"
            :items="results.issues.results"
            :loading="results.issues.loading"
            :page.sync="results.issues.currentPage"
            :items-per-page.sync="results.issues.itemsPerPage"
            :server-items-length="results.issues.total"
            :no-data-text="$t('No Issues Found')"
            :hide-default-footer="true"
          >
            <template #default="props">
              <transition-group
                :class="'items'"
                name="slide-fade-list"
                mode="out-in"
                tag="div"
              >
                <div
                  v-for="item in props.items"
                  :key="item.node.id"
                  class="mt-2"
                >
                  <router-link
                    class="item py-2 px-3 text-subtitle-2 color--text-secondary"
                    :to="{ name: 'ticket', params: { ticket_id: item.node.id } }"
                    @click="close"
                  >
                    {{ item.node.title }}
                  </router-link>
                </div>
              </transition-group>
            </template>
          </v-data-iterator>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="results.help.total"
        class="root mb-2"
        @change="loadHelp"
      >
        <v-expansion-panel-header class="px-6 py-5">
          <span class="text-subtitle-1"
            >{{ $t("P911") }} ({{ results.help.total }})</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-iterator
            class="comments-wrapper"
            :items="results.help.results"
            :loading="results.help.loading"
            :page.sync="results.help.currentPage"
            :items-per-page.sync="results.help.itemsPerPage"
            :server-items-length="results.help.total"
            :no-data-text="$t('No P911 Found')"
            :hide-default-footer="true"
          >
            <template #default="props">
              <transition-group
                :class="'items'"
                name="slide-fade-list"
                mode="out-in"
                tag="div"
              >
                <div
                  v-for="item in props.items"
                  :key="item.node.id"
                  class="mt-2"
                >
                  <router-link
                    class="item py-2 px-3 text-subtitle-2 color--text-secondary"
                    :to="{ name: 'help_ticket', params: { ticket_id: item.node.id } }"
                    @click="close"
                  >
                    <text-overflow :text="item.node.details" />
                  </router-link>
                </div>
              </transition-group>
            </template>
          </v-data-iterator>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="results.docs.total"
        class="root mb-2"
      >
        <v-expansion-panel-header class="px-6 py-5">
          <span class="text-subtitle-1"
            >{{ $t("Docs") }} ({{ results.docs.total }})</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-iterator
            class="comments-wrapper"
            :items="results.docs.results"
            :loading="results.docs.loading"
            :page.sync="results.docs.currentPage"
            :items-per-page.sync="results.docs.itemsPerPage"
            :server-items-length="results.docs.total"
            :no-data-text="$t('No Docs Found')"
            :hide-default-footer="true"
          >
            <template #default="props">
              <transition-group
                :class="'items'"
                name="slide-fade-list"
                mode="out-in"
                tag="div"
              >
                <help-center-card
                  v-for="doc in props.items"
                  :key="doc.id"
                  :description="doc.description"
                  :name="doc.name"
                  icon="mdi-file-document-multiple-outline"
                  @click="selectDoc(doc)"
                />
              </transition-group>
            </template>
          </v-data-iterator>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="results.videos.total"
        class="root mb-2"
      >
        <v-expansion-panel-header class="px-6 py-5">
          <span class="text-subtitle-1"
            >{{ $t("Videos") }} ({{ results.videos.total }})</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-iterator
            class="comments-wrapper"
            :items="results.videos.results"
            :loading="results.videos.loading"
            :page.sync="results.videos.currentPage"
            :items-per-page.sync="results.videos.itemsPerPage"
            :server-items-length="results.videos.total"
            :no-data-text="$t('No Videos Found')"
            :hide-default-footer="true"
          >
            <template #default="props">
              <transition-group
                :class="'items'"
                name="slide-fade-list"
                mode="out-in"
                tag="div"
              >
                <help-center-card
                  v-for="video in props.items"
                  :key="video.id"
                  :description="video.description"
                  :name="video.name"
                  icon="mdi-file-video-outline"
                  @click="selectDoc(video)"
                />
              </transition-group>
            </template>
          </v-data-iterator>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="showDialog"
      width="1000"
    >
      <media-viewer
        :media="selectedMedia"
        @close="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import MediaViewer from "@/components/MediaViewer";
import HelpCenterSearchInput from "./HelpCenterSearchInput.vue";
import HelpCenterCard from "./HelpCenterCard.vue";
import TextOverflow from "@/ui/TextOverlow/TextOverflow.vue";

export default {
  components: {
    TextOverflow,
    HelpCenterCard,
    HelpCenterSearchInput,
    MediaViewer
  },
  props: {},
  data() {
    return {
      searchText: "",
      searchDebouncer: false,
      selectedSection: false,
      panel: false,
      selectedMedia: false,
      showDialog: false,
      results: {
        machines: {
          itemsPerPage: 10,
          currentPage: 1,
          loading: false,
          results: [],
          total: 0
        },
        issues: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        },
        help: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        },
        docs: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        },
        videos: {
          load: null,
          itemsPerPage: 10,
          currentPage: 1,
          next: false,
          prev: false,
          loading: false,
          results: [],
          total: false
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      rightPanel: "components/RightPanel",
      user: "session/User"
    })
  },

  watch: {
    $route() {
      this.close();
    },
    searchText: function () {
      this.results.machines.results = [];
      this.results.machines.total = 0;
      this.results.issues.results = [];
      this.results.issues.total = 0;
      this.results.help.results = [];
      this.results.help.total = 0;
      this.results.docs.results = [];
      this.results.docs.total = 0;
      this.results.videos.results = [];
      this.results.videos.total = 0;

      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer);
      }
      if (this.searchText) {
        let load = this.loadCounts;
        this.searchDebouncer = setTimeout(load, 500);
      }
    }
  },

  methods: {
    moment,
    ...mapActions({
      setRightPanel: "components/SetRightPanel"
    }),
    close() {
      this.setRightPanel(false);
    },
    loadCounts() {
      this.loadMachines();
      this.loadIssues();
      this.loadHelp();
      this.loadDocs();
      this.loadVideos();
    },
    selectDoc(doc) {
      this.selectedMedia = doc;
      this.showDialog = true;
    },
    loadMachines() {
      let params = {
        searchText: this.searchText
      };
      this.results.machines.currentPage = 1;
      this.results.machines.loading = true;
      this.results.machines.results = this.results.machines.results.slice();
      this.$http.get("search/machines/", { params }).then((res) => {
        this.results.machines.results = res.data;
        this.results.machines.total = res.data.length;
      });
    },
    loadIssues() {
      this.results.issues.loading = true;
      const query = `query (
        $filters: GrapheneElasticFilterIssueSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        issues (
          simpleQueryString: $search,
          filter: $filters,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [automatic]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              id,
              title,
              reason,
              openedDate,
              ticketedDate,
              assignedToNames,
              issueStartDate,
              issueEndDate,
              machineId,
              machineName,
              issueTypeId,
              issueTypeName,
            }
          }
        }
      }`;
      const variables = {
        filters: {
          ticketed: { value: true }
        },
        before: false,
        after: false,
        search: this.searchText + "*"
      };
      if (this.results.issues.load) {
        Object.assign(variables, this.results.issues.load);
      } else {
        variables["first"] = this.results.issues.itemsPerPage;
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.results.issues.results = res.data.data.issues.edges;
          this.results.issues.total = res.data.data.issues.facets.automatic.doc_count;
          this.results.issues.next = res.data.data.issues.pageInfo.endCursor;
          this.results.issues.prev = res.data.data.issues.pageInfo.startCursor;
          this.results.issues.load = false;
          this.results.issues.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadHelp() {
      this.results.help.loading = true;
      const query = `query(
        $filters: GrapheneElasticFilterHelpTicketSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String
        ){
        helpTickets(
          simpleQueryString: $search,
          filter: $filters,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [title]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            node{
              id
              ticketedBy,
              ticketedByName,
              ticketedDate,
              details,
              tagSearch,
              title
            }
          }
        }
      }`;
      const variables = {
        filters: {},
        before: false,
        after: false,
        search: this.searchText + "*"
      };
      if (this.results.issues.load) {
        Object.assign(variables, this.results.issues.load);
      } else {
        variables["first"] = this.results.issues.itemsPerPage;
      }
      this.$http
        .get("graphql/", { params: { query: query, variables: variables } })
        .then((res) => {
          this.results.help.results = res.data.data.helpTickets.edges;
          this.results.help.total = res.data.data.helpTickets.facets.title.doc_count;
          this.results.help.next = res.data.data.helpTickets.pageInfo.endCursor;
          this.results.help.prev = res.data.data.helpTickets.pageInfo.startCursor;
          this.results.help.load = false;
          this.results.help.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadDocs() {
      const query = `query($search: String){
        helpDocs(search: $search){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;
      const variables = {};
      if (this.searchText) {
        variables["search"] = this.searchText;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.results.docs.results = res.data.data.helpDocs;
          this.results.docs.total = res.data.data.helpDocs.length;
          this.results.docs.load = false;
          this.results.docs.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    loadVideos() {
      const query = `query($search: String){
        helpDocs(search: $search, video: true){
          id,
          name,
          description,
          thumbnailUrl,
          latestVersion{
            version,
            fileUrl,
            revisionDate,
            revisionComment,
            pdf,
            video,
            image,
            download
          }
        }
      }`;
      const variables = {};
      if (this.searchText) {
        variables["search"] = this.searchText;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.results.videos.results = res.data.data.helpDocs;
          this.results.videos.total = res.data.data.helpDocs.length;
          this.results.videos.load = false;
          this.results.videos.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables";

.root {
  border: 1px solid var(--border-secondary);
  background: transparent !important;
  border-radius: 4px;

  &:before,
  &:after {
    display: none !important;
  }
}

.item {
  background: var(--bg-card-white);
  border-radius: 4px;
  display: block;
  text-decoration: none;

  &:hover {
    background: color-mix(in srgb, var(--bg-card-white), #fff 15%);
  }
}
</style>
