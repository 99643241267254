<template>
  <div class="close-button-container">
    <v-spacer class="close-spacer"></v-spacer>
    <v-btn
      class="close-button"
      :icon="true"
      @click="onClick"
      fab
      small
    >
      <v-icon>{{ "mdi-close-thick" }}</v-icon>
    </v-btn>
    <v-spacer class="close-spacer"></v-spacer>
  </div>
</template>
<script>
export default {
  name: "DialogCloseButton",
  props: {
    onClick: { type: Function, default: () => null }
  }
};
</script>
<style lang="scss">
.close-button-container {
  .close-spacer {
    height: 0.5rem;
  }
  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
