<template>
  <div class="v-range-picker-custom">
    <v-text-field
      @click.stop.prevent="
        () => {
          appendIcon ? null : setVisible(id);
        }
      "
      v-bind="textFieldProps"
      :disabled="disabled"
      :loading="loading"
      :label="label"
      :value="formattedDatetime"
      :clearable="clearable"
      :append-icon="appendIcon"
      :hint="hint"
      :rules="rules"
      @click:append="openCalendar"
      @click:clear="clearDate"
      @focus="
        () => {
          focus();
        }
      "
      @focusout="
        () => {
          focusout();
        }
      "
      hide-details
      readonly
    />
    <v-dialog
      v-model="visibleComponent.visible == id && isDialog"
      @close="close"
      @click:outside="close"
      content-class="v-time-picker-custom modal_time_picker"
    >
      <div
        class="overlay"
        @click="close()"
        v-if="visibleComponent.visible == id && isDialog"
      ></div>
      <transition name="slide-fade">
        <div
          class="picker modal-picker"
          :class="position"
          v-if="visibleComponent.visible == id"
        >
          <v-tabs
            fixed-tabs
            v-model="activeTab"
          >
            <DialogCloseButton :onClick="close" />
            <v-tab-item key="calendar">
              <v-date-picker
                range
                v-model="dates"
                v-bind="datePickerProps"
                @input="dateInput"
                full-width
                :max="max ? max.toISOString() : null"
                :min="min ? min.toISOString() : null"
                :locale="$i18n.locale()"
              />
            </v-tab-item>
          </v-tabs>
          <v-card-actions>
            <v-spacer></v-spacer>
            <slot
              name="actions"
              :parent="this"
            >
              <v-btn
                color="green darken-1"
                text
                @click="okHandler"
                >{{ $t(okText) }}</v-btn
              >
            </slot>
          </v-card-actions>
        </div>
      </transition>
    </v-dialog>
    <div
      class="overlay"
      @click="close()"
      v-if="visibleComponent.visible == id && !isDialog"
    ></div>
    <transition name="slide-fade">
      <div
        class="picker"
        :class="position"
        v-if="visibleComponent.visible == id && !isDialog"
      >
        <v-tabs
          fixed-tabs
          v-model="activeTab"
        >
          <DialogCloseButton :onClick="close" />
          <v-tab-item key="calendar">
            <v-date-picker
              range
              v-model="dates"
              v-bind="datePickerProps"
              @input="dateInput"
              full-width
              :max="max ? max.toISOString() : null"
              :min="min ? min.toISOString() : null"
              :locale="$i18n.locale()"
            />
          </v-tab-item>
        </v-tabs>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot
            name="actions"
            :parent="this"
          >
            <v-btn
              color="green darken-1"
              text
              @click="okHandler"
              >{{ $t(okText) }}</v-btn
            >
          </slot>
        </v-card-actions>
      </div>
    </transition>
  </div>
  <!-- </v-dialog> -->
</template>
<style lang="scss">
@import "../../scss/variables";
.v-range-picker-custom {
  position: relative;

  &.modal_time_picker {
    height: 50%;
    width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }

  .overlay {
    pointer-events: all;
  }
  .v-picker__title {
    height: 84px;
    padding-top: 10px;
  }
  .theme--light.v-input {
    input {
      cursor: pointer;
      color: rgba(0, 0, 0, 0.6);
      border-color: rgba(0, 0, 0, 0.6);
    }
  }
  .theme--light.v-text-field:not(.v-input--has-state):hover
    > .v-input__control
    > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    border-color: $white;
  }
  .theme--light.v-label {
    color: $white;
  }
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: $white;
  }
  .theme--light.v-tabs > .v-tabs-bar,
  .theme--light.v-tabs-items,
  .theme--light.v-card,
  .theme--light.v-picker__body {
    background-color: transparent;
  }
  .v-picker__title {
    background-color: $blue;
    border-radius: 0;
  }
  .v-btn.v-btn--active::before,
  .v-time-picker-clock__item--active::before {
    background-color: $blue;
    opacity: 1;
  }
  .v-time-picker-clock__hand {
    background-color: $blue !important;
    border-color: $blue !important;
  }
  .v-text-field {
    .v-label--active {
      transform: translateY(-23px) translateX(-8px) scale(0.75);
    }
  }

  .dialogPicker {
    background: $darkGrey;
  }

  .picker {
    box-shadow:
      inset 1px -1px 2px rgba(0, 0, 0, 0.2),
      inset -1px 1px 2px rgba(255, 255, 255, 0.2),
      -2px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    position: absolute;
    top: 3.5rem;
    background-color: $darkGrey;
    width: 300px;
    z-index: 999;

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $darkGrey;
      left: 20px;
      top: -6px;
      transition: all 0.2s ease-out;
    }

    &.modal-picker:after {
      border: none !important;
    }

    &.right {
      left: unset;
      right: 0;
      &:after {
        left: unset;
        right: 20px;
      }
    }
    &.top {
      bottom: 5rem;
      top: unset;
      &:after {
        bottom: -10px;
        top: unset;
        transform: rotate(180deg);
      }
    }
    .theme--light.v-btn {
      color: white;
    }
    .v-tabs > .v-tabs-bar {
      color: white;
      border-radius: 1rem 1rem 0 0;
      overflow: hidden;
      .v-tab:not(.v-tab--active) {
        color: rgba(255, 255, 255, 0.5);
      }
      .v-tab--active {
        color: rgba(255, 255, 255, 1);
      }
    }
    .v-picker,
    .v-date-picker-title {
      border-radius: 0;
    }
    .v-date-picker-header__value:not(:hover):not(:focus),
    .v-date-picker-header__value {
      color: white;
      button:not(:hover):not(:focus),
      button {
        color: white;
      }
    }
    .theme--light.v-date-picker-table {
      color: white;
      th,
      .v-btn {
        color: white;
      }
    }
  }
}
</style>
<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import Vue from "vue";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";

export default {
  name: "v-daterange-picker",
  model: {
    prop: "datesIn",
    event: "input"
  },
  props: {
    max: Date,
    min: Date,
    isDialog: Boolean,
    clearable: Boolean,
    appendIcon: String,
    selectCallback: Function,
    hint: String,
    rules: String,
    datesIn: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    id: {
      type: String,
      default: ""
    },
    position: {
      type: String,
      default: "left"
    },
    range: {
      type: Boolean,
      default: false
    }
  },
  components: { DialogCloseButton },
  data() {
    return {
      display: false,
      activeTab: 0,
      dates: []
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      visibleComponent: "components/Visible"
    }),
    formattedDatetime() {
      if (this.selectedDatetime != null && this.selectedDatetime.length == 2) {
        return (
          moment(this.selectedDatetime[0]).format(this.dateFormat) +
          " - " +
          moment(this.selectedDatetime[1]).format(this.dateFormat)
        );
      } else {
        return "";
      }
    },
    selectedDatetime() {
      if (this.dates) {
        let datesString = this.dates;
        return [moment(datesString[0]).toDate(), moment(datesString[1]).toDate()];
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    }
  },
  methods: {
    ...mapActions({
      setVisible: "components/SetVisible"
    }),
    init() {
      this.dates = this.datesIn;
    },
    clearDate() {
      this.dates = null;
    },
    focus() {
      this.$emit("focus");
    },
    focusout() {
      this.$emit("focusout");
    },
    close() {
      this.setVisible(false);
    },
    openCalendar() {
      this.setVisible(this.id);
    },
    okHandler() {
      this.resetPicker();
      this.setVisible(false);
      this.$emit("okClick", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    dateInput(value) {
      console.log("date selected", value);
      this.$emit("input", value);
    },
    okClick() {
      this.$emit("okClick");
    }
  },
  watch: {
    datesIn: function () {
      this.init();
    }
  }
};
</script>
