<template>
  <v-col>
    <v-row class="d-flex align-center">
      <v-col width="auto">
        <h3>{{ $t("Front") }}</h3>
        <svg viewBox="0 0 100 100">
          <image
            xlink:href="../../../assets/img/stickFigure.svg"
            height="100%"
            width="100%"
          />
          <g v-if="type === 'event' || (safetyTickets && safetyTickets.length)">
            <InjuryPoint
              :cx="50"
              :cy="3.5"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="headFront"
            />
            <InjuryPoint
              :cx="45"
              :cy="10"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightEye"
            />
            <InjuryPoint
              :cx="55"
              :cy="10"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftEye"
            />
            <InjuryPoint
              :cx="50"
              :cy="20"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="neckFront"
            />
            <InjuryPoint
              :cx="37"
              :cy="24"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightShoulder"
            />
            <InjuryPoint
              :cx="32"
              :cy="45"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightArm"
            />
            <InjuryPoint
              :cx="32"
              :cy="60"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightHand"
            />
            <InjuryPoint
              :cx="63"
              :cy="24"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftShoulder"
            />
            <InjuryPoint
              :cx="68"
              :cy="45"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftArm"
            />
            <InjuryPoint
              :cx="68"
              :cy="60"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftHand"
            />
            <InjuryPoint
              :cx="50"
              :cy="45"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="torso"
            />
            <InjuryPoint
              :cx="43.5"
              :cy="62"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightHip"
            />
            <InjuryPoint
              :cx="42.5"
              :cy="80"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightLeg"
            />
            <InjuryPoint
              :cx="41.5"
              :cy="95"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightFoot"
            />
            <InjuryPoint
              :cx="56.5"
              :cy="62"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftHip"
            />
            <InjuryPoint
              :cx="57.5"
              :cy="80"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftLeg"
            />
            <InjuryPoint
              :cx="58.5"
              :cy="95"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftFoot"
            />
          </g>
        </svg>
      </v-col>
      <v-col width="auto">
        <h3>{{ $t("Back") }}</h3>
        <svg viewBox="0 0 100 100">
          <image
            xlink:href="../../../assets/img/stickFigure.svg"
            height="100%"
            width="100%"
          />
          <g v-if="type === 'event' || (safetyTickets && safetyTickets.length)">
            <InjuryPoint
              :cx="50"
              :cy="8"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="headBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="20"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="neckBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="30"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="upperBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="40"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="midBack"
            />
            <InjuryPoint
              :cx="50"
              :cy="50"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="lowerBack"
            />
            <InjuryPoint
              :cx="43.5"
              :cy="72"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftUpperLeg"
            />
            <InjuryPoint
              :cx="42.5"
              :cy="86"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftLowerLeg"
            />
            <InjuryPoint
              :cx="41.5"
              :cy="97"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="leftHeel"
            />
            <InjuryPoint
              :cx="56.5"
              :cy="72"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightUpperLeg"
            />
            <InjuryPoint
              :cx="57.5"
              :cy="86"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightLowerLeg"
            />
            <InjuryPoint
              :cx="58.5"
              :cy="97"
              :type="type"
              :affectedAreas="affectedAreas"
              :safetyTickets="safetyTickets"
              pointName="rightHeel"
            />
          </g>
        </svg>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import InjuryPoint from "./InjuryPoint.vue";

export default {
  props: ["type", "affectedAreas", "safetyTickets"],
  components: { InjuryPoint }
};
</script>

<style scoped lang="scss">
@import "../../../scss/variables";

.row {
  .col {
    padding: 1rem 0;
  }
  h3 {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
