var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realtime_table"},[_c('v-btn',{staticClass:"hideDesktop",attrs:{"fab":"","x-small":"","light":""},on:{"click":function($event){_vm.showSearch = !_vm.showSearch}}},[(!_vm.showSearch)?_c('i',{staticClass:"fa fa-filter"}):_vm._e(),_vm._v(" "),(_vm.showSearch)?_c('i',{staticClass:"fa fa-times"}):_vm._e()]),_vm._v(" "),(_vm.vw > 1200 || _vm.showSearch)?_c('div',{staticClass:"searchbars"},[_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Zone'),"items":Array.from(_vm.zoneSet),"value":_vm.filters.zone,"menu-props":{
        closeOnClick: true,
        closeOnContentClick: true
      }},on:{"change":function (e) {
          _vm.setFilter('zone', e);
        }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
          _vm.setFilter('zone', null);
        }}},[(_vm.filters.zone != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Machine'),"items":Array.from(_vm.machineSet),"value":_vm.filters.machine,"menu-props":{
        closeOnClick: true,
        closeOnContentClick: true
      }},on:{"change":function (e) {
          _vm.setFilter('machine', e);
        }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
          _vm.setFilter('machine', null);
        }}},[(_vm.filters.machine != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Part'),"items":Array.from(_vm.partSet),"value":_vm.filters.part,"menu-props":{
        closeOnClick: true,
        closeOnContentClick: true
      }},on:{"change":function (e) {
          _vm.setFilter('part', e);
        }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
          _vm.setFilter('part', null);
        }}},[(_vm.filters.part != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Operator'),"items":Array.from(_vm.operatorSet),"value":_vm.filters.name,"menu-props":{
        closeOnClick: true,
        closeOnContentClick: true
      }},on:{"change":function (e) {
          _vm.setFilter('name', e);
        }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
          _vm.setFilter('name', null);
        }}},[(_vm.filters.name != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()]),_vm._v(" "),_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('Operator Status'),"items":Array.from(_vm.operatorStatusSet),"value":_vm.filters.checked_in == null ? null : _vm.filters.checked_in ? 'Checked-In' : 'Checked-Out',"menu-props":{
        closeOnClick: true,
        closeOnContentClick: true
      }},on:{"change":function (e) {
          _vm.setFilter('checked_in', e == null ? null : e == 'Checked-In');
        }}}),_vm._v(" "),_c('div',{staticClass:"clearbutton",on:{"click":function () {
          _vm.setFilter('checked_in', null);
        }}},[(_vm.filters.checked_in != null)?_c('i',{staticClass:"fa fa-remove"}):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"middle_row"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"shift_performance blue-title"},[(_vm.vw > 1000)?_c('h3',[_vm._v(_vm._s(_vm.$t("Shift Performance")))]):_vm._e(),_vm._v(" "),_c('h4',[_vm._v("\n          "+_vm._s(_vm.fromDate && _vm.fromDate.format("MMMM DD, YYYY hh:mm A"))+" -\n          "+_vm._s(_vm.toDate && _vm.toDate.format("MMMM DD, YYYY hh:mm A"))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"box totals"},[_c('v-card',{staticClass:"card checked_in"},[_c('div',{staticClass:"checked_in_box"},[_c('div',{staticClass:"blue-title"},[_vm._v(_vm._s(_vm.$t("Total Checked-In")))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.numCheckedIn))])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"checked_in_box"},[_c('div',{staticClass:"blue-title"},[_vm._v(_vm._s(_vm.$t("Total Checked-Out")))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.numCheckedOut))])])])])],1),_vm._v(" "),_c('div',{staticClass:"box intervalSelection"},[_c('v-tabs',{attrs:{"fixed-tabs":"","value":_vm.calendarCase},on:{"change":_vm.ChangeCalendar}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t("Shift")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Day")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Week")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Month")))]),_vm._v(" "),_c('v-tab',[_vm._v(_vm._s(_vm.$t("Year")))])],1)],1)]),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.computed_table_headers,"table_headers":_vm.header_options,"items":_vm.filtered_items,"expanded":_vm.expanded,"single-expand":true,"item-key":"key","hide-default-header":_vm.vw > 1000,"loading":_vm.loading},on:{"click:row":_vm.rowClick,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.vw > 1000)?{key:"header",fn:function(ref){
        var props = ref.props;
return [_c('thead',[_c('tr',_vm._l((_vm.computed_table_headers),function(head){return _c('th',[_c('div',{staticClass:"header_item",class:head.class,on:{"click":function () {
                  _vm.sortby(head);
                }}},[_c('div',{staticClass:"rowcontent"},[_vm._v(_vm._s(head.text))]),_vm._v(" "),(head.sort == 'asc')?_c('i',{staticClass:"mdi mdi-arrow-up"}):(head.sort == 'desc')?_c('i',{staticClass:"mdi mdi-arrow-down"}):_c('i')])])}),0)])]}}:null,{key:"item.expand",fn:function(ref){
                var item = ref.item;
return [(item.machines && item.machines.length > 1 && !_vm.expanded.includes(item))?_c('v-btn',{attrs:{"x-small":"","fab":"","light":""}},[_c('i',{staticClass:"fa fa-caret-down",attrs:{"aria-hidden":"true"}})]):_vm._e(),_vm._v(" "),(item.machines && item.machines.length > 1 && _vm.expanded.includes(item))?_c('v-btn',{attrs:{"x-small":"","fab":"","light":""}},[_c('i',{staticClass:"fa fa-caret-up",attrs:{"aria-hidden":"true"}})]):_vm._e()]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"table_name"},[_c('div',{staticClass:"icon",style:(item.avatar
              ? {
                  background: ("url(" + (item.avatar) + ")"),
                  'background-size': 'cover',
                  'background-position-x': 'center',
                  'background-position-y': 'center'
                }
              : null)},[(item.avatar == null)?_c('i',{staticClass:"fa fa-user"}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"table_status"},[_c('div',{staticClass:"tiny-light",class:item.checked_in ? 'green' : 'red'}),_vm._v(" "),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.checked_in ? _vm.$t("Checked-In") : _vm.$t("Checked-Out")))])])]}},{key:"item.machine_status",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"table_machine_status"},[_c('p',{style:({ color: item.machine_status ? item.machine_status.color : '' })},[_vm._v("\n          "+_vm._s(item.machine_status ? item.machine_status.name : "")+"\n        ")])])]}},{key:"item.target_units",fn:function(ref){
              var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.numberWithCommas(item.target_units.toFixed()))+"\n    ")]}},{key:"item.actual",fn:function(ref){
              var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.numberWithCommas(item.actual.toFixed()))+"\n    ")]}},{key:"item.average",fn:function(ref){
              var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.numberWithCommas(item.average))+"\n    ")]}},{key:"item.gap",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"table_gap",class:_vm.GetGapColor(item.gap, item.target_units, item.actual)},[_vm._v("\n        "+_vm._s(_vm.numberWithCommas(item.gap.toFixed()))+"\n      ")])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"nestedTable"},[_c('realtime-table-expand',{attrs:{"checkedIn":item.checked_in,"machines":item.machines,"table_headers":_vm.table_headers,"now":_vm.now,"role":item.role,"badges":item}})],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }