<template>
  <div class="card cost-by-type">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Cost By Type") }}</span>
    </div>
    <div class="loading-bar">
      <v-progress-linear
        indeterminate
        v-if="loading"
      />
    </div>
    <div class="chart-container">
      <TreeMapChart
        ref="treeMap"
        class="tree-map"
        :data="treeMapData"
        :id="'cost-by-type'"
        :labelFormat="treeMapLabel"
        :handleClick="handleTreeMapClick"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import TreeMapChart from "@/components/charts/TreeMapChart";
import { tiffanyBlue } from "@/scss/_variables.scss";
import { hexToRGB } from "@/utils/colors";

export default {
  name: "ScrapAnalyticsCostByType",
  props: {
    filters: { type: Object, default: () => {} }
  },
  components: {
    TreeMapChart
  },
  data() {
    return {
      loading: false,
      treeMapData: []
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    }),
    treeMapLabel() {
      return `{name}; {value.formatNumber('$#,###.00')}`;
    }
  },
  methods: {
    async handleTreeMapClick(event) {
      const dataItem = event.target.dataItem;
      const dataContext = dataItem.dataContext.dataContext;
      if (
        ("children" in dataContext && dataContext.children.length > 0) ||
        dataItem.dataContext.level > 1
      ) {
        return;
      }
      this.loading = true;
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate
      };
      if ("machines" in this.filters && this.filters.machines.length > 0) {
        params.machine_ids = this.filters.machines;
      }
      if ("partNumbers" in this.filters && this.filters.partNumbers.length > 0) {
        params.part_numbers = this.filters.partNumbers;
      }
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_code/", { params });
        if (response && response.data) {
          const children = [];
          const data = response.data.map((responseItem) => ({
            ...responseItem,
            scrap_type_name:
              "scrap_type_name" in responseItem ? responseItem.scrap_type_name : "Process"
          }));
          data.forEach((codeItem) => {
            if (dataContext.name === "Process" && codeItem.scrap_type === "non_process") {
              return;
            }
            if (dataContext.name !== codeItem.scrap_type_name) {
              return;
            }
            children.push({
              name: `${dataContext.name === "Process" ? codeItem.code : codeItem.child_component_name}; ${codeItem.machine}`,
              value: codeItem.total_cost,
              color: hexToRGB(tiffanyBlue, "0.9")
            });
          });
          dataContext.children = children;
          this.$refs.treeMap.chart.invalidateData();
          this.$refs.treeMap.chart.events.once("datavalidated", () => {
            const updatedDataItem = this.findDataItem(
              dataContext,
              this.$refs.treeMap.chart.dataItems
            );
            if (updatedDataItem) {
              this.$refs.treeMap.getLevels(this.$refs.treeMap.chart.data);
              this.$refs.treeMap.chart.zoomToChartDataItem(updatedDataItem);
            }
          });
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    findDataItem(dataContext, dataItems) {
      for (const [i, item] of Array.prototype.entries.call(dataItems)) {
        const dataItem = dataItems.getIndex(i);
        if (dataItem.dataContext === dataContext) {
          return dataItem;
        }
        if (dataItem.children) {
          const child = this.findDataItem(dataContext, dataItem.children);
          if (child) {
            return child;
          }
        }
      }
      return;
    },
    async getCostByType() {
      this.loading = true;
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate
      };
      if ("machines" in this.filters && this.filters.machines.length > 0) {
        params.machine_ids = this.filters.machines;
      }
      if ("partNumbers" in this.filters && this.filters.partNumbers.length > 0) {
        params.part_numbers = this.filters.partNumbers;
      }
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_type/", { params });
        if (response && response.data) {
          this.treeMapData = Object.keys(response.data).map((key) => {
            const machineList = [];
            response.data[key].machines.forEach((machine) => {
              if (Array.isArray(machine)) {
                machine.forEach((item) => machineList.push(item));
              } else {
                machineList.push(machine);
              }
            });
            return {
              name: key,
              value: response.data[key].cost,
              color: hexToRGB(tiffanyBlue, "0.9")
            };
          });
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalyticsCostByType.scss";
</style>
