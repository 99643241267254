// dummy $t function, Vue does not initialize translations on this step
const $t = (template) => template;

export const MAX_FILES_SIZE_GB = 5;
export const FILES_TYPES = ".pdf,.csv,.xlsx,.xls";
export const OTHER_MACHINE = {
  id: "other",
  name: $t("Other")
};

//hardcoded
export const KNOWLEDGE_DOMAINS = [
  { id: 1, name: $t("Manufacturing"), apiName: "manufacturing" },
  { id: 2, name: $t("Finance"), apiName: "finance" }
];

export const IRIS_UPLOAD_INJECT_FILES = "files";
export const IRIS_UPLOAD_INJECT_FILES_ERROR = "filesError";
export const IRIS_UPLOAD_INJECT_VALIDATE_FILES = "validateFiles";
export const UPPY = "uppy";
export const APPLY_UPPY_MUTATION = "applyUppyMutation";
export const UPLOAD_STATUSES = "uploadStatuses";
export const POSSIBLE_UPLOAD_STATUSES = {
  //uppy statuses
  initiated: "Initiated",
  uploadProgress: "Uploading",
  uploadSuccess: "Uploaded",
  uploadFailed: "Failed",
  //api statuses
  created: "Created",
  processing: "Processing",
  success: "Success",
  indexing: "Indexing",
  textract_reading: "Textract Reading",
  textract_in_progress: "Textract in Progress",
  in_process: "In Process",
  uploaded_to_s3: "Uploaded to S3",
  extracting_text: "Extracting Text",
  waiting_for_processing: "Waiting for Processing",
  failed: "Failed",
  aborted: "Aborted",
  finished: "Finished",
  textract_failed: "Textract Failed",
  textract_completed: "Textract Completed",
  deleted: "Removed from LLM access",
  deletion_failed: "Removal Failed",
  updates_postponed: "Updates Postponed"
};

export const FINALIZED_UPLOAD_STATUSES = [
  "initiated",
  "uploadProgress",
  "uploadSuccess",
  "uploadFailed",
  "success",
  "failed",
  "aborted",
  "finished",
  "textract_failed",
  "deleted",
  "deletion_failed",
  "updates_postponed"
];

export const IRIS_STATUSES_AVAILABLE_FOR_DELETION = [
  POSSIBLE_UPLOAD_STATUSES.success,
  POSSIBLE_UPLOAD_STATUSES.failed,
  POSSIBLE_UPLOAD_STATUSES.finished
];

export const IRIS_UPPY_UPLOAD_STATUSES = [
  POSSIBLE_UPLOAD_STATUSES.initiated,
  POSSIBLE_UPLOAD_STATUSES.uploadProgress,
  POSSIBLE_UPLOAD_STATUSES.uploadSuccess,
  POSSIBLE_UPLOAD_STATUSES.uploadFailed
];

export const IRIS_UPLOAD_INJECT_ADD_FILES = "addFilesToUpload";
export const IRIS_UPLOAD_INJECT_UPLOAD = "upload";
export const IRIS_UPLOAD_INJECT_UPLOAD_DISABLED = "uploadDisabled";
export const IRIS_UPLOAD_TEXT_MIN_CHARACTERS = 50;
export const IRIS_UPLOAD_TEXT_MAX_CHARACTERS = 500;
export const IRIS_UPLOAD_UPDATE_STATUS_INTERVAL = 30000;

export const IRIS_UPLOAD_FILE_SOURCE_TYPE = {
  document: "Uploaded",
  typed: "Typed"
};
