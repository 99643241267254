/**
 * Manage user session data
 * @author Nicholas Hydock
 */
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

import router from "@/router";

Vue.use(Vuex);

class User {
  constructor() {
    this.sharedDevice = true;
    this.avatar = "";
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phoneNumber = "";
    this.id = "ABC123";
    this.pk = -1;
    this.username = "";
    this.theme = 0;
    this.themeName = "dark";
    this.permissions = [];
    this.value_stream = "";
    this.role = "";
    this.roles = [];
    this.tier = -1;
    this.title = "";
    this.language = 0;
    this.languageName = "en";
    this.emailNotifications = true;
    this.smsNotifications = true;
    this.hasPassword = true;
    this.machines = [];
    this.location = null;
    this.locationName = null;
    this.locationBanner = null;
    this.locationLookup = null;
    this.useDevelopmentEdge = false;
  }

  get fullname() {
    return this.firstName + " " + this.lastName;
  }
}

function isValidJWT(token) {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    JSON.parse(window.atob(base64));
    return true;
  } catch (e) {
    return false;
  }
}

function parseJwt(token) {
  // attempt to parse the token.  If it can't be parsed properly, ignore it
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  } catch (e) {
    return {
      exp: 0
    };
  }
}

const state = {
  jwt: localStorage.getItem("jwt"),
  expire: localStorage.getItem("jwt") ? parseJwt(localStorage.getItem("jwt")).exp : 0,
  user: Object.assign(new User(), JSON.parse(localStorage.getItem("profile"))),
  fullscreen: false
};

const getters = {
  "session/IsAuthenticated"(state) {
    return !!state.jwt;
  },
  "session/AuthExpiration"(state) {
    return state.expire;
  },
  "session/User"(state) {
    return state.user;
  },
  "session/Fullscreen"(state) {
    return state.fullscreen;
  },
  "session/Location"(state) {
    return {
      id: state.user.location,
      name: state.user.locationName,
      lookup: state.user.locationLookup,
      banner: state.user.locationBanner
    };
  },
  "session/SharedDevice"(state) {
    return state.sharedDevice;
  }
};

const mutations = {
  /**
   * Stores the jwt
   * @param state
   * @param jwt
   * @constructor
   */
  "session/JWT"(state, jwt) {
    if (!isValidJWT(jwt)) {
      jwt = null;
    }

    state.jwt = jwt;
    if (jwt == null) {
      localStorage.removeItem("jwt");
      state.expire = 0;
      delete axios.defaults.headers["Authorization"];
      if (router.currentRoute.name != "login") {
        router.push("/login");
      }
    } else {
      localStorage.setItem("jwt", jwt);
      let parsed = parseJwt(jwt);
      state.user = Object.assign(new User(), parsed);
      localStorage.setItem("profile", JSON.stringify(state.user));
      state.expire = parsed.exp;
    }
    if (jwt) {
      axios.defaults.headers["Authorization"] = "JWT " + localStorage.getItem("jwt");
    } else {
      delete axios.defaults.headers["Authorization"];
    }
  },
  "session/PROFILE"(state, user) {
    state.user = Object.assign(new User(), user);
    localStorage.setItem("profile", JSON.stringify(user));
  },
  "session/UPDATE_PROFILE"(state, user) {
    Object.assign(state.user, user);
    localStorage.setItem("profile", JSON.stringify(state.user));
  },
  "session/SET_LOCATION"(state, location) {
    console.log(location, state);
    Object.assign(state.user, location);
    localStorage.setItem("profile", JSON.stringify(state.user));
  },
  "session/TOGGLE_FULL"(state, fullscreen) {
    state.fullscreen = fullscreen;
  },
  "session/SET_SHARED_DEVICE"(state, sharedDevice) {
    state.sharedDevice = sharedDevice;
  }
};

const actions = {
  /**
   * Log in action the user and store their jwt globally
   */
  "session/Login"({ commit }, { username, password }) {
    return axios
      .post("login/", {
        username,
        password
      })
      .then((response) => {
        const body = response.data;
        commit("session/JWT", body.token);
      });
  },
  /**
   * Log out action the user and removes their JWT
   */
  "session/Logout"({ commit }) {
    commit("session/JWT", null);
  },
  /**
   * Action for refreshing the session store's jwt
   */
  "session/Refresh"({ commit, state }) {
    if (state.jwt) {
      axios
        .post("refresh_token/", {
          token: state.jwt
        })
        .then((response) => {
          let jwt = response.data.token;
          commit("session/JWT", jwt);
        });
    }
  },
  /**
   * Updates the user's profile
   */
  "session/UpdateProfile"({ commit }, profile) {
    commit("session/UPDATE_PROFILE", profile);
  },
  "session/SetLocation"({ commit }, location) {
    commit("session/SET_LOCATION", location);
  },
  "session/SetJWT"({ commit }, jwt) {
    commit("session/JWT", jwt);
  },
  "session/SetFullscreen"({ commit }, fullscreen) {
    commit("session/TOGGLE_FULL", fullscreen);
  },
  "session/SetSharedDevice"({ commit }, sharedDevice) {
    commit("session/SET_SHARED_DEVICE", sharedDevice);
  }
};

export const isAuthenticated = function () {
  if (localStorage.getItem("jwt") == null) {
    this.$router.push("/login");
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
