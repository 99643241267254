<template>
  <div class="card group-level-one">
    <p class="title">{{ $t("Tickets By") }} {{ levelP1 ? levelP1.name : "" }}</p>
    <div class="performance-summary-card-header">
      <AnalyticsPagination :handleTabChange="(pageSize) => handleTabChange(pageSize)" />
    </div>
    <BarChart
      class="bar-chart"
      :chartData="groupLevelOneChart"
      :axisType="AXISTYPE.SHORTEN"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import { coralReef, tiffanyBlue } from "@/scss/_variables.scss";

export default {
  name: "P911GroupLevelOneChart",
  props: {
    levelP1: { type: Object, default: () => {} },
    filters: { type: Object, default: () => {} }
  },
  components: {
    BarChart,
    AnalyticsPagination
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      pageSize: 10,
      groupLevelOneChart: { datasets: [{ data: [] }], labels: [] },
      data: null
    };
  },
  computed: {
    ...mapGetters({
      machineGroupFromId: "dbCache/machineGroupFromId"
    })
  },
  methods: {
    handleTabChange(pageSize) {
      this.pageSize = pageSize;
      this.getP911ByGroupLevelP1();
    },
    async getP911ByGroupLevelP1() {
      if (!this.levelP1) {
        return;
      }
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        group_level: this.levelP1.level,
        machine_ids: this.filters.machines.map((machine) => machine.pk)
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("help/open_closed_by_group/", { params });
        if (response) {
          this.data = Object.keys(response.data)
            .map((key) => ({ key, ...response.data[key] }))
            .sort((a, b) => b.open.doc_count - a.open.doc_count);
          if (this.pageSize) {
            this.data = this.data.slice(0, this.pageSize);
          }
          this.formatData();
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    },
    formatData() {
      const labels = this.data.map((item) => {
        const foundGroup = this.machineGroupFromId(parseInt(item.key, 10));
        return foundGroup && foundGroup.name ? foundGroup.name : "";
      });
      this.groupLevelOneChart = {
        datasets: [
          {
            data: this.data.map((item) => item.closed.doc_count),
            label: this.$t("Closed"),
            backgroundColor: this.data.map(() => tiffanyBlue)
          },
          {
            data: this.data.map((item) => item.open.doc_count),
            label: this.$t("Open"),
            backgroundColor: this.data.map(() => coralReef)
          }
        ],
        labels
      };
    }
  }
};
</script>
