<template>
  <div class="card cost-by-machine">
    <div class="chart-title-icons">
      <span class="header-text">{{ $t("Cost By Machine") }}</span>
      <div class="chart-icons">
        <v-icon
          class="table-icon"
          :color="showTable ? 'primary' : null"
          @click="handleShowTable"
        >
          mdi-table
        </v-icon>
        <v-icon
          class="bar-chart-icon"
          :color="showBarChart ? 'primary' : null"
          @click="handleShowBarChart"
        >
          mdi-chart-bar
        </v-icon>
      </div>
    </div>
    <div class="loading-bar">
      <v-progress-linear
        indeterminate
        v-if="loading"
      />
    </div>
    <div
      v-if="showBarChart"
      class="chart-container"
    >
      <BarChart
        class="bar-chart"
        :chartData="chartData"
        :axisType="AXISTYPE.SHORTEN"
        :commas="true"
        :uom="'$'"
      />
      <div class="paginator">
        <BarChartPaginator
          v-if="!loading"
          :refresh="filters"
          :rawData="rawData"
          :sortMethod="sortData"
          default-sort="desc"
          :buildChart="buildChart"
          :defaultQuantity="10"
        />
      </div>
    </div>
    <div
      class="table-container"
      v-if="showTable"
    >
      <GenericTable
        ref="genericTable"
        :columns="columns"
        :data="tableData"
        :nested_columns="nested"
        :footer="footer"
        :resetExpand="false"
        :expandRowClickHandler="expandRowClickHandler"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import BarChartPaginator from "@/components/BarChartPaginator";
import GenericTable from "@/components/GenericTable";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import { macawBlueGreen } from "@/scss/_variables.scss";
import { hexToRGB } from "@/utils/colors";
import { getDatasets } from "@/utils/scrapAnalyticsUtils";

export default {
  name: "CostByMachine",
  props: {
    filters: { type: Object, default: () => {} }
  },
  components: {
    BarChart,
    GenericTable,
    BarChartPaginator
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      showBarChart: true,
      showTable: false,
      chartData: {},
      loading: false,
      nested: [
        { key: "" },
        { key: "component" },
        { key: "description" },
        { key: "enteredQuantity" },
        { key: "formattedCost" }
      ],
      tableData: [],
      expanded: false,
      rawData: []
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    }),
    cost() {
      return `$ ${this.tableData.reduce((acc, cur) => acc + cur.cost, 0).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    columns() {
      if (this.expanded) {
        return [
          { title: this.$t("Machine"), key: "name" },
          { title: this.$t("Component"), key: "component" },
          { title: this.$t("Description"), key: "description", cssClass: "description" },
          { title: this.$t("Scrapped Quantity"), key: "enteredQuantity" },
          { title: this.$t("Cost"), key: "formattedCost", cssClass: "cost" }
        ];
      }
      return [
        { title: this.$t("Machine"), key: "name" },
        { title: this.$t("Cost"), key: "formattedCost" }
      ];
    },
    footer() {
      if (this.expanded) {
        return [
          { type: "text", text: this.$t("Total") },
          {},
          {},
          {},
          { type: "text", text: this.cost }
        ];
      }
      return [
        { type: "text", text: this.$t("Total") },
        { type: "text", text: this.cost }
      ];
    }
  },
  methods: {
    async expandRowClickHandler(data, index, expanded) {
      this.expanded = expanded.some((item) => item === true);
      if (!expanded[index]) {
        return;
      }
      this.loading = true;
      try {
        const params = {
          from_date: this.filters.fromDate,
          to_date: this.filters.toDate,
          machine_id: parseInt(data.id, 10)
        };
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_code/", { params });
        if (response && response.data) {
          const data = response.data.map((scrap) => {
            return {
              component:
                scrap.scrap_type === "non_process"
                  ? scrap.child_component_name
                  : scrap.scrap_reason_code,
              description:
                scrap.scrap_type === "non_process"
                  ? scrap.child_component_description
                  : scrap.description,
              enteredQuantity: `${scrap.entered_quantity}${"scrap_quantity_uom" in scrap ? scrap.scrap_quantity_uom : ""}`,
              formattedCost: `$ ${scrap.total_cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            };
          });
          const rowData = { ...this.tableData[index] };
          rowData._nested = data;
          this.$set(this.tableData, index, rowData);
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    handleShowBarChart() {
      this.showTable = false;
      this.showBarChart = true;
    },
    handleShowTable() {
      this.showBarChart = false;
      this.showTable = true;
    },
    sortData(data, sortDirection) {
      data.sort((a, b) => {
        const valueA = a.cost;
        const valueB = b.cost;
        return sortDirection === "desc" ? valueB - valueA : valueA - valueB;
      });
      return data;
    },
    buildChart(data) {
      this.chartData = {};
      const colors = data.map(() => hexToRGB(macawBlueGreen, "0.9"));
      this.chartData = {
        labels: data.map((item) => this.machineLookup(parseInt(item.id, 10)).name),
        datasets: getDatasets(data, colors)
      };
    },
    async getCostByMachine() {
      this.loading = true;
      const params = {
        from_date: this.filters.fromDate,
        to_date: this.filters.toDate
      };
      if ("machines" in this.filters && this.filters.machines.length > 0) {
        params.machine_ids = this.filters.machines;
      }
      if ("partNumbers" in this.filters && this.filters.partNumbers.length > 0) {
        params.part_numbers = this.filters.partNumbers;
      }
      try {
        const response = await this.$http.get("scrap-reporting/scrap_cost_by_machine/", { params });
        if (response && response.data) {
          this.rawData = Object.keys(response.data).map((key) => ({
            id: key,
            cost: response.data[key]
          }));
          this.tableData = Object.keys(response.data).map((key) => ({
            id: key,
            name: this.machineLookup(parseInt(key, 10)).name,
            cost: response.data[key],
            formattedCost: `$ ${response.data[key].toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
            _nested: [{}]
          }));
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    }
  },
  watch: {
    filters() {
      if ("genericTable" in this.$refs && this.$refs.genericTable) {
        this.$refs.genericTable.expanded = [];
      }
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalyticsCostByMachine.scss";
</style>
