<template>
  <div class="tla-report-charts">
    <grid-layout
      :col-num="12"
      :row-height="90"
      :is-resizable="false"
      :is-draggable="false"
      :vertical-compact="true"
      :use-css-transforms="false"
      :layout.sync="layout"
    >
      <grid-item
        v-for="(item, idx) in layout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
      >
        <component
          :is="item.component"
          v-bind="item.props"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { Fragment } from "vue-fragment";
import VueGridLayout from "vue-grid-layout";

import TLAMajorLosses from "./TLAMajorLosses";
import TLAMinorLosses from "./TLAMinorLosses";
import TLAUnplannedDowntime from "./TLAUnplannedDowntime";
import TLAUtilizationByLevel from "./TLAUtilizationByLevel";
import TLAUtilizationMachine from "./TLAUtilizationMachine";
import TLAUtilizationPart from "./TLAUtilizationPart";
import SummaryTLACard from "./summaryTLACard";
import useTLAFilters from "./useTLAFilters";

export default {
  name: "TLAReportCharts",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    Fragment: Fragment
  },
  props: ["filters"],
  setup() {
    const { removeAllFilters, hasFilters, interactiveFilters } = useTLAFilters();
    return {
      removeAllFilters,
      hasFilters,
      interactiveFilters
    };
  },
  data() {
    return {
      layout: [
        {
          x: 0,
          y: 0,
          w: 3,
          h: 1,
          i: 0,
          component: SummaryTLACard,
          props: {
            name: this.$t("Total Duration"),
            type: "summary",
            calculation: "totalDuration",
            subtitle: this.$t("(machine hours)"),
            summary: {},
            color: "#fed9b6"
          }
        },
        {
          x: 3,
          y: 0,
          w: 3,
          h: 1,
          i: 1,
          component: SummaryTLACard,
          props: {
            name: this.$t("Total Run Time"),
            type: "summary",
            calculation: "totalRuntime",
            subtitle: this.$t("(machine hours)"),
            summary: {},
            color: "#00aeb8"
          }
        },
        {
          x: 6,
          y: 0,
          w: 3,
          h: 1,
          i: 2,
          component: SummaryTLACard,
          props: {
            name: this.$t("Total Downtime"),
            type: "summary",
            calculation: "totalDowntime",
            subtitle: this.$t("(machine hours)"),
            summary: {},
            color: "#f07166"
          }
        },
        {
          x: 9,
          y: 0,
          w: 3,
          h: 1,
          i: 3,
          component: SummaryTLACard,
          props: {
            name: this.$t("Asset Utilization"),
            type: "summary",
            calculation: "assetUtilization",
            subtitle: "",
            summary: {},
            color: "#0081a6"
          }
        },
        {
          x: 0,
          y: 2,
          w: 6,
          h: 3,
          i: 4,
          component: TLAUtilizationByLevel,
          props: {
            priorityLevel: "tlaReportMachineGroupLevelP1",
            priority: 0
          }
        },
        {
          x: 6,
          y: 2,
          w: 6,
          h: 3,
          i: 5,
          component: TLAUtilizationByLevel,
          props: {
            priorityLevel: "tlaReportMachineGroupLevelP2",
            priority: 1
          }
        },
        {
          x: 0,
          y: 7,
          w: 6,
          h: 3,
          i: 6,
          component: TLAUtilizationMachine,
          props: {
            priority: 2
          }
        },
        {
          x: 6,
          y: 7,
          w: 6,
          h: 3,
          i: 7,
          component: TLAUtilizationPart,
          props: {
            priority: 3
          }
        },
        {
          x: 0,
          y: 12,
          w: 12,
          h: 2.5,
          i: 8, //there appears to be a bug h: 1.1 seems to fix it
          component: TLAMajorLosses,
          props: {
            priority: 4
          }
        },
        {
          x: 0,
          y: 15,
          w: 12,
          h: 4,
          i: 9,
          component: TLAMinorLosses,
          props: {
            priority: 5
          }
        },
        { x: 0, y: 21, w: 12, h: 3, i: 10, component: TLAUnplannedDowntime, props: {} }
      ]
    };
  },
  mounted() {
    this.sendFiltersToComponents();
  },
  methods: {
    sendFiltersToComponents: async function () {
      let summary;
      try {
        summary = await this.getFilteredAvailability();
      } catch (e) {
        console.log(e);
      }
      requestAnimationFrame(() => {
        this.layout = this.layout.map((l) => {
          if (l.props && l.props.type === "summary") {
            return { ...l, props: { ...l.props, summary: { ...summary }, filters: this.filters } };
          } else {
            return { ...l, props: { ...l.props, filters: this.filters } };
          }
        });
      });
    },
    getFilteredAvailability() {
      return new Promise((resolve, reject) => {
        if (!this.filters || !this.filters.from_date || !this.filters.to_date) {
          reject();
        } else {
          let params = {
            from_date: this.filters.from_date,
            to_date: this.filters.to_date
          };

          if (this.hasFilters) {
            if (
              this.interactiveFilters["machine_id"] &&
              this.interactiveFilters["machine_id"].length > 0
            ) {
              params.machine_id = this.interactiveFilters["machine_id"].join(",");
            } else if (
              this.interactiveFilters["tlaReportMachineGroupLevelP2"] &&
              this.interactiveFilters["tlaReportMachineGroupLevelP2"].length > 0
            ) {
              params.machine_group_id =
                this.interactiveFilters["tlaReportMachineGroupLevelP2"].join(",");
            } else if (
              this.interactiveFilters["tlaReportMachineGroupLevelP1"] &&
              this.interactiveFilters["tlaReportMachineGroupLevelP1"].length > 0
            ) {
              params.machine_group_id =
                this.interactiveFilters["tlaReportMachineGroupLevelP1"].join(",");
            }

            if (
              this.interactiveFilters["part_numbers"] &&
              this.interactiveFilters["part_numbers"].length > 0
            ) {
              params.part_numbers = this.interactiveFilters["part_numbers"].join(",");
            }

            if (
              this.interactiveFilters["minor_losses"] &&
              this.interactiveFilters["minor_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["minor_losses"].join(",");
            } else if (
              this.interactiveFilters["major_losses"] &&
              this.interactiveFilters["major_losses"].length > 0
            ) {
              params.status_codes = this.interactiveFilters["major_losses"].join(",");
            }
          } else {
            params.machine_group_id = this.filters.machine_groups.length
              ? this.filters.machine_groups.join(",")
              : null;
            params.machine_id = this.filters.machines.length
              ? this.filters.machines.join(",")
              : null;
            params.part_numbers = this.filters.part_numbers.length
              ? this.filters.part_numbers.join(",")
              : null;
            params.status_codes = this.filters.status_codes.length
              ? this.filters.status_codes.join(",")
              : null;
          }

          this.$http
            .get("metrics/availability_sum/", {
              params: params
            })
            .then((result) => {
              resolve(result.data);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    }
  },
  watch: {
    filters: async function () {
      this.sendFiltersToComponents();
    },
    interactiveFilters: async function () {
      this.sendFiltersToComponents();
    }
  }
};
</script>

<style scoped lang="scss">
.tla-report-charts {
  padding: 0 10px;
  flex: 1;
  overflow: scroll;
  margin-top: -10px;

  .text-card {
    background: white;
    width: 100%;
    height: 100%;
  }
}
</style>
