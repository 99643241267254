export const Presets = {
  SHIFT: 0,
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
  NONE: 5
};

export const Scales = {
  HOUR: "hour",
  DAY: "day",
  WEEK: "week",
  MONTH: "month"
};

export const PRESETS_TO_SCALE = {
  [Presets.SHIFT]: Scales.HOUR,
  [Presets.DAY]: Scales.HOUR,
  [Presets.WEEK]: Scales.DAY,
  [Presets.MONTH]: Scales.WEEK,
  [Presets.YEAR]: Scales.MONTH
};

export const SCALE_TO_PRESET = {
  [Scales.HOUR]: Presets.DAY,
  [Scales.DAY]: Presets.WEEK,
  [Scales.WEEK]: Presets.MONTH,
  [Scales.MONTH]: Presets.YEAR
};

export const FILTERS_DATE_FORMAT = "YYYY-MM-DD HH:mm";

export const DATE_FORMAT = "MMM DD";
export const DATE_FORMAT_YEAR = "MMM DD, YYYY";
export const DATE_TIME_FORMAT = "hh:mm A";

export const QUERY_LONG_LIVE_STALE_TIME = 60 * 60 * 1000; // 1hr
