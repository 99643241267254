<template>
  <div class="fileUpload">
    <v-btn
      v-if="transparent"
      icon
      @click="clickInput()"
    >
      <i
        class="fa fa-upload"
        aria-hidden="true"
      ></i>
    </v-btn>
    <v-btn
      color="white"
      v-else
      light
      fab
      small
      @click="clickInput()"
    >
      <i
        class="fa fa-upload"
        aria-hidden="true"
      ></i>
    </v-btn>
    <input
      :id="id"
      type="file"
      :accept="accept"
      capture
      @change="readFile"
    />
  </div>
</template>
<style lang="scss">
.fileUpload {
  input {
    display: none;
  }
}
</style>

<script>
export default {
  props: {
    value: {
      default: null
    },
    id: {
      default: "file"
    },
    accept: {
      default: ""
    },
    transparent: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      file: null
    };
  },
  components: {},
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    clickInput() {
      let el = document.getElementById(this.id);
      if (el) {
        el.click();
      }
    },
    readFile(e) {
      if (e && e.target.files.length > 0) {
        var file = e.target.files[0];
        var reader = new FileReader();
        let context = this;
        reader.onloadend = function () {
          context.file = reader.result;
          context.$emit("input", context.file);
        };
        console.log(file);
        reader.readAsDataURL(file);
      }
    },
    emitFile() {
      this.$emit("input", this.file);
    }
  }
};
</script>
