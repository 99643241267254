import moment from "moment/moment";
import Vue from "vue";
import zh_Hans from "@amcharts/amcharts4/lang/zh_Hans";
import es_ES from "@amcharts/amcharts4/lang/es_ES";
import en_US from "@amcharts/amcharts4/lang/en_US";

const MOMENT_JS_ABBR = {
  en: "en-ca",
  zh: "zh-cn"
};

const AM_CHARTS_LANGUAGE = {
  en: en_US,
  es: es_ES,
  zh: zh_Hans
};

export const setMomentJsLocale = (languageAbbr) => {
  const abv = String(languageAbbr).toLowerCase();
  moment.locale(MOMENT_JS_ABBR[abv] ? MOMENT_JS_ABBR[abv] : abv);
};

export const setVueLocale = (language) => {
  const jsonFile = JSON.parse(language.translations);
  if (!Vue.i18n.localeExists(language.abv)) {
    Vue.i18n.add(language.abv, jsonFile);
  } else {
    Vue.i18n.replace(language.abv, jsonFile);
  }
  Vue.i18n.set(language.abv);
};

export const changeLocale = (language) => {
  if (!language) return;
  setVueLocale(language);
  setMomentJsLocale(language.abv);
};

export const setAMChartsLocale = (chart) => {
  chart.language.locale = AM_CHARTS_LANGUAGE[Vue.i18n.locale()];
};
