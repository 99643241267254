<template>
  <div
    v-if="$vuetify.breakpoint.smAndDown"
    class="zcb-safety-mobile"
  >
    <v-data-table
      :headers="safetyTicketTableHeaders"
      :items="safetyTickets"
      :loading="loadingData"
      class="blue-headers"
    >
      <template v-slot:item.incidentDate="{ item }">
        {{ moment(item.incidentDate).format($datetime.date) }}
      </template>
      <template v-slot:item.firstAid="{ item }">
        <v-checkbox
          disabled
          :input-value="item.firstAid"
          class="my-0 py-0"
        />
      </template>
      <template v-slot:item.recordable="{ item }">
        <v-checkbox
          disabled
          :input-value="item.recordable"
          class="my-0 py-0"
        />
      </template>
      <template v-slot:item.affectedBodyPart="{ item }">
        {{ formatAffectedBodyParts(item.affectedBodyPart) }}
      </template>
    </v-data-table>
  </div>
  <v-row
    v-else-if="$vuetify.breakpoint.mdAndUp"
    class="mt-3 zcb-safety-card"
    style="overflow-y: hidden"
  >
    <!-- Blue guy -->
    <v-col cols="4">
      <v-row
        justify="center"
        class="mb-1"
      >
        <HumanDiagram
          type="card"
          :safetyTickets="safetyTickets"
        />
      </v-row>
      <v-row class="py-0 my-0">
        <svg
          width="40"
          height="40"
          class="mx-3"
        >
          <circle
            r="20"
            cx="20"
            cy="20"
            fill="#FFC794"
          />
        </svg>
        <p class="d-flex align-center">{{ $t("First Aid") }}</p>
      </v-row>
      <v-row class="mb-1">
        <svg
          width="40"
          height="40"
          class="mx-3"
        >
          <circle
            r="20"
            cx="20"
            cy="20"
            fill="#EA4D55"
          />
        </svg>
        <p class="d-flex align-center">{{ $t("Recordable") }}</p>
      </v-row>
    </v-col>
    <!-- Summary box, tables, and chart -->
    <v-col
      style="
        display: flex;
        flex-direction: column;
        max-height: calc(100% - 1rem);
        overflow-y: hidden;
      "
    >
      <!-- Summary box -->
      <v-row style="flex: unset">
        <v-col>
          <div class="summary-box">
            <div
              v-if="this.safetyTickets.length > 0 && !this.loadingData"
              style="padding-top: 10px; padding-bottom: 10px"
            >
              <v-row>
                <v-col>
                  <h4>{{ $t("Date") }}</h4>
                  <p>
                    <time :datetime="selectedTicket.incidentDate">{{
                      moment(selectedTicket.incidentDate).format($datetime.date)
                    }}</time>
                  </p>
                </v-col>
                <v-col>
                  <div style="display: inline-flex">
                    <h4>{{ $t("First Aid") }}</h4>
                    <v-checkbox
                      disabled
                      :input-value="selectedTicket.firstAid"
                      class="mt-0 pt-0 pl-1"
                    />
                  </div>
                </v-col>
                <v-col>
                  <div style="display: inline-flex">
                    <h4>{{ $t("Recordable") }}</h4>
                    <v-checkbox
                      disabled
                      :input-value="selectedTicket.recordable"
                      class="mt-0 pt-0 pl-1"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h4>{{ $t("Description of Last Incident") }}</h4>
                  <p>{{ selectedTicket.description }}</p>
                </v-col>
                <v-col>
                  <h4>{{ $t("Corrective Action") }}</h4>
                  <p>{{ selectedTicket.correctiveAction }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-icon
                    small
                    @click="editEvent"
                    class="mr-2"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    small
                    @click="() => (deleteDialog = true)"
                    class="ml-2"
                    >mdi-trash-can</v-icon
                  >
                </v-col>
              </v-row>
            </div>
            <div v-if="this.loadingData">
              <v-skeleton-loader
                class="mx-auto"
                type="heading"
              />
              <v-skeleton-loader
                class="mx-auto mt-3"
                type="text"
              />
            </div>
          </div>
        </v-col>
        <v-dialog
          v-model="deleteDialog"
          max-width="400"
        >
          <v-card>
            <DialogCloseButton :onClick="() => (deleteDialog = false)" />
            <v-card-title class="headline">{{ $t("Delete Incident") }}</v-card-title>
            <v-card-text>
              {{ $t("Are you sure you want to delete this incident?") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="deleteDialog = false"
              >
                {{ $t("Cancel") }}
              </v-btn>
              <v-btn
                color="error"
                text
                @click="deleteEvent"
              >
                {{ $t("Confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- Safety tickets table -->
      <v-row style="max-height: 50%">
        <v-col style="max-height: 100%">
          <div
            class="card"
            style="height: 100%"
          >
            <div class="card card-body pt-2 safety-ticket-table">
              <v-data-table
                :headers="safetyTicketTableHeaders"
                :items="safetyTickets"
                :loading="loadingData"
                @click:row="(row) => handleRowClick(row)"
                dense
                class="blue-headers"
                fixed-header
              >
                <template v-slot:item.description="{ item }">{{
                  item.description.length > 20
                    ? item.description.slice(0, 19) + "..."
                    : item.description
                }}</template>
                <template v-slot:item.correctiveAction="{ item }">{{
                  item.correctiveAction.length > 20
                    ? item.correctiveAction.slice(0, 19) + "..."
                    : item.correctiveAction
                }}</template>
                <template v-slot:item.incidentDate="{ item }">
                  {{ moment(item.incidentDate).format($datetime.date) }}
                </template>
                <template v-slot:item.firstAid="{ item }">
                  <v-checkbox
                    disabled
                    :input-value="item.firstAid"
                    class="my-0 py-0"
                  />
                </template>
                <template v-slot:item.recordable="{ item }">
                  <v-checkbox
                    disabled
                    :input-value="item.recordable"
                    class="my-0 py-0"
                  />
                </template>
                <template v-slot:item.affectedBodyPart="{ item }">
                  {{ formatAffectedBodyParts(item.affectedBodyPart) }}
                </template>
              </v-data-table>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Bar chart and group/shift table -->
      <v-row style="align-items: flex-start; flex: 0.3">
        <!-- Bar chart -->
        <v-col>
          <div
            class="card"
            style="height: 12.5rem"
          >
            <div class="card-body">
              <v-row class="justify-space-between ml-3 mr-3 pt-1 mb-2">
                <h4 class="blue-header">
                  {{ $t("Safety Trend Information") }}
                </h4>
                <div>
                  <div
                    v-for="(btn, btnIndex) in intervals"
                    class="btns-container"
                  >
                    <button
                      class="btn radioBtn align-top"
                      style="width: 4rem"
                      :key="btnIndex"
                      :class="{ active: safetyTrendPreset === btn.value }"
                      @click="presetClick(btn.value)"
                    >
                      {{ btn.name }}
                    </button>
                    <v-autocomplete
                      v-if="btn.value === 'year' && safetyTrendPreset === btn.value"
                      class="year-dropdown"
                      hide-details
                      auto-select-first
                      v-model="selectedYear"
                      :items="[currentYear, currentYear - 1]"
                      :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                    ></v-autocomplete>
                  </div>
                </div>
              </v-row>
              <bar-chart
                class="bar-chart"
                v-if="chartData"
                title=""
                :chartData="chartData"
                :chartOptions="chartOptions"
                :height="120"
                :click-function="handleBarChartClick"
              />
            </div>
          </div>
        </v-col>
        <!-- Group/shift table -->
        <v-col class="pt-0 pl-0">
          <div
            class="card card-body"
            style="min-height: 199px; max-height: 199px; overflow-y: scroll"
          >
            <div class="card-body">
              <v-simple-table
                v-if="!loadingData"
                fixed-header
                height="199px"
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>{{ $t("Machine Group") }}</th>
                      <th>{{ $t("First Aid") }}</th>
                      <th>{{ $t("Recordable") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(group, index) in safetyEventsByGroupAndShift">
                      <tr :key="'group' + index">
                        <td class="align-left">
                          <v-btn
                            v-if="group && group.shifts && group.shifts.buckets.length > 0"
                            @click="
                              toggleShifts(group);
                              toggleGroup(group.key);
                            "
                            icon
                          >
                            <v-icon
                              >{{ expandedShifts[group.key] ? "mdi-menu-down" : "mdi-menu-right" }}
                            </v-icon>
                          </v-btn>
                          <span
                            v-else
                            class="pl-7"
                          ></span>
                          {{ group.name }}
                        </td>
                        <td>{{ group["first_aid"].value }}</td>
                        <td>{{ group.recordable.value }}</td>
                        <td>
                          <v-btn
                            @click="handleGroupFilterClick(group.key)"
                            :color="selectedMachineGroupID ? 'error' : 'primary'"
                          >
                            {{ $t("Filter") }}
                          </v-btn>
                        </td>
                      </tr>
                      <template v-if="expandedShifts[group.key]">
                        <tr
                          v-for="(shift, index) in expandedShifts[group.key].buckets"
                          :key="'shift' + index"
                        >
                          <td>
                            <span class="pl-12">Shift {{ shift.name }}</span>
                          </td>
                          <td>{{ shift["first_aid"].value }}</td>
                          <td>{{ shift.recordable.value }}</td>
                          <td></td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
              <p v-if="loadingData">{{ $t("Loading") }}...</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";

import DialogCloseButton from "@/components/DialogCloseButton";

import PaginatedChart from "../../views/analytics/operator/PaginatedChart.vue";
import BarChart from "../charts/BarChart";
import HumanDiagram from "./safety_body_diagram/HumanDiagram.vue";

export default {
  name: "SafetyCard",
  props: ["level", "machine_group_pk", "safetyEventSubmits"],
  emits: ["safetyEventSubmitted", "editSafetyEvent"],
  data() {
    return {
      safetyTickets: [],
      safetyTicketTableHeaders: [
        { text: this.$t("Date"), value: "incidentDate" },
        { text: this.$t("Description"), value: "description" },
        { text: this.$t("Corrective Action"), value: "correctiveAction" },
        { text: this.$t("First Aid"), value: "firstAid" },
        { text: this.$t("Recordable"), value: "recordable" },
        { text: this.$t("Affected Body Part"), value: "affectedBodyPart" }
      ],
      loadingData: false,
      selectedTicket: {},
      safetyTrendData: null,
      safetyTrendPreset: "year",
      chartOptions: {},
      chartData: { datasets: [], labels: [] },
      intervals: [
        { name: this.$t("Day"), value: "day" },
        { name: this.$t("Week"), value: "week" },
        { name: this.$t("Month"), value: "month" },
        { name: this.$t("Year"), value: "year" }
      ],
      safetyGroupLevel: null,
      safetyEventsByGroupAndShift: [],
      expandedShifts: {},
      selectedGroup: null,
      groupsBySafetyLevel: [],
      selectedMachineGroupID: null,
      selectedShift: null,
      currentYear: moment().toDate().getFullYear(),
      selectedYear: moment().toDate().getFullYear(),
      yearScale: { from_date: moment().startOf("year"), to_date: moment().endOf("year") },
      circleRadius: 3,
      deleteDialog: false
    };
  },
  components: { PaginatedChart, BarChart, DialogCloseButton, HumanDiagram },
  methods: {
    // IMPORTS
    moment,

    // UTILITIES
    handleRowClick(row) {
      this.selectedTicket = row;
    },
    formatAffectedBodyParts(input) {
      /**
       * Transforms a string of pipe-separated values into a comma-separated list of title-cased values.
       * If the input cannot be split into parts, returns the input unmodified (for bad testing data).
       * @function
       * @param {string} input - The input string to be formatted.
       * @returns {string} A comma separated list of title-cased values, or the input unmodified
       */
      try {
        const parts = input.split("|");
        const titleCasedParts = parts.map((part) => {
          return this.camelCaseToTitleCase(part);
        });
        return titleCasedParts.join(", ");
      } catch (error) {
        return input;
      }
    },
    camelCaseToTitleCase(str) {
      return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
    },
    presetClick(preset) {
      this.selectedGroup = null;
      this.selectedShift = null;
      this.expandedShifts = {};
      this.safetyTrendPreset = preset;
      let from_date = moment();
      let to_date = moment();
      let dateFormat = "D MMM hhA";
      let scale = "1h";
      switch (preset) {
        case "day":
          from_date = moment().startOf("hour").subtract(23, "h");
          to_date = moment().endOf("hour");
          dateFormat = "D MMM hhA";
          scale = "1h";
          break;
        case "week":
          from_date = moment().startOf("week");
          to_date = moment().endOf("week");
          dateFormat = "D MMM";
          scale = "1d";
          break;
        case "month":
          from_date = moment().startOf("month");
          to_date = moment().endOf("month");
          dateFormat = "week";
          scale = "1w";
          break;
        case "year":
          from_date = this.yearScale.from_date;
          to_date = this.yearScale.to_date;
          dateFormat = "MMM YY";
          scale = "1M";
          break;
      }
      Object.assign(this.chartOptions, {
        interval: scale,
        preset: preset,
        dateFormat: dateFormat,
        from_date: from_date,
        to_date: to_date
      });
      this.loadSafetyTickets();
      this.loadSafetyTrendData().then(() => {
        this.generateChart();
      });
      this.getSafetyEventsByGroupAndShift();
    },
    generateChart() {
      const recordable = this.safetyTrendData.map((item) => {
        return item.recordable.value;
      });
      const firstAid = this.safetyTrendData.map((item) => {
        return item["first_aid"].value;
      });
      const labels = this.safetyTrendData.map((item) => {
        if (this.chartOptions.dateFormat === "week") {
          let start = moment(item.key_as_string);
          let end = moment(item.key_as_string).add(7, "d");
          if (start.month() !== end.month()) {
            return start.format("D MMM") + " - " + end.format("D MMM");
          } else {
            return start.format("D") + " - " + end.format("D MMM");
          }
        } else {
          return moment(item.key_as_string).format(this.chartOptions.dateFormat);
        }
      });
      Object.assign(this.chartData, {
        labels: labels,
        datasets: [
          {
            label: this.$t("Recordable"),
            backgroundColor: "#EA4D55",
            data: recordable
          },
          {
            label: this.$t("First Aid"),
            backgroundColor: "#FFC794",
            data: firstAid
          }
        ]
      });
    },
    toggleShifts(group) {
      // Check if the expandedShifts object already has this group key
      // eslint-disable-next-line no-prototype-builtins
      if (this.expandedShifts.hasOwnProperty(group.key)) {
        // If it does, remove the shifts array from the object
        this.$delete(this.expandedShifts, group.key);
      } else {
        // If it doesn't, add the shifts array to the object
        this.$set(this.expandedShifts, group.key, group.shifts);
      }
    },
    toggleGroup(groupKey) {
      if (this.selectedGroup === groupKey) {
        this.selectedGroup = null;
      } else {
        this.selectedGroup = groupKey;
      }
    },
    editEvent() {
      this.$emit("editSafetyEvent", this.selectedTicket);
    },

    // API CALLS
    async setGroupsBySafetyLevel() {
      this.loadingData = true;
      await this.getSafetyGroupLevel();
      if (this.level === this.safetyGroupLevel) {
        this.groupsBySafetyLevel.push(this.machine_group_pk);
      } else if (this.level > this.safetyGroupLevel) {
        // get all subgroup ids at the safety group level
        return this.$http
          .get("graphql/", {
            params: {
              query: `{
                                    machineGroups {
                                      pk
                                      level {
                                        level
                                      }
                                      allSubGroups {
                                        pk
                                        level {
                                          level
                                        }
                                      }
                                    }
                                  }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroup = machineGroups.find(
              (machineGroup) => parseInt(machineGroup.pk) === parseInt(this.machine_group_pk)
            );
            const subGroups = machineGroup["allSubGroups"];
            subGroups.forEach((subGroup) => {
              if (subGroup.level.level === this.safetyGroupLevel) {
                this.groupsBySafetyLevel.push(subGroup.pk);
              }
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        // find the parent at the safety group level
        return this.$http
          .get("graphql/", {
            params: {
              query: `{
                                    machineGroups {
                                      pk
                                      level {
                                        level
                                      }
                                      allSubGroups {
                                        pk
                                        level {
                                          level
                                        }
                                      }
                                    }
                                  }`
            }
          })
          .then((res) => {
            const machineGroups = res.data.data.machineGroups;
            const machineGroupsAtThisLevel = machineGroups.filter(
              (machineGroup) => machineGroup.level.level === this.safetyGroupLevel
            );
            machineGroupsAtThisLevel.forEach((machineGroup) => {
              const subGroups = machineGroup["allSubGroups"];
              subGroups.forEach((subGroup) => {
                if (subGroup.pk === this.machine_group_pk) {
                  this.groupsBySafetyLevel.push(machineGroup.pk);
                }
              });
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }
      this.loadingData = false;
    },
    async loadSafetyTickets() {
      this.loadingData = true;
      /**
       * Loads safety tickets from GraphQL API.
       * Sets `loadingData` to true while loading, and false after loading is complete.
       * Sorts the loaded safety tickets in descending order by `incidentDate`.
       * Filters the loaded safety tickets to only include nodes with `incidentDate` between `this.chartOptions.from_date` and `this.chartOptions.to_date`.
       * @function
       * @throws {Error} If the GraphQL API request fails
       */
      const query = `
    {
      safetyEvents {
        edges {
          node {
            id
            machineGroupId
            incidentDate
            description
            correctiveAction
            firstAid
            recordable
            affectedBodyPart
          }
        }
      }
    }
  `;
      await this.$http.post("graphql/", { query: query }).then(({ data }) => {
        const { edges } = data.data["safetyEvents"];
        this.safetyTickets = [];
        edges.forEach((edge) => {
          const { node } = edge;
          if (
            this.groupsBySafetyLevel.includes(node.machineGroupId) &&
            moment(node.incidentDate).isAfter(this.chartOptions.from_date) &&
            moment(node.incidentDate).isBefore(this.chartOptions.to_date)
          ) {
            if (
              this.selectedMachineGroupID &&
              parseInt(this.selectedMachineGroupID) === node.machineGroupId
            ) {
              this.safetyTickets.push(node);
            } else if (!this.selectedMachineGroupID) {
              this.safetyTickets.push(node);
            }
          }
        });
        this.safetyTickets.sort((a, b) => new Date(b.incidentDate) - new Date(a.incidentDate));
        this.selectedTicket = this.safetyTickets[0];
      });
      this.loadingData = false;
    },
    async getSafetyEventsByGroupAndShift() {
      this.loadingData = true;
      const params = {
        machine_group_ids: this.selectedMachineGroupID
          ? [this.selectedMachineGroupID]
          : this.groupsBySafetyLevel,
        to_date: this.chartOptions.to_date.toISOString(),
        from_date: this.chartOptions.from_date.toISOString()
      };
      this.$http
        .get("/safety/safety_events_by_group_and_shift/", {
          params
        })
        .then((res) => {
          this.$set(this, "safetyEventsByGroupAndShift", res.data);
        });
      this.loadingData = false;
    },
    async loadSafetyTrendData() {
      this.loadingData = true;
      const params = {
        to_date: this.chartOptions.to_date.toISOString(),
        from_date: this.chartOptions.from_date.toISOString(),
        interval: this.chartOptions.interval,
        group_ids: this.selectedMachineGroupID
          ? [this.selectedMachineGroupID]
          : this.groupsBySafetyLevel
      };
      await this.$http
        .get("safety/safety_trend/", { params })
        .then((res) => {
          this.safetyTrendData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
      this.loadingData = false;
    },
    async getSafetyGroupLevel() {
      this.loadingData = true;
      const response = await this.$http.get("graphql/", {
        params: {
          query: `{
           settings{
             safetyGroupLevel {
               pk
               name
               id
               level
             }
           }
         }`
        }
      });
      this.safetyGroupLevel = response.data.data.settings[0].safetyGroupLevel.level;
      this.loadingData = false;
    },
    getItemInterval() {
      switch (this.chartOptions.interval) {
        case "1d":
          return "day";
        case "1w":
          return "week";
        case "1M":
          return "month";
        case "1y":
          return "year";
      }
    },
    buildDates(label, dateFormat) {
      if (dateFormat === "week") {
        const splitLabel = label.split(" - ");
        let startDay = splitLabel[0];
        const endDay = splitLabel[1];

        if (startDay.split(" ").length === 1) {
          // if start day is only DD (and not DD MMM), add the month to it
          const month = endDay.split(" ")[1];
          startDay = startDay + " " + month;
        }

        const startDate = moment(startDay + " " + moment().year(), "D MMM YYYY").endOf("day");
        const endDate = moment(endDay + " " + moment().year(), "D MMM YYYY")
          .subtract(1, "d")
          .endOf("day");
        return [startDate, endDate];
      } else {
        const date = moment(label, dateFormat);
        const itemInterval = this.getItemInterval();
        const startDate = moment(date).startOf(itemInterval);
        const endDate = moment(date).endOf(itemInterval);
        return [startDate, endDate];
      }
    },
    handleBarChartClick(event, arg2) {
      if (arg2[0]) {
        this.safetyTrendPreset = null;
        this.selectedGroup = null;
        this.selectedMachineGroupID = null;
        const label = arg2[0]._view.label;
        const [startDate, endDate] = this.buildDates(
          label,
          this.chartOptions.dateFormat,
          this.chartOptions.interval
        );
        this.chartOptions.from_date = startDate;
        this.chartOptions.to_date = endDate;
        this.loadSafetyTickets();
        this.loadSafetyTrendData().then(() => {
          this.generateChart();
        });
        this.getSafetyEventsByGroupAndShift();
      }
    },
    handleGroupFilterClick(key) {
      if (!this.selectedMachineGroupID) {
        this.safetyEventsByGroupAndShift = this.safetyEventsByGroupAndShift.filter(
          (group) => group.key === key
        );
        this.selectedMachineGroupID = key;
        this.loadSafetyTickets();
        this.getSafetyEventsByGroupAndShift();
        this.loadSafetyTrendData().then(() => {
          this.generateChart();
        });
      } else {
        this.selectedMachineGroupID = null;
        this.loadSafetyTickets();
        this.getSafetyEventsByGroupAndShift();
        this.loadSafetyTrendData().then(() => {
          this.generateChart();
        });
      }
    },
    async deleteEvent() {
      try {
        const mutationName = "updateSafetyEvent";
        const inputTypeName = "UpdateSafetyEventInput";
        const query = `mutation ($input: ${inputTypeName}!) {
      ${mutationName}(safetyData: $input) {
        safetyEvent {
          id
        }
      }
    }`;
        const input = {
          id: this.selectedTicket.id,
          delete: true
        };
        const response = await this.$http.post("graphql/", {
          query,
          variables: { input }
        });
        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }
        this.deleteDialog = false;
        setTimeout(() => {
          this.loadSafetyTickets();
          this.getSafetyEventsByGroupAndShift();
          this.loadSafetyTrendData().then(() => {
            this.generateChart();
          });
        }, 1000);
        this.$message("ALERT", {
          text: this.$t("Incident Deleted"),
          type: "success"
        });
      } catch (error) {
        console.error("Error deleting the incident:", error);
        this.$message("ALERT", { text: "Failed", type: "error" });
        this.deleteDialog = false;
      }
    }
  },
  async mounted() {
    await this.setGroupsBySafetyLevel();
    await this.loadSafetyTickets();
    await this.presetClick("year");
  },
  watch: {
    safetyEventSubmits() {
      this.loadSafetyTickets();
      this.getSafetyEventsByGroupAndShift();
      this.loadSafetyTrendData().then(() => {
        this.generateChart();
      });
    },
    selectedYear() {
      this.yearScale.from_date = moment([this.selectedYear]).startOf("year");
      this.yearScale.to_date = moment([this.selectedYear]).endOf("year");
      this.presetClick("year");
    }
  }
};
</script>
<style lang="scss">
@import "../../scss/variables";
@import "../../scss/mq";

.zcb-safety-card {
  height: 100%;

  .summary-box {
    border: 2px solid $blue;
    border-radius: 8px;
    padding: 8px 5px;
    width: 100%;
  }

  .summary-box h4 {
    color: $blue;
    display: inline;
    margin-right: 3px;
  }

  .summary-box p {
    display: inline;
  }

  .summary-box .col {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
  }

  .blue-header {
    color: $blue !important;
  }

  th {
    color: $blue !important;
  }

  .safety-ticket-table {
    max-height: 100%;
    height: 100%;
  }

  .safety-ticket-table .v-data-table__wrapper {
    max-height: 100%;
    overflow-y: scroll;
  }

  .safety-ticket-table .v-data-table {
    height: calc(100% - 4rem) !important;
  }

  .btns-container {
    display: inline-block;
    vertical-align: top;

    .year-dropdown {
      width: 4rem;
      text-align: end;
      padding: 0;
      margin: 0;
    }

    .year-dropdown.v-select.v-input--is-focused input {
      min-width: 2rem;
    }
  }

  .bar-chart {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.zcb-safety-mobile {
  .v-data-footer {
    flex-wrap: nowrap;
  }
}
</style>
