<script>
import BarChart from "../../../components/charts/BarChart";
import { round } from "@amcharts/amcharts5/.internal/core/util/Math";
export default {
  name: "SubgroupBreakdown",
  props: ["level", "machine_group_id", "machine_group_pk"],
  data() {
    return {
      shifts: [],
      subgroups: [],
      loading: 0,
      startDate: null,
      endDate: null,
      preset: "Shift"
    };
  },
  methods: {
    async getShift() {
      this.loading++;
      const query = `{
        shifts(current: true, machineGroupId: "${this.machine_group_id}") {
          shiftDays(current: true) {
            id,
            lastStartDatetime,
            nextEndDatetime,
          }
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", {
          params: { query }
        });
        if (response && response.data && response.data.data) {
          this.shifts = response.data.data.shifts;
          const allDates = this.shifts.flatMap((shift) =>
            shift.shiftDays.flatMap((shiftDay) => [
              shiftDay.lastStartDatetime,
              shiftDay.nextEndDatetime
            ])
          );
          this.startDate = new Date(Math.min(...allDates.map((date) => new Date(date))));
          this.endDate = new Date(Math.max(...allDates.map((date) => new Date(date))));
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading--;
      }
    },
    async getSubgroups() {
      this.loading++;
      const query = `
      {
        machineGroup(id: "${this.machine_group_id}") {
          subGroups{
            edges{
              node{
                id
                pk
                name
              }
            }
          }
        }
      }
      `;
      try {
        const response = await this.$http.get("graphql/", {
          params: { query }
        });
        if (response && response.data && response.data.data) {
          const allSubGroups = response.data.data.machineGroup["subGroups"]["edges"];
          this.subgroups = allSubGroups
            .reduce((unique, item) => {
              return unique.some((subgroup) => subgroup.node.id === item.node.id)
                ? unique
                : [...unique, item];
            }, [])
            .map((group) => group.node);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading--;
      }
    },
    async getSubgroupBreakdown() {
      if (!this.startDate || !this.endDate || !this.subgroups || this.subgroups.length === 0) {
        setTimeout(() => this.getSubgroupBreakdown(), 1000);
        return;
      }
      this.loading++;
      const data = {
        start_date: this.startDate,
        end_date: this.endDate,
        subgroups: this.subgroups
      };
      try {
        const response = await this.$http.post("/metrics/subgroup_breakdown/", data);
        if (response && response.data) {
          for (let i = 0; i < response.data.length; i++) {
            const subgroup = this.subgroups.find(
              (subgroup) => subgroup.pk === response.data[i].subgroup_id
            );
            if (subgroup) {
              subgroup.produced = response.data[i].produced;
              subgroup.productionTarget2 = response.data[i].production_target_2;
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading--;
      }
    },
    async changePreset() {
      this.loading++;
      const now = new Date();
      switch (this.preset) {
        case 0:
          await this.getShift();
          await this.getSubgroupBreakdown();
          this.loading--;
          break;
        case 1:
          this.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          this.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
          await this.getSubgroupBreakdown();
          this.loading--;
          break;
        case 2:
          // eslint-disable-next-line no-case-declarations
          const firstDayOfWeek = now.getDate() - now.getDay();
          this.startDate = new Date(now.getFullYear(), now.getMonth(), firstDayOfWeek);
          this.endDate = new Date(now.getFullYear(), now.getMonth(), firstDayOfWeek + 7);
          await this.getSubgroupBreakdown();
          this.loading--;
          break;
        case 3:
          this.startDate = new Date(now.getFullYear(), now.getMonth(), 1);
          this.endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          await this.getSubgroupBreakdown();
          this.loading--;
          break;
        case 4:
          this.startDate = new Date(now.getFullYear(), 0, 1);
          this.endDate = new Date(now.getFullYear() + 1, 0, 0);
          await this.getSubgroupBreakdown();
          this.loading--;
          break;
      }
    }
  },
  async created() {
    this.loading++;
    try {
      await this.getShift();
      await this.getSubgroups();
      await this.getSubgroupBreakdown();
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.loading--;
      }, 2000);
    }
  },
  components: {
    BarChart
  },
  computed: {
    chartData() {
      if (!this.loading) {
        return {
          labels: this.subgroups.map((subgroup) => subgroup.name),
          datasets: [
            {
              label: this.$t("Production Target"),
              data: this.subgroups.map((subgroup) => round(subgroup.productionTarget2)),
              type: "scatter",
              pointRadius: 5,
              fill: false,
              borderColor: "#ffffff",
              pointBackgroundColor: "#ffffff"
            },
            {
              label: this.$t("Produced"),
              data: this.subgroups.map((subgroup) => subgroup.produced),
              backgroundColor: this.subgroups.map((subgroup) => {
                return subgroup.produced < subgroup.productionTarget2 ? "#EA4D55" : "#00BC6C";
              }),
              borderColor: this.subgroups.map((subgroup) => {
                return subgroup.produced < subgroup.productionTarget2 ? "#EA4D55" : "#00BC6C";
              }),
              borderWidth: 1
            }
          ]
        };
      }
    }
  },
  watch: {
    preset: {
      handler: function () {
        this.changePreset();
      },
      immediate: false
    }
  }
};
</script>

<template>
  <div class="subgroup-breakdown card">
    <div class="header">
      <h4>{{ $t("Presets") }}</h4>
      <v-tabs
        height="50"
        v-model="preset"
      >
        <v-tab>{{ $t("Shift") }}</v-tab>
        <v-tab>{{ $t("Day") }}</v-tab>
        <v-tab>{{ $t("Week") }}</v-tab>
        <v-tab>{{ $t("Month") }}</v-tab>
        <v-tab>{{ $t("Year") }}</v-tab>
      </v-tabs>
    </div>
    <div class="chart-container">
      <BarChart
        v-if="!loading && chartData.datasets.length > 0"
        style="height: 100%"
        :chart-data="chartData"
        :axisType="'shorten'"
        :commas="true"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.subgroup-breakdown {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1rem;
  margin: 1rem;
  overflow-y: auto;
}

.header {
  height: 100px;
}

.chart-container {
  flex-grow: 1;
}
</style>
