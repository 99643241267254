<template>
  <div class="group_help_tickets">
    <v-dialog
      v-model="downloadingCsv"
      content-class="group_help_tickets_csv"
      persistent
    >
      <template v-slot:default="dialog">
        <div class="card">
          <DialogCloseButton :onClick="cancelDownloadCsv" />
          <v-card-text>
            <div class="progress_card">
              <div class="progress_holder">
                {{ $t("creating csv") }}
                <v-progress-linear indeterminate />
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="card-actions">
            <v-btn
              color="primary"
              @click="cancelDownloadCsv"
              >Cancel</v-btn
            >
          </v-card-actions>
        </div>
      </template>
    </v-dialog>
    <div class="header-row">
      <machine-prioritization
        v-if="prioritization && accessToConfig"
        :machine_group_id="machine_group_id"
      >
      </machine-prioritization>
    </div>

    <v-data-table
      :height="windowWidth > 990 ? 'calc(100vh - 15rem)' : 'calc(100vh - 17rem)'"
      item-key="id"
      :items="helpTable.data"
      :headers="headers"
      :sortBy.sync="helpTable.sortBy"
      :sortDesc.sync="helpTable.sortDesc"
      :page.sync="helpTable.page.current"
      :itemsPerPage.sync="helpTable.page.itemsPerPage"
      :serverItemsLength="helpTable.total"
      @click:row="goToTicket"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Help Tickets... Please wait')"
      disable-pagination
      fixed-header
    >
      <template v-slot:[`item.title`]="{ item }">
        {{ item.node.title }}
      </template>
      <template v-slot:[`item.ticketedDate`]="{ item }">
        {{ moment(item.node.ticketedDate).format($datetime.default) }}
      </template>
      <template v-slot:[`item.tagSearch`]="{ item }">
        <div class="tags">
          <div
            class="tag"
            v-html="getTagSlot(item.node.tagLevel1)"
          ></div>
          <div
            class="tag"
            v-html="getTagSlot(item.node.tagLevel2)"
          ></div>
          <div
            class="tag"
            v-html="getTagSlot(item.node.tagLevel3)"
          ></div>
          <div
            class="tag"
            v-html="getTagSlot(item.node.tagLevel4)"
          ></div>
          <div
            class="tag"
            v-html="getTagSlot(item.node.tagLevel5)"
          ></div>
        </div>
      </template>
      <template v-slot:[`item.machineName`]="{ item }">
        <router-link
          class="machine-link"
          :to="{
            name: 'machine',
            params: {
              machine_id: getMachineGQLId(item.node.machineId),
              machine_pk: item.node.machineId
            }
          }"
        >
          <p>{{ item.node.machineName }}</p>
          <i
            class="fa fa-external-link-square"
            aria-hidden="true"
          />
        </router-link>
      </template>
      <template v-slot:[`item.P1`]="{ item }">
        {{ getP911GroupLevelName(item.node.machineId, levelP1.pk) }}
      </template>
      <template v-slot:[`item.P2`]="{ item }">
        {{ getP911GroupLevelName(item.node.machineId, levelP2.pk) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          class="status-wrapper"
          v-html="getStatusSlot(item.node.machineId)"
        ></div>
      </template>
      <template v-slot:[`item.unplanned`]="{ item }">
        <div class="unplanned-wrapper">{{ getUnplanned(item.node.machineId) }}</div>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.node.humanReadableId ? item.node.humanReadableId : item.node.esId }}
      </template>
      <template v-slot:[`item.factoryOrder`]="{ item }">
        {{ item.node.factoryOrder }}
      </template>
      <template v-slot:[`item.ticketedByName`]="{ item }">
        {{ item.node.ticketedByName }}
      </template>
      <template v-slot:[`item.partName`]="{ item }">
        {{ item.node.partName }}
      </template>
      <template v-slot:[`item.duration`]="{ item }">
        {{
          item.node.closedDate
            ? seconds(moment(item.node.closedDate).diff(moment(item.node.ticketedDate)) / 1000)
            : seconds(moment().diff(moment(item.node.ticketedDate)) / 1000)
        }}
      </template>
      <template v-slot:[`header.assignedToNames`]="{ item }">
        <v-tabs
          class="tabs"
          v-model="usersTab"
          key="tabs"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="realtime">
            <p>
              <span class="button_text">{{ $t("Assigned") }}</span>
            </p>
          </v-tab>
          <v-tab key="summary">
            <p>
              <span class="button_text">{{ $t("Responding") }}</span>
            </p>
          </v-tab>
        </v-tabs>
      </template>
      <template v-slot:[`item.assignedToNames`]="{ item }">
        <div class="assignedRow">
          <v-dialog
            content-class="assignableDialog"
            v-model="item.assignDialog"
            persistent
            max-width="600px"
            v-if="usersTab === 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                light
                fab
                small
                v-bind="attrs"
                v-on="on"
              >
                <i
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">
                <h3>{{ $t("Assign Users") }}</h3>
              </v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-xs-12 col-sm-6">
                    <v-text-field
                      v-model="userSearch"
                      append-icon="mdi-magnify"
                      :label="$t('Search Users to Assign')"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                    <div class="assignable">
                      <p
                        v-for="user in filteredUsers"
                        @click="newUsers.push(user)"
                      >
                        {{ user.firstName }} {{ user.lastName }}
                      </p>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6">
                    <h2>{{ $t("New Users") }}</h2>
                    <div class="newUsers">
                      <p
                        v-for="(new_user, new_user_index) in newUsers"
                        @click="newUsers.splice(new_user_index, 1)"
                      >
                        {{ new_user.firstName }} {{ new_user.lastName }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  text
                  @click="closeAssign(item)"
                >
                  {{ $t("Cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  primary
                  text
                  :disabled="newUsers.length === 0"
                  :loading="assigningTicket"
                  @click="helpAssign(item)"
                >
                  {{ $t("Assign") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div v-if="!p911Table">
            <p v-if="usersTab === 0">
              {{
                item.node.assignedToNames
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll("'", "")
              }}
            </p>
            <p v-if="usersTab === 1">
              {{
                item.node.respondingNames
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll("'", "")
              }}
            </p>
          </div>
          <div
            v-else
            class="assigned-responding"
          >
            <div
              v-if="usersTab === 0"
              class="assigned"
            >
              {{
                item.node.assignedToNames
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll("'", "")
              }}
            </div>
            <div
              v-if="usersTab === 1"
              class="responding"
            >
              {{
                item.node.respondingNames
                  .replaceAll("[", "")
                  .replaceAll("]", "")
                  .replaceAll("'", "")
              }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:[`item.launch`]="{ item }">
        <v-btn
          class="hidden-xs-only launch-button"
          small
          color="primary"
          fab
          @click.stop.prevent="launchTicket(item)"
        >
          <i
            class="fa fa-external-link-square"
            aria-hidden="true"
          ></i>
        </v-btn>
        <v-btn
          class="hidden-sm-and-up launch-button"
          small
          color="primary"
          @click.stop.prevent="launchTicket(item)"
        >
          {{ $t("Launch") }}
        </v-btn>
      </template>
      <template v-slot:[`item.comments`]="{ item }">
        <v-tooltip
          bottom
          color="black"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
            >
              {{ getShortComment(item) }}
            </div>
          </template>
          <div>
            <div
              v-for="(comment, index) in getCommentFeed(item)"
              :key="index"
            >
              {{ comment }}
            </div>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:footer.prepend>
        <v-btn
          color="primary"
          class="ma-2"
          @click="downloadCsv"
        >
          <v-icon>mdi-file-download</v-icon> CSV
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import { TableData } from "@/datatypes/table";
import { Dispatcher } from "@/utils/eventbus";
import { seconds } from "@/utils/filters";
import { ISSUE_CARD_TYPES } from "@/utils/p911AnalyticsUtils";
import { hasUserRole, userRoles } from "@/utils/user";

import MachinePrioritization from "./components/MachinePrioritization";

export default {
  props: {
    machine_group_id: { type: String, default: "" },
    machine_group_pk: { type: [String, Number], default: "" },
    machines: { type: Array, default: () => [] },
    closedDateExists: { type: Boolean, default: false },
    searchText: { type: String, default: "" },
    p911Table: { type: TableData, default: () => null },
    filters: { type: Object, default: () => {} },
    p911OpenClosedOrTotal: { type: String, default: "" }
  },
  data() {
    return {
      ISSUE_CARD_TYPES: Object.freeze(ISSUE_CARD_TYPES),
      usersTab: 1,
      helpTable: this.p911Table
        ? this.p911Table
        : new TableData([
            { text: this.$t("ID"), value: "id", sortable: true },
            { text: this.$t("Machine"), value: "machineName", sortable: true, width: "115px" },
            { text: this.$t(this.P1Name), value: "P1", sortable: false },
            { text: this.$t(this.P2Name), value: "P2", sortable: false },
            { text: this.$t("Status"), value: "status", sortable: false },
            {
              text: this.$t("Downtime (last 12hrs)"),
              value: "unplanned",
              sortable: false,
              width: "100px"
            },
            { text: this.$t("Tags"), value: "tagSearch", sortable: false },
            { text: this.$t("Ref Id"), value: "node.reference", sortable: true },
            {
              text: this.$t("Details"),
              value: "node.details",
              sortable: false,
              cellClass: "ticket-details"
            },
            { text: this.$t("Ticket By"), value: "ticketedByName", sortable: true },
            { text: this.$t("Date"), value: "ticketedDate", sortable: true, width: "115px" },
            { text: this.$t("Duration"), value: "duration", sortable: false },
            {
              text: this.$t("Assigned"),
              value: "assignedToNames",
              sortable: false,
              width: "250px"
            },
            { text: this.$t("Comments"), value: "comments", sortable: false },
            { text: "", value: "launch", sortable: false }
          ]),
      prioritization: false,
      ticketingAnomalyPending: false,
      helpTags: false,
      loading: false,
      machineStatuses: [],
      helpTimeout: false,
      machinesTimeout: false,
      accessToConfig: false,
      assignDialog: false,
      userSearch: "",
      users: [],
      newUsers: [],
      downloadingCsv: false,
      cancelSource: null,
      assigningTicket: false,
      levelP1: null,
      levelP2: null,
      windowWidth: window.innerWidth,
      commentFeed: {},
      machineHeirarchyLookup: null
    };
  },
  components: {
    MachinePrioritization,
    DialogCloseButton
  },
  created() {
    this.getP911ReportMachineGroupLevels();
    this.populateMachineHeirarchyLookup();
    this.helpTable.page.itemsPerPage = 20;
    this.accessToConfig = hasUserRole(this.user, userRoles.HelpAdmin);
    this.loadMachines();
    this.loadHelpTags();
    this.checkMachineGroupPrioritization();
    Dispatcher.$listen("NEW_HELP_TICKET", this.loadHelpTickets);
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  beforeDestroy() {
    Dispatcher.$silence("NEW_HELP_TICKET");
  },
  computed: {
    ...mapGetters({
      user: "session/User",
      statuses: "app/Statuses"
    }),
    filteredUsers() {
      if (!this.userSearch) {
        return this.users;
      }
      return this.users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(this.userSearch.toLowerCase());
      });
    },
    headers() {
      if (this.p911Table) {
        return this.p911Table.headers;
      }
      return [
        { text: this.$t("ID"), value: "id", sortable: true },
        { text: this.$t("Machine"), value: "machineName", sortable: true, width: "115px" },
        { text: this.$t(this.P1Name), value: "P1", sortable: false },
        { text: this.$t(this.P2Name), value: "P2", sortable: false },
        { text: this.$t("Status"), value: "status", sortable: false },
        {
          text: this.$t("Downtime (last 12hrs)"),
          value: "unplanned",
          sortable: false,
          width: "100px"
        },
        { text: this.$t("Tags"), value: "tagSearch", sortable: false },
        { text: this.$t("Ref Id"), value: "node.reference", sortable: true },
        {
          text: this.$t("Details"),
          value: "node.details",
          sortable: false,
          cellClass: "ticket-details"
        },
        { text: this.$t("Ticket By"), value: "ticketedByName", sortable: true },
        { text: this.$t("Date"), value: "ticketedDate", sortable: true, width: "115px" },
        { text: this.$t("Duration"), value: "duration", sortable: false },
        { text: this.$t("Assigned"), value: "assignedToNames", sortable: false, width: "250px" },
        { text: this.$t("Comments"), value: "comments", sortable: false },
        { text: "", value: "launch", sortable: false }
      ];
    },
    P1Name() {
      if (this.levelP1) {
        return this.levelP1.name;
      } else {
        return "";
      }
    },
    P2Name() {
      if (this.levelP2) {
        return this.levelP2.name;
      } else {
        return "";
      }
    }
  },
  methods: {
    moment,
    seconds,
    cancelDownloadCsv: function () {
      this.downloadingCsv = false;
      if (this.cancelSource) {
        this.cancelSource.cancel("canceled");
      }
    },
    downloadCsv() {
      this.downloadingCsv = true;

      const CancelToken = axios.CancelToken;
      this.cancelSource = CancelToken.source();
      const params = {
        search: this.searchText || null,
        closed: this.closedDateExists
      };
      if (this.machine_group_pk) {
        params.machine_group_id = this.machine_group_pk;
      }
      if (
        this.filters &&
        "fromDate" in this.filters &&
        "toDate" in this.filters &&
        this.filters.fromDate &&
        this.filters.toDate
      ) {
        params.from_date = this.filters.fromDate;
        params.to_date = this.filters.toDate;
      }
      if (this.filters && "machines" in this.filters) {
        params.machine_id = this.filters.machines;
      }
      if (
        this.p911OpenClosedOrTotal === ISSUE_CARD_TYPES.OPEN ||
        this.p911OpenClosedOrTotal === ISSUE_CARD_TYPES.CLOSED
      ) {
        params.closed = this.p911OpenClosedOrTotal !== ISSUE_CARD_TYPES.OPEN;
      }
      if (this.p911OpenClosedOrTotal === ISSUE_CARD_TYPES.TOTAL) {
        delete params["closed"];
      }

      this.$http
        .get("help/csv/", {
          params: params,
          responseType: "blob",
          cancelToken: this.cancelSource.token
        })
        .then((response) => {
          this.downloadingCsv = false;

          const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", `p911_${new Date().toISOString()}.csv`); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          if (this.cancelSource.token) {
            returnnull;
          } else {
            throw e;
          }
        });
    },
    checkMachineGroupPrioritization() {
      let query = "";
      let variables = {};
      if (this.machine_group_id) {
        query = `query getMachineGroup($id: ID!){
          machineGroup(id: $id){
            id
            ... on MachineGroupType{
              prioritization
            }
          }
          users{
            id,
            firstName,
            lastName,
          }
        }`;
        variables = { id: this.machine_group_id };
      } else {
        query = `query {
          users{
            id,
            firstName,
            lastName,
          }
        }`;
      }
      this.$http.post("graphql/", { query: query, variables: variables }).then((res) => {
        if (
          res.data.data &&
          "machineGroup" in res.data.data &&
          "prioritization" in res.data.data.machineGroup
        ) {
          this.prioritization = res.data.data.machineGroup.prioritization;
        }
        this.users = res.data.data.users;
      });
    },
    loadMachines() {
      if (!this.machine_group_pk) {
        return;
      }
      this.machinesLoaded = false;
      this.filterOptions = {
        machines: [],
        statuses: [],
        codes: [],
        parts: [],
        jobs: []
      };
      let params = {
        machine_group_id: this.machine_group_pk,
        start_date: moment().set("minute", 0).subtract(12, "h").toISOString(),
        end_date: moment().set("minute", 0).toISOString(),
        fields: [
          "factory_order",
          "lot_size",
          "part_number",
          "machine_name",
          "machine_id",
          "status_code"
        ],
        metrics: ["unplanned"]
      };
      this.$http.get("metrics/job_status/", { params: params }).then((res) => {
        this.machineStatuses = res.data;
        this.machinesLoaded = true;
        if (this.machinesTimeout) {
          clearTimeout(this.machinesTimeout);
        }
      });
    },
    loadHelpTags() {
      this.loading = true;
      this.helpTags = false;
      this.$http
        .get("graphql/", {
          params: {
            query: `{helpTags{
          name,
          color,
          id,
          pk
          lft,
          rgt,
          treeId,
          depth
        }
      }`
          }
        })
        .then((res) => {
          this.helpTags = res.data.data.helpTags;
          if (this.machine_group_pk || Object.keys(this.filters).length > 0) {
            this.loadHelpTickets();
            return;
          }
          this.loading = false;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    helpTicketDebouncer() {
      if (this.helpTimeout) {
        clearTimeout(this.helpTimeout);
      }
      let loadHelpTickets = this.loadHelpTickets;
      this.helpTimeout = setTimeout(loadHelpTickets, 500);
    },
    loadHelpTickets() {
      this.loading = true;
      let sort = this.helpTable.sortBy.length > 0 || this.closedDateExists;
      const query = `query (
        ${sort ? "$ordering: GrapheneElasticOrderingHelpTicketSearchConnectionBackendFilter!" : this.machine_group_pk ? "$machinePriorityOrder: GrapheneElasticMachinePriorityOrderingHelpTicketSearchConnection!" : ""},
        $filters: GrapheneElasticFilterHelpTicketSearchConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String){
        helpTickets (
          ${sort ? "ordering: $ordering" : this.machine_group_pk ? "machinePriorityOrder: $machinePriorityOrder" : ""},
          queryString: $search,
          filter: $filters,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [title]) {
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              esId,
              id,
              title,
              reference,
              details,
              ticketedBy,
              ticketedByName,
              ticketedDate,
              closedDate,
              assignedToNames,
              respondingNames,
              machineId,
              machineName,
              tagSearch
              tagLevel1
              tagLevel2
              tagLevel3
              tagLevel4
              tagLevel5
              comment1
              comment2
              additionalComments
              humanReadableId
            }
          }
        }
      }`;
      let ordering = { ticketedDate: "DESC" };
      if (this.helpTable.sortBy.length > 0) {
        ordering = {};
        this.helpTable.sortBy.forEach((sortBy, index) => {
          if (sortBy.startsWith("node.")) {
            sortBy = sortBy.replace("node.", "");
          }
          ordering[sortBy] = this.helpTable.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        filters: {
          closedDate: { exists: this.closedDateExists }
        },
        before: false,
        after: false,
        search: ""
      };
      if (
        this.filters &&
        "fromDate" in this.filters &&
        "toDate" in this.filters &&
        this.filters.fromDate &&
        this.filters.toDate
      ) {
        variables["filters"]["ticketedDate"] = {
          range: {
            lower: { datetime: this.filters.fromDate },
            upper: { datetime: this.filters.toDate }
          }
        };
      }
      if (this.filters && "machines" in this.filters && this.filters.machines.length > 0) {
        variables.filters.machineId = { terms: this.filters.machines };
      }
      if (
        this.p911OpenClosedOrTotal === ISSUE_CARD_TYPES.OPEN ||
        this.p911OpenClosedOrTotal === ISSUE_CARD_TYPES.CLOSED
      ) {
        variables["filters"]["closedDate"] = {
          exists: this.p911OpenClosedOrTotal !== ISSUE_CARD_TYPES.OPEN
        };
      }
      if (this.p911OpenClosedOrTotal === ISSUE_CARD_TYPES.TOTAL) {
        delete variables["filters"]["closedDate"];
      }
      if (sort) {
        variables["ordering"] = ordering;
      } else if (this.machine_group_pk) {
        variables["machinePriorityOrder"] = { machineGroupId: this.machine_group_pk };
      }
      if (this.helpTable.page.load) {
        Object.assign(variables, this.helpTable.page.load);
      } else {
        variables["first"] = this.helpTable.page.itemsPerPage;
      }
      if (this.searchText) {
        variables["search"] = this.searchText;
      }
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          this.helpTable.data = res.data.data.helpTickets.edges;
          this.helpTable.total = res.data.data.helpTickets.facets.title.doc_count;
          this.helpTable.page.next = res.data.data.helpTickets.pageInfo.endCursor;
          this.helpTable.page.prev = res.data.data.helpTickets.pageInfo.startCursor;
          this.helpTable.page.load = false;
          this.loading = false;
          if (this.helpTimeout) {
            clearTimeout(this.helpTimeout);
          }
          Promise.resolve();
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    getMachineGQLId(machine_pk) {
      let machine_id = false;
      this.machines.forEach((machine) => {
        if (machine.pk.toString() === machine_pk) {
          machine_id = machine.id;
        }
      });
      return machine_id;
    },
    closeAssign(ticket) {
      ticket.assignDialog = false;
      this.newUsers = [];
    },
    helpAssign(ticket) {
      this.assigningTicket = true;
      let newUserIds = [];
      this.newUsers.forEach((user) => {
        newUserIds.push(user.id);
      });
      const query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
        updateHelpTicket(globalId:$id, helpData:$helpTicketData){
          ok,
          helpTicket{
            assignedTo,
            assignedToNames,
          }
        }
      }`;
      const variables = {
        id: ticket.node.id,
        helpTicketData: {
          assignNew: newUserIds
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          this.newUsers = [];
          this.assigningTicket = false;
          ticket.assignDialog = false;
          this.loadHelpTickets();
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    getTagSlot(tag_id) {
      if (this.helpTags) {
        const tag = this.helpTags.find((t) => t.id === tag_id);
        if (tag) {
          return `<div class='tag-chip' style='background-color: ${tag.color}'>${tag.name}</div>`;
        }
      }
      return "";
    },
    getUnplanned(machine_id) {
      let unplanned = 0;
      this.machineStatuses.forEach((machine) => {
        if (machine.machine_id.toString() === machine_id.toString()) {
          unplanned = machine.unplanned.duration.value;
        }
      });
      return this.seconds(unplanned);
    },
    getStatusSlot(machine_id) {
      let code = "";
      this.machineStatuses.forEach((machine) => {
        if (machine.machine_id.toString() === machine_id.toString()) {
          code = machine.current_status;
        }
      });
      let statusMatch = false;
      let codeMatch = false;
      if (code) {
        this.statuses.forEach((status) => {
          status.statuscodeSet.forEach((sc) => {
            if (String(sc.code) === String(code)) {
              statusMatch = status;
              codeMatch = sc;
            }
          });
        });
      }
      if (statusMatch) {
        if (codeMatch) {
          return `<div class='status' style='background-color: ${statusMatch.color}'>${code + " - " + codeMatch.description}</div>`;
        } else {
          return `<div class='status' style='background-color: ${statusMatch.color}'>${code}</div>`;
        }
      } else if (codeMatch) {
        return `<div class='status'>${code + " - " + codeMatch.description}</div>`;
      } else {
        return `<div class='status'>${code}</div>`;
      }
    },
    goToTicket(item) {
      this.$router.push({ name: "help_ticket", params: { ticket_id: item.node.id } });
    },
    launchTicket(item) {
      let routeData = this.$router.resolve({
        name: "help_ticket",
        params: { ticket_id: item.node.id }
      });
      window.open(routeData.href, "_blank");
    },
    getShortComment(item) {
      let additionalComments = item.node.additionalComments
        ? item.node.additionalComments.split("|")
        : [];
      let allComments = [
        item.node.comment1 ? item.node.comment1 : "",
        item.node.comment2 ? item.node.comment2 : "",
        ...additionalComments
      ].join(" ");
      if (allComments.length > 25) {
        let truncatedComment = allComments.substring(0, 25);
        let lastSpaceIndex = truncatedComment.lastIndexOf(" ");
        // If no space was found, just return the truncated string
        if (lastSpaceIndex === -1) {
          return truncatedComment + "...";
        }
        // Otherwise, return up to the last full word
        return truncatedComment.substring(0, lastSpaceIndex) + "...";
      }
      // If allComments length is not more than 25
      return allComments;
    },
    getCommentFeed(item) {
      let additionalComments = item.node.additionalComments
        ? item.node.additionalComments.split("|")
        : [];
      return [
        item.node.comment1 ? item.node.comment1 : "",
        item.node.comment2 ? item.node.comment2 : "",
        ...additionalComments
      ];
    },
    async getP911ReportMachineGroupLevels() {
      const query = `{
    settings {
      p911ReportMachineGroupLevelP1 {
        pk,
        name,
        level,
      }
      p911ReportMachineGroupLevelP2 {
        pk,
        name,
        level,
      }
    }
  }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.settings &&
          response.data.data.settings.length > 0
        ) {
          this.levelP1 = response.data.data.settings[0]["p911ReportMachineGroupLevelP1"];
          this.levelP2 = response.data.data.settings[0]["p911ReportMachineGroupLevelP2"];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async populateMachineHeirarchyLookup() {
      const query = `{
        machines {
          pk
          machineHeirarchy {
            pk
            name
            level {
              name
              pk
            }
          }
        }
      }`;
      try {
        const response = await this.$http.get("graphql/", { params: { query } });
        if (response && response.data && response.data.data && response.data.data.machines) {
          this.machineHeirarchyLookup = response.data.data.machines.reduce((lookup, machine) => {
            lookup[machine.pk] = machine.machineHeirarchy;
            return lookup;
          }, {});
        }
      } catch (error) {
        console.error(error);
      }
    },
    getP911GroupLevelName(machinePk, level_pk) {
      const machineHeirarchy = this.machineHeirarchyLookup[machinePk];
      if (machineHeirarchy) {
        for (let i = 0; i < machineHeirarchy.length; i++) {
          if (machineHeirarchy[i].level && machineHeirarchy[i].level.pk === level_pk) {
            return machineHeirarchy[i].name;
          }
        }
      }
      return null;
    }
  },
  watch: {
    searchText: function () {
      this.helpTicketDebouncer();
    },
    "helpTable.sortBy": function () {
      this.helpTicketDebouncer();
    },
    "helpTable.sortDesc": function () {
      this.helpTicketDebouncer();
    },
    "helpTable.page.itemsPerPage": function () {
      this.helpTicketDebouncer();
    },
    "helpTable.page.current": function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.helpTable.page.load = {
          after: this.helpTable.page.next,
          before: false,
          first: this.helpTable.page.itemsPerPage
        };
        this.helpTicketDebouncer();
      } else if (newPage < oldPage) {
        this.helpTable.page.load = {
          after: false,
          before: this.helpTable.page.prev,
          last: this.helpTable.page.itemsPerPage
        };
        this.helpTicketDebouncer();
      }
    },
    closedDateExists() {
      this.loadHelpTickets();
    },
    "filters.machines.length": function () {
      this.loadHelpTickets();
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";
.group_help_tickets_csv {
  .card {
    display: flex;
    flex-direction: column;
  }

  .progress_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .progress_holder {
      padding: 5px 0;
    }
  }
  .card-actions {
    padding: 0 15px;
    display: flex;
    justify-content: end;
    flex: 1;
  }
}

.group_help_tickets {
  margin-top: 0.25rem;
  padding-bottom: 0.5rem;
  height: calc(100% - 7rem);
  position: relative;

  .modal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
    .machine-prioritization {
      position: absolute;
      top: -3rem;
      right: 200px;
    }
    @media all and (max-width: 990px) {
      .machine-prioritization {
        top: -5rem;
      }
    }
  }
  .v-input {
    max-width: 500px;
    margin: auto auto 0.5rem;
  }
  .v-data-table {
    thead {
      th {
        white-space: nowrap;
      }
    }
    .machine-link {
      color: unset;
      text-decoration: none;
      display: flex;
      align-items: center;
      p {
        white-space: nowrap;
        margin: 0 0.5rem 0 0;
      }
    }
    .ticket-details {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 250px;
      min-width: 250px;
      overflow: hidden;
    }
    .ticket-details:hover {
      text-overflow: unset;
      white-space: wrap;
      width: 250px;
    }
    .assignedRow {
      display: flex;
      align-items: center;
      .assigned-responding {
        display: flex;
        width: 100%;
      }
      .assigned {
        margin-left: 5px;
      }
      .responding {
        text-align: right;
        width: 100%;
      }
      p {
        margin-left: 1rem;
        font-size: 0.8rem;
      }
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      margin: 0.15rem 0;
      .tag {
        .tag-chip {
          padding: 0.25rem 0.5rem;
          white-space: nowrap;
          margin: 0.15rem 0.25rem;
          border-radius: 5px;
          color: white;
        }
      }
    }
    .status-wrapper {
      height: 100%;
      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
        color: white;
      }
    }
  }
}
</style>
