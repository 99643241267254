<template>
  <div class="card tickets-by-machine-chart">
    <p class="title">{{ $t("Tickets By Machine") }}</p>
    <div class="performance-summary-card-header">
      <AnalyticsPagination :handleTabChange="(pageSize) => handleTabChange(pageSize)" />
    </div>
    <BarChart
      class="bar-chart"
      :chartData="ticketsByMachinesChartData"
      :axisType="AXISTYPE.SHORTEN"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import AnalyticsPagination from "@/components/AnalyticsPagination";
import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import { coralReef, tiffanyBlue } from "@/scss/_variables.scss";

export default {
  name: "P911TicketsByMachine",
  props: {
    filters: { type: Object, default: () => {} }
  },
  components: {
    BarChart,
    AnalyticsPagination
  },
  data() {
    return {
      AXISTYPE: AXISTYPE,
      ticketsByMachinesChartData: {},
      pageSize: 10
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    })
  },
  methods: {
    handleTabChange(pageSize) {
      this.pageSize = pageSize;
      this.getP911ByMachines();
    },
    async getP911ByMachines() {
      const params = {
        from_date: this.filters.fromDate ? this.filters.fromDate.toISOString() : "",
        to_date: this.filters.toDate ? this.filters.toDate.toISOString() : "",
        machine_ids: this.filters.machines.map((machine) => machine.pk)
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      try {
        const response = await this.$http.get("help/open_closed_by_machine/", { params });
        if (response && response.data) {
          response.data.sort((a, b) => b.open.doc_count - a.open.doc_count);
          this.ticketsByMachinesChartData = {
            datasets: [
              {
                data: response.data.map((item) => item.closed.doc_count),
                label: this.$t("Closed"),
                backgroundColor: response.data.map(() => tiffanyBlue)
              },
              {
                data: response.data.map((item) => item.open.doc_count),
                label: this.$t("Open"),
                backgroundColor: response.data.map(() => coralReef)
              }
            ],
            labels: response.data.map((item) => this.machineLookup(parseInt(item.key)).name)
          };
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
