<template>
  <div class="machine-group-realtime">
    <div
      class="cards"
      :class="{ noJob: !showJobStatus }"
      key="cards"
    >
      <div
        class="card"
        id="heatmapCard"
      >
        <div class="card-header">
          <h2>{{ $t("Realtime Variance Analysis") }}</h2>
        </div>
        <div class="card-content">
          <machine-heatmap
            :machine_group_id="machine_group_id"
            :machine_group_pk="machine_group_pk"
          />
        </div>
      </div>
      <div
        class="card"
        id="opportunityCard"
      >
        <div class="card-header">
          <h2>{{ $t("Opportunity Cost") }} ($)</h2>
        </div>
        <div class="card-content">
          <div
            class="costs row makeMobileColumn"
            v-if="opportunityCost"
          >
            <div class="col-xs-4 col-sm-3 mobileKeyValuePair">
              <p class="label">{{ $t("Performance") }}</p>
              <p class="value">{{ shorten(perfVar) }}</p>
            </div>
            <div class="col-xs-4 col-sm-3 mobileKeyValuePair">
              <p class="label">{{ $t("Quality") }}</p>
              <p class="value">{{ shorten(qualVar) }}</p>
            </div>
            <div class="col-xs-4 col-sm-3 mobileKeyValuePair">
              <p class="label">{{ $t("Availability") }}</p>
              <p class="value">{{ shorten(availVar) }}</p>
            </div>
            <div class="col-xs-12 col-sm-3 mobileKeyValuePair">
              <p class="label">{{ $t("Total") }}</p>
              <p class="value">{{ shorten(totalVar) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card"
        id="helpCard"
      >
        <div class="card-header">
          <h2>{{ $t("P911 Status") }}</h2>
        </div>
        <div class="card-content flexCard">
          <div
            class="details row makeMobileColumn"
            v-if="helpTicketStates"
          >
            <div class="col-xs-3 col-md-2 mobileKeyValuePair">
              <p class="label">{{ $t("Open") }}</p>
              <p class="value">{{ helpTicketStates.open.doc_count }}</p>
            </div>
            <div class="col-xs-3 col-md-2 mobileKeyValuePair">
              <p class="label">{{ $t("Waiting") }}</p>
              <p class="value">{{ helpTicketStates.waiting.doc_count }}</p>
            </div>
            <div class="col-xs-3 col-md-2 mobileKeyValuePair">
              <p class="label">{{ $t("Active") }}</p>
              <p class="value">{{ helpTicketStates.active.doc_count }}</p>
            </div>
            <div class="col-xs-3 col-md-2 mobileKeyValuePair">
              <p class="label">{{ $t("Closed") }}</p>
              <p class="value">{{ helpTicketStates.closed.doc_count }}</p>
            </div>
            <div class="col-xs-12 col-md-4 mobileKeyValuePair hideMobile">
              <p class="label">{{ $t("Avg Response Time") }}</p>
              <p class="value time">
                {{
                  helpTicketStates.avg_response_time.value
                    ? seconds(helpTicketStates.avg_response_time.value, false, true)
                    : 0
                }}
              </p>
            </div>
            <div
              class="col-xs-12 tags hideMobile"
              v-if="helpTicketStates"
            >
              <div
                class="tag"
                v-for="(tag, tag_index) in helpTicketStates.tags"
              >
                <p>
                  <span :style="{ color: tag.color }">{{ tag.count }}</span> {{ tag.name }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="details row makeMobileColumn hideDesktop"
            v-if="helpTicketStates"
            v-bind:style="{ display: helpTicketStates.tags.length == 0 ? 'none' : null }"
          >
            <div
              class="col-xs-3 col-md-2 mobileKeyValuePair"
              v-for="(tag, tag_index) in helpTicketStates.tags"
            >
              <p
                class="label"
                :style="{ color: tag.color }"
              >
                {{ tag.name }}
              </p>
              <p class="value">{{ tag.count }}</p>
            </div>
          </div>
        </div>
        <div
          class="avgResponseTime hideDesktop"
          v-if="helpTicketStates"
        >
          <p class="label">{{ $t("Avg Response Time") }}</p>
          <p class="value time">
            {{
              helpTicketStates.avg_response_time.value
                ? seconds(helpTicketStates.avg_response_time.value, false, true)
                : 0
            }}
          </p>
        </div>
      </div>
      <div
        class="card"
        id="jobCard"
        v-if="showJobStatus"
      >
        <div class="card-header">
          <h2>{{ $t("Job Status") }}</h2>
          <div class="options">
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="jobOrder == 'DESC'"
              @click="jobOrder = 'ASC'"
            >
              <i
                class="fa fa-arrow-up"
                aria-hidden="true"
              ></i>
            </v-btn>
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="jobOrder == 'ASC'"
              @click="jobOrder = 'DESC'"
            >
              <i
                class="fa fa-arrow-down"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div
          class="card-content"
          v-if="machinesLoaded && jobsLoaded"
        >
          <transition-group name="fade">
            <div
              class="machineRow"
              v-for="(job, job_index) in jobMachines"
              :key="job.machine_id"
            >
              <p class="label">
                <span>{{ machinesLookup[job.machine_id].name }}</span>
                {{ machinesLookup[job.machine_id].name.substring(0, 10) }}
              </p>
              <div class="bar-container">
                <div
                  class="bar"
                  :style="{ width: job.percentage + '%' }"
                  :class="getJobCls(job.percentage.toFixed(0))"
                ></div>
              </div>
              <p class="percentage">{{ job.percentage.toFixed(0) + "%" }}</p>
            </div>
          </transition-group>
        </div>
      </div>
      <div
        class="card"
        id="perfCard"
      >
        <div class="card-header">
          <h2>{{ $t("Performance") }}</h2>
          <div class="options">
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="performanceOrder == 'DESC'"
              @click="performanceOrder = 'ASC'"
            >
              <i
                class="fa fa-arrow-up"
                aria-hidden="true"
              ></i>
            </v-btn>
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="performanceOrder == 'ASC'"
              @click="performanceOrder = 'DESC'"
            >
              <i
                class="fa fa-arrow-down"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div
          class="card-content"
          v-if="machinesLoaded && machineDataLoaded"
        >
          <transition-group name="fade">
            <div
              class="machineRow"
              v-for="(machine, machine_index) in performanceMachines"
              :key="machine.key"
            >
              <p class="label">
                <span>{{ machinesLookup[machine.key].name }}</span>
                {{ machinesLookup[machine.key].name.substring(0, 10) }}
              </p>
              <div class="bar-container">
                <div
                  class="bar"
                  :style="{ width: machine.performance.value * 100 + '%' }"
                  :class="getCls(machine, 'performance', 'performanceTarget')"
                ></div>
              </div>
              <p class="percentage">{{ (machine.performance.value * 100).toFixed(0) + "%" }}</p>
            </div>
          </transition-group>
        </div>
      </div>
      <div
        class="card"
        id="qualityCard"
      >
        <div class="card-header">
          <h2>{{ $t("Quality") }}</h2>
          <div class="options">
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="qualityOrder == 'DESC'"
              @click="qualityOrder = 'ASC'"
            >
              <i
                class="fa fa-arrow-up"
                aria-hidden="true"
              ></i>
            </v-btn>
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="qualityOrder == 'ASC'"
              @click="qualityOrder = 'DESC'"
            >
              <i
                class="fa fa-arrow-down"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div class="card-content">
          <transition-group name="fade">
            <div
              class="machineRow"
              v-for="(machine, machine_index) in qualityMachines"
              :key="machine.key"
            >
              <p class="label">
                <span>{{ machinesLookup[machine.key].name }}</span>
                {{ machinesLookup[machine.key].name.substring(0, 10) }}
              </p>
              <div class="bar-container">
                <div
                  class="bar"
                  :style="{ width: machine.quality.value * 100 + '%' }"
                  :class="getCls(machine, 'quality', 'qualityTarget')"
                ></div>
              </div>
              <p class="percentage">{{ (machine.quality.value * 100).toFixed(0) + "%" }}</p>
            </div>
          </transition-group>
        </div>
      </div>
      <div
        class="card"
        id="availabilityCard"
      >
        <div class="card-header">
          <h2>{{ $t("Availability") }}</h2>
          <div class="options">
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="availabilityOrder == 'DESC'"
              @click="availabilityOrder = 'ASC'"
            >
              <i
                class="fa fa-arrow-up"
                aria-hidden="true"
              ></i>
            </v-btn>
            <v-btn
              color="white"
              light
              fab
              x-small
              v-if="availabilityOrder == 'ASC'"
              @click="availabilityOrder = 'DESC'"
            >
              <i
                class="fa fa-arrow-down"
                aria-hidden="true"
              ></i>
            </v-btn>
          </div>
        </div>
        <div class="card-content">
          <transition-group name="fade">
            <div
              class="machineRow"
              v-for="(machine, machine_index) in availabilityMachines"
              :key="machine.key"
            >
              <p class="label">
                <span>{{ machinesLookup[machine.key].name }}</span>
                {{ machinesLookup[machine.key].name.substring(0, 10) }}
              </p>
              <div class="bar-container">
                <div
                  class="bar"
                  :style="{ width: machine.availability.value * 100 + '%' }"
                  :class="getCls(machine, 'availability', 'runtimeTarget')"
                ></div>
              </div>
              <p class="percentage">{{ (machine.availability.value * 100).toFixed(0) + "%" }}</p>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";
.machine-group-realtime {
  //height: 100%;
  width: 100%;
  overflow: hidden;
  flex: 1;
  .menu {
    height: 3rem;
    .tabs {
      margin: 0 1rem;
      i {
        margin-right: 1rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    height: unset !important;
    width: unset !important;
    margin: 5px !important;

    .makeMobileColumn {
      display: flex;
      flex-flow: column;
      align-items: unset !important;
      text-align: unset !important;
      margin: 0 !important;
      flex: 1;
    }

    .avgResponseTime {
      display: flex !important;
      justify-content: space-around;
      margin: 0 15px;
      padding: 5px;
      border-radius: 4px;
      .theme--dark & {
        background: $darkGrey;
      }

      .theme--light & {
        background: $light-background;
      }
    }

    .mobileKeyValuePair {
      width: unset;
      max-width: unset;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0;
      padding: 0;

      .label {
        color: $blue;
        font-size: 16px;
        padding: 4px;
        text-align: left;
        flex: 1;
        margin-bottom: -0.5rem !important;
      }

      .value {
        font-size: 16px !important;
        padding: 5px;
        text-align: left;
        flex: 1;
        margin-bottom: -0.5rem !important;
      }
    }
  }

  .cards {
    padding: 1rem;
    position: relative;
    height: calc(100% - 1rem);
    width: 100%;

    @media screen and (max-width: 1200px) {
      height: 100% !important;
      width: unset;
      margin: 5px;
      padding: 0.5rem;
    }

    .card {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      .card-header {
        height: unset;
        max-height: unset;
        padding-top: 15px;

        h2 {
          color: $blue;
          i {
            opacity: 0.6;
            margin: 0.5rem;
            cursor: pointer;
          }
        }
        .fa-arrow-down,
        .fa-arrow-up {
          font-size: 1rem;
        }
      }
    }
    #heatmapCard {
      top: 0.5rem;
      height: calc(50% - 1.5rem);
      left: 1rem;
      width: calc(50% - 2rem);
    }
    #opportunityCard {
      top: 0.5rem;
      height: calc(25% - 1.5rem);
      right: 1rem;
      width: calc(50% - 1.5rem);
    }
    #helpCard {
      top: calc(25% + 0.5rem);
      height: calc(25% - 1.5rem);
      right: 1rem;
      width: calc(50% - 1.5rem);
    }
    #jobCard {
      top: calc(50% + 0.5rem);
      height: calc(50% - 1.5rem);
      left: 1rem;
      width: calc(25% - 2rem);
    }
    #perfCard {
      top: calc(50% + 0.5rem);
      height: calc(50% - 1.5rem);
      left: calc(25% + 0.5rem);
      width: calc(25% - 1.5rem);
    }
    #qualityCard {
      top: calc(50% + 0.5rem);
      height: calc(50% - 1.5rem);
      width: calc(25% - 1.5rem);
      right: calc(25% + 1rem);
    }
    #availabilityCard {
      top: calc(50% + 0.5rem);
      height: calc(50% - 1.5rem);
      right: 1rem;
      width: calc(25% - 1.5rem);
    }
    &.noJob {
      #perfCard {
        left: 1rem;
        width: calc(33.33% - 1.5rem);
      }
      #qualityCard {
        width: calc(33.33% - 2rem);
        right: calc(33.33% + 1rem);
      }
      #availabilityCard {
        right: 1rem;
        width: calc(33.33% - 1.5rem);
      }
    }
    #opportunityCard {
      .costs {
        height: 100%;
        align-items: center;
        text-align: center;
        .label {
          margin-bottom: -0.25rem;
        }
        .value {
          font-size: 3rem;
        }
      }
    }
    #helpCard {
      .card-content {
        overflow-y: visible;
        padding-top: 2.5rem;
      }
      .details {
        height: calc(100% - 3rem);
        align-items: center;
        text-align: center;
        margin: auto;
        .value {
          font-size: 2.5rem;
          margin-bottom: -0.5rem;
          &.time {
            white-space: nowrap;
          }
        }
        .label {
          margin-bottom: -0.5rem;
        }
        .tags {
          display: flex;
          justify-content: center;

          //@media screen and (max-width: 1000px){
          //  display: none;
          //}

          p {
            margin: 0.5rem 0.75rem;
            font-size: 1.25rem;
            span {
              font-weight: 600;
              margin: 0 0.25rem;
            }
          }
        }
      }
    }
    .machineRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;
      transition: all 0.5s;
      .label {
        width: 6rem;
        margin-bottom: 0.25rem;
        position: relative;
        span {
          visibility: hidden;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          text-align: center;
          padding: 0 5px;
          border-radius: 6px;

          /* Position the tooltip text - see examples below! */
          position: absolute;
          z-index: 1;
        }
        &:hover {
          span {
            visibility: visible;
          }
        }
      }
      .percentage {
        width: 3rem;
      }
      .bar-container {
        width: calc(100% - 10rem);
        .bar {
          height: 1rem;
          background-color: $blue;
          border-radius: 0.15rem;
          max-width: 100%;
          &.goodBar {
            background-color: #78c47d;
          }
          &.warningBar {
            background-color: #e0cc6b;
          }
          &.badBar {
            background-color: #f46c6a;
          }
        }
      }
    }
  }
}

@media screen and(max-width: 600px) {
  .machineCard {
    .card-header {
      position: relative !important;
    }

    .v-data-table__mobile-row {
      display: flex;

      .v-data-table__mobile-row__header {
        flex: unset;
        width: 100px;
        justify-content: flex-start;
        text-align: left;
      }

      .v-data-table__mobile-row__cell {
        display: flex;
        flex: 1;
        p {
          flex: 1;
          display: table-cell;
          text-align: right;
        }
      }
    }
  }
}

@media screen and(max-width: 1200px) {
  .machine-group-metrics {
    flex-flow: column-reverse !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex: 1;

    .menu {
      flex: unset;
      display: flex;

      .v-tabs-bar {
        height: 100%;
      }
    }

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }

    .issueTables {
      .card-header {
        position: relative !important;
      }

      .card-content {
        margin-top: unset !important;
        padding-top: unset !important;
        flex-flow: column !important;

        .values {
          padding: 0.5rem 1rem !important;
          flex-flow: row !important;
        }
      }
    }

    .cards {
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      @media screen and(max-width: 1200px) {
        .hideMobile {
          display: none;
        }

        .showMobile {
          display: initial;
        }
      }

      @media screen and(max-width: 1200px) {
        .card-content {
          overflow-y: visible;
          padding: 0 0.8rem;
          position: relative;
          padding-bottom: 15px;
          padding-top: 15px;
        }

        .flexCard {
          display: flex;
        }

        .card-header {
          padding: 0 0.8rem;
          position: relative;
          min-height: unset;
          h2 {
            font-size: 16px;
          }
        }
      }

      .card {
        position: relative;
        margin: 0.75rem 0;
        padding-bottom: 20px;
      }
      #heatmapCard {
        top: 0;
        height: fit-content;
        left: 0;
        width: 100%;
      }
      #opportunityCard {
        top: 0;
        height: fit-content;
        right: 0;
        width: 100%;
        .costs {
          .value {
            font-size: 1.75rem;
          }
        }
      }
      #helpCard {
        top: 0;
        height: fit-content;
        right: 0;
        width: 100%;
      }
      #jobCard {
        display: inline-block;
        top: 0;
        height: fit-content;
        left: 0;
        width: calc(50% - 1rem);
      }
      #perfCard {
        display: inline-block;
        top: 0;
        height: fit-content;
        left: 0;
        width: calc(50% - 1rem);
      }
      #qualityCard {
        display: inline-block;
        top: 0;
        height: fit-content;
        right: 0;
        width: calc(50% - 1rem);
      }
      #availabilityCard {
        display: inline-block;
        top: 0;
        height: fit-content;
        right: 0;
        width: calc(50% - 1rem);
      }
      &.noJob {
        #perfCard {
          left: 1rem;
          width: calc(33.33% - 1.5rem);
        }
        #qualityCard {
          width: calc(33.33% - 2rem);
          right: 0;
        }
        #availabilityCard {
          right: 1rem;
          width: calc(33.33% - 1.5rem);
        }
      }
      @include media_below(1000px) {
        &.noJob {
          #perfCard {
            left: 0rem;
            width: 100%;
          }
          #qualityCard {
            left: 0rem;
            width: 100%;
          }
          #availabilityCard {
            left: 0rem;
            width: 100%;
          }
        }
      }
    }
  }
}
@include media_below(700px) {
  .machine-group-metrics {
    .cards {
      #jobCard {
        width: 100%;
      }
      #perfCard {
        width: 100%;
      }
      #qualityCard {
        width: 100%;
      }
      #availabilityCard {
        width: 100%;
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import Vue from "vue";

import MachineHeatmap from "@/components/charts/Heatmap/MachineMetric";
import { seconds, shorten } from "@/utils/filters";

import PaginatedChart from "../../analytics/operator/PaginatedChart";

export default {
  props: ["machine_group_id", "machine_group_pk", "machinesLookup", "machines"],
  data() {
    return {
      tab: 0,
      machinesLoaded: true,

      machineData: [],
      machineDataLoaded: true,

      showJobStatus: false,
      jobs: false,
      jobsLoaded: false,

      opportunityCost: false,
      costLoaded: false,

      helpTicketStates: false,
      helpLoaded: false,

      jobOrder: "ASC",
      performanceOrder: "ASC",
      qualityOrder: "ASC",
      availabilityOrder: "ASC",

      machineDataTimeout: false,
      jobsTimeout: false,
      costTimeout: false,
      helpTimeout: false
    };
  },
  components: {
    PaginatedChart,
    MachineHeatmap
  },
  created() {
    this.loadMachineData();
    this.checkJobStatus();
    this.loadCost();
    this.loadHelpTickets();
  },
  beforeDestroy() {},
  computed: {
    jobMachines() {
      if (this.jobsLoaded) {
        if (this.jobOrder == "ASC") {
          return _.sortBy(this.jobs, "percentage").reverse();
        } else {
          return _.sortBy(this.jobs, "percentage");
        }
      }
      return [];
    },
    performanceMachines() {
      if (this.machineDataLoaded && this.machinesLoaded) {
        if (this.performanceOrder == "ASC") {
          return _.sortBy(this.machineData, "performance.value").reverse();
        } else {
          return _.sortBy(this.machineData, "performance.value");
        }
      }
      return [];
    },
    qualityMachines() {
      if (this.machineDataLoaded && this.machinesLoaded) {
        if (this.qualityOrder == "ASC") {
          return _.sortBy(this.machineData, "quality.value").reverse();
        } else {
          return _.sortBy(this.machineData, "quality.value");
        }
      }
      return [];
    },
    availabilityMachines() {
      if (this.machineDataLoaded && this.machinesLoaded) {
        if (this.availabilityOrder == "ASC") {
          return _.sortBy(this.machineData, "availability.value").reverse();
        } else {
          return _.sortBy(this.machineData, "availability.value");
        }
      }
      return [];
    },
    perfVar() {
      if (this.opportunityCost.total_performance_variance.value < 0) {
        return 0;
      }
      return this.opportunityCost.total_performance_variance.value;
    },
    qualVar() {
      if (this.opportunityCost.total_quality_variance.value < 0) {
        return 0;
      }
      return this.opportunityCost.total_quality_variance.value;
    },
    availVar() {
      if (this.opportunityCost.total_availability_variance.value < 0) {
        return 0;
      }
      return this.opportunityCost.total_availability_variance.value;
    },
    totalVar() {
      return this.perfVar + this.qualVar + this.availVar;
    }
  },
  methods: {
    seconds,
    shorten,
    checkJobStatus() {
      this.$http
        .get("graphql/", {
          params: {
            query: `{settings{
          showJobStatus
        }
      }`
          }
        })
        .then((res) => {
          if (res.data.data.settings.length > 0) {
            this.showJobStatus = res.data.data.settings[0].showJobStatus;
            if (this.showJobStatus) {
              this.loadJobs();
            }
          }
        });
    },
    loadMachineData() {
      this.machineDataLoaded = false;
      let params = {
        from_date: moment().set("minute", 0).subtract(24, "h").toISOString(),
        to_date: moment().set("minute", 0).toISOString(),
        machine_group_id: this.machine_group_pk
      };
      this.$http.get("metrics/machine_metric_breakdown/", { params: params }).then((res) => {
        this.machineData = res.data;
        this.machineData.forEach((machine, i) => {
          if (!machine.performance || !machine.performance.value) {
            Vue.set(machine, "performance", { value: 0 });
          }
          if (!machine.quality || !machine.quality.value) {
            Vue.set(machine, "quality", { value: 0 });
          }
          if (machine.key in this.machinesLookup) {
            this.machinesLookup[machine.key]["trend"] = machine.trend;
          }
        });

        this.machineDataLoaded = true;
        if (this.machineDataTimeout) {
          clearTimeout(this.machineDataTimeout);
        }
        let loadMachineData = this.loadMachineData;
        this.machineDataTimeout = setTimeout(loadMachineData, 300000);
      });
    },
    loadJobs() {
      this.jobsLoaded = false;
      let params = { machine_group_id: this.machine_group_pk };
      this.$http.get("metrics/job_status/", { params: params }).then((res) => {
        this.jobs = res.data;
        this.jobsLoaded = true;
        if (this.jobTimeout) {
          clearTimeout(this.jobTimeout);
        }
        let loadJobs = this.loadJobs;
        this.jobTimeout = setTimeout(loadJobs, 300000);
      });
    },
    loadCost() {
      this.costLoaded = false;
      let params = {
        machine_group_id: this.machine_group_pk,
        from_date: moment().set("minute", 0).subtract(24, "h").toISOString(),
        to_date: moment().set("minute", 0).toISOString()
      };
      this.$http.get("variance/summary/", { params: params }).then((res) => {
        this.opportunityCost = res.data;
        this.costLoaded = true;
        if (this.costTimeout) {
          clearTimeout(this.costTimeout);
        }
        let loadCost = this.loadCost;
        this.costTimeout = setTimeout(loadCost, 300000);
      });
    },
    loadHelpTickets() {
      this.helpLoaded = false;
      let params = { machine_group_id: this.machine_group_pk };
      this.$http.get("help/summary/", { params: params }).then((res) => {
        this.helpTicketStates = res.data;
        this.helpLoaded = true;
        if (this.helpTimeout) {
          clearTimeout(this.helpTimeout);
        }
        let loadHelpTickets = this.loadHelpTickets;
        this.helpTimeout = setTimeout(loadHelpTickets, 300000);
      });
    },
    getCls(machine, metric, target) {
      let val = machine[metric]["value"] * 100;
      let targetVal = this.machinesLookup[machine.key][target];
      let diff = parseFloat(val) - parseFloat(targetVal);
      if (diff >= 0) {
        return "goodBar";
      } else if (diff >= -5) {
        return "warningBar";
      } else {
        return "badBar";
      }
    },
    getJobCls(percentage) {
      if (percentage > 100) {
        return "badBar";
      } else if (percentage == 100) {
        return "goodBar";
      }
    }
  },
  watch: {
    $route(to, from) {
      this.loadMachineData();
      this.loadJobs();
      this.loadHelpTickets();
    }
  }
};
</script>
