<template>
  <div class="card ldms-filter-card">
    <div class="card radio-button-filters">
      <v-radio-group
        :value="selectedFilterType"
        @change="changeFilterType"
      >
        <v-radio
          :ripple="false"
          :label="this.$t('Data Table')"
          :value="FILTER_TYPES.DATA_TABLE"
        />
        <v-radio
          :ripple="false"
          :label="this.$t('Impact Summary')"
          :value="FILTER_TYPES.IMPACT_SUMMARY"
        />
        <v-radio
          :ripple="false"
          :label="this.$t('Performance Summary')"
          :value="FILTER_TYPES.PERFORMANCE_SUMMARY"
        />
      </v-radio-group>
    </div>
    <div class="card preset-filters">
      <h4 class="preset-title">{{ $t("Presets") }}</h4>
      <v-tabs
        class="preset-tabs"
        height="25"
        v-model="selectedPreset"
        @change="changePreset"
      >
        <v-tab class="no-tab"></v-tab>
        <v-tab class="preset-tab">{{ $t("Shift") }}</v-tab>
        <v-tab class="preset-tab">{{ $t("Day") }}</v-tab>
        <v-tab class="preset-tab">{{ $t("Week") }}</v-tab>
        <v-tab class="preset-tab">{{ $t("Month") }}</v-tab>
        <v-tab class="preset-tab">{{ $t("Year") }}</v-tab>
      </v-tabs>
      <div v-if="isPerformanceSummary">
        <h4 class="scales-title">{{ $t("Scales") }}</h4>
        <v-tabs
          class="scales-tabs"
          height="25"
          v-model="scale"
          @change="changeScale"
        >
          <v-tab class="no-tab"></v-tab>
          <v-tab class="scales-tab">{{ $t("Hour") }}</v-tab>
          <v-tab class="scales-tab">{{ $t("Day") }}</v-tab>
          <v-tab class="scales-tab">{{ $t("Week") }}</v-tab>
          <v-tab class="scales-tab">{{ $t("Month") }}</v-tab>
          <v-tab class="scales-tab">{{ $t("Year") }}</v-tab>
        </v-tabs>
      </div>
      <h4 class="preset-from-date">{{ $t("From Date") }}</h4>
      <datetime-picker
        class="date-picker"
        id="fromDate"
        v-model="fromDate"
        :is-dialog="true"
        @input="changeDateManually"
      />
      <h4 class="preset-to-date">{{ $t("To Date") }}</h4>
      <datetime-picker
        class="date-picker to-date-picker"
        id="toDate"
        v-model="toDate"
        :is-dialog="true"
        @input="changeDateManually"
      />
    </div>
    <div class="card dropdown-filters">
      <MachineGroupSelector
        class="machine-group-selector"
        :hideMachineGroupSelector="true"
        :setMachineGroups="setMachineGroups"
      />
      <v-autocomplete
        class="part-autocomplete"
        :placeholder="this.$t('Part')"
        :items="parts"
        :filter="filterParts"
        clearable
        multiple
        single-line
        persistent
        v-model="selectedParts"
        item-text="name"
        item-value="pk"
        :menu-props="{
          closeOnClick: true
        }"
        @change="setupFilters"
      >
        <template v-slot:item="{ item, index }">
          {{ item.name }} : {{ item.description }}
        </template>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            <span>{{ item.name }}</span>
          </span>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            &nbsp;(+{{ selectedParts.length - 1 }} {{ $t("others") }})
          </span>
        </template>
      </v-autocomplete>
      <v-autocomplete
        class="issue-autocomplete"
        :placeholder="this.$t('Issues')"
        :items="reasons"
        :filter="filterReasons"
        clearable
        multiple
        single-line
        persistent
        item-text="text"
        item-value="pk"
        v-model="selectedReasons"
        :menu-props="{
          closeOnClick: true
        }"
        @change="setupFilters"
      >
        <template v-slot:item="{ item, index }">
          {{ item.text }}
        </template>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            <span>{{ item.text }}</span>
          </span>
          <span
            v-if="index === 1"
            class="grey--text text-caption"
          >
            &nbsp;(+{{ selectedReasons.length - 1 }} {{ $t("others") }})
          </span>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";

import DatetimePicker from "@/components/form/DatetimePicker";
import { FILTER_TYPES } from "@/utils/LDMSAnalyticsUtils";
import MachineGroupSelector from "@/views/analytics/production_report/MachineGroupSelector";

export default {
  name: "FilterLDMSAnalytics",
  components: {
    DatetimePicker,
    MachineGroupSelector
  },
  props: ["applyFilters", "applySelectedFilterType", "selectedFilterType"],
  data() {
    return {
      FILTER_TYPES: Object.freeze(FILTER_TYPES),
      fromDate: null,
      toDate: null,
      parts: [],
      selectedParts: [],
      reasons: [],
      selectedReasons: [],
      selectedMachines: [],
      selectedMachineGroups: [],
      machineData: [],
      shiftStart: null,
      shiftEnd: null,
      selectedPreset: 0,
      presets: [null, "shift", "day", "week", "month", "year"],
      scale: null,
      scales: [null, "1h", "1d", "1w", "1M", "1y"],
      dateFormats: ["D MMM hhA", "D MMM", "week", "MMM YY"],
      interval: null,
      dateFormat: "1h"
    };
  },
  async created() {
    const shifts = await this.loadShift();
    if (shifts.length > 0 && shifts[0].shiftDays.length > 0) {
      this.shiftStart = moment(shifts[0].shiftDays[0].lastStartDatetime).toDate();
      this.shiftEnd = moment(shifts[0].shiftDays[0].nextEndDatetime).toDate();
    }
  },
  computed: {
    isPerformanceSummary() {
      return this.selectedFilterType === this.FILTER_TYPES.PERFORMANCE_SUMMARY;
    }
  },
  methods: {
    changeFilterType(filterType) {
      this.applySelectedFilterType(filterType);
    },
    filterParts(item, queryText, itemText) {
      const searchText = queryText.trim().toLowerCase();
      const name = item.name.toLowerCase();
      const value = item.description.toLowerCase();
      return name.indexOf(searchText) > -1 || value.indexOf(searchText) > -1;
    },
    filterReasons(item, queryText, itemText) {
      const searchText = queryText.trim().toLowerCase();
      return item.text.toLowerCase().indexOf(searchText) > -1;
    },
    async loadShift() {
      try {
        const query = `{
          shifts(current:true){
            name
            shiftDays(current:true){
              lastStartDatetime
              nextStartDatetime
              nextEndDatetime
            }
          }
        }`;
        const response = await this.$http.get("graphql/", { params: { query } });
        if (response) {
          return Promise.resolve(response.data.data.shifts);
        }
      } catch (error) {
        console.error(error);
        return Promise.reject();
      }
    },
    async getMachineData(groups, machines) {
      let groupsArr = [-1];
      let machinesArr = [-1];
      if (groups.length > 0) {
        groupsArr = groups;
      }
      if (machines.length > 0) {
        machinesArr = machines;
      }
      const query = `query($groupIds: [Int], $machineIds: [Int]){
        machineGroups(ids: $groupIds){
          id
          allMachines {
            name,
            id,
            pk,
            performanceTarget,
            qualityTarget,
            oeeTarget,
            runtimeTarget
          }
        }
        machines(ids: $machineIds) {
          name,
          id,
          pk,
          performanceTarget,
          qualityTarget,
          oeeTarget,
          runtimeTarget
        }
      }`;
      const variables = {
        groupIds: groupsArr,
        machineIds: machinesArr
      };
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (response) {
          let machineList = [];
          const machineListByMachineGroup = response.data.data.machineGroups;
          for (const list of machineListByMachineGroup) {
            machineList = machineList.concat(list.allMachines);
          }
          //filter out the duplicates that exist in both the list we got from the
          //groups and the list we sent
          machineList = machineList.filter((machine) => machinesArr.indexOf(machine.pk) < 0);
          //then add the data from the individual machines
          machineList = machineList.concat(response.data.data.machines);

          return machineList;
        }
      } catch (error) {
        return [];
      }
    },
    setMachineGroups(machineGroups, machines) {
      //load machines
      this.selectedMachineGroups = machineGroups;
      //we need to load zones with a list of IDs
      this.getMachineData(machineGroups, machines).then((data) => {
        this.machineData = data;
        const machinePks = data.map((machine) => machine.pk);
        const machineIds = data.map((machine) => machine.id);
        this.selectedMachines = machinePks;
        this.setupFilters();
        this.loadParts(machinePks);
        this.loadReasons(machineIds);
      });
    },
    async loadParts(machinePks) {
      let machines = null;
      if (machinePks.length > 0) {
        machines = machinePks;
      }
      this.parts = await this.getParts(machines);
    },
    async loadReasons(machineIds) {
      let machines = null;
      if (machineIds.length > 0) {
        machines = machineIds;
      }
      this.reasons = await this.getReasons(machines);
    },
    async getParts(ids) {
      try {
        const query = `query($ids: [Int]){
          partConfigurations(machinePks: $ids){
            part {
                name,
                id,
                description,
                pk
            }
          }
        }`;
        const variables = { ids };
        const response = await this.$http.post("graphql/", { query, variables });
        if (response) {
          const partConfigs = response.data.data.partConfigurations;
          const result = [];
          for (let config of partConfigs) {
            result.push(config.part);
          }
          return result;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getReasons(ids) {
      const query = `query ($machine: [ID]) {
        issueReasons(machine: $machine) {
          edges {
            node {
              id
              pk
              text
              issueType {
                id
                pk
              }
            }
          }
        }
      }`;
      const variables = { machine: ids };
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.issueReasons &&
          response.data.data.issueReasons.edges
        ) {
          return response.data.data.issueReasons.edges.map((edge) => {
            return {
              id: edge.node.id,
              pk: edge.node.pk,
              text: edge.node.text
            };
          });
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    changeDateManually() {
      this.selectedPreset = 0;
      this.setupFilters();
    },
    changePreset(preset) {
      const currentPreset = this.presets[preset];
      switch (currentPreset) {
        case "shift":
          this.fromDate = this.shiftStart;
          this.toDate = this.shiftEnd;
          this.scale = 1;
          this.dateFormat = this.dateFormats[0];
          break;
        case "day":
          this.fromDate = moment().startOf("day").toDate();
          this.toDate = moment().endOf("day").toDate();
          this.scale = 1;
          this.dateFormat = this.dateFormats[0];
          break;
        case "week":
          this.fromDate = moment().startOf("week").toDate();
          this.toDate = moment().endOf("week").toDate();
          this.scale = 2;
          this.dateFormat = this.dateFormats[1];
          break;
        case "month":
          this.fromDate = moment().startOf("month").toDate();
          this.toDate = moment().endOf("month").toDate();
          this.scale = 2;
          this.dateFormat = this.dateFormats[1];
          break;
        case "year":
          this.fromDate = moment().startOf("year").toDate();
          this.toDate = moment().endOf("year").toDate();
          this.scale = 4;
          this.dateFormat = this.dateFormats[3];
          break;
      }
      this.interval = this.scales[this.scale];
      this.setupFilters();
    },
    changeScale(scale) {
      this.interval = this.scales[scale];
      switch (this.interval) {
        case "1h":
          this.dateFormat = this.dateFormats[0];
          break;
        case "1d":
          this.dateFormat = this.dateFormats[0];
          break;
        case "1w":
          this.dateFormat = this.dateFormats[1];
          break;
        case "1M":
          this.dateFormat = this.dateFormats[2];
          break;
        case "1y":
          this.dateFormat = this.dateFormats[3];
          break;
      }
      this.setupFilters();
    },
    setupFilters() {
      this.applyFilters({
        fromDate: this.fromDate,
        toDate: this.toDate,
        machineGroups: this.selectedMachineGroups,
        machines: this.selectedMachines,
        reasons: this.selectedReasons,
        parts: this.selectedParts,
        interval: this.interval,
        dateFormat: this.dateFormat
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
</style>
